import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from "@/router";
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import './fornac/fornac.css'
import "../style/theme/index.css"
import "../src/assets/font/font.css"
import locale from 'element-ui/lib/locale/lang/en'
import VueJsonToCsv from 'vue-json-to-csv'


Vue.use(ElementUI, {locale})
Vue.use(VueGoodTablePlugin)
Vue.use(VueJsonToCsv)

//定义全局函数
Vue.prototype.columnFilterFn = function (data, filterString) {
    // 这是一个VueGoodTable的筛选函数。
    // 筛选条件用逗号分割,被筛选的数据用分号分割。
    let filterStringArr = filterString.trim().split(",")
    let dataArr = data.trim().split(";")

    // 筛去空字符串和去重
    filterStringArr = filterStringArr.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
    })
    dataArr = dataArr.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
    })

    let temp2 = filterStringArr.map((item_f) => {
        return dataArr.map((item_d) => {
            return item_d.toLowerCase().includes(item_f.toLowerCase())
        })
    })
    return temp2.flat().indexOf(true) !== -1
    // filter  data    result
    // [a,b]   [a]     true
    // [a,b]   [a,b,c] true
    // [a,b]   [c,d]   false
    // [a,b]   [bcd]    true
}
Vue.prototype.modification2SupOrSub = function (text) {
    // 这个函数用来将文本的修饰名称格式化为有上下标的形式(html)
    // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
    let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
    }
    let reg1 = new RegExp("[0-9]+", 'g');
    let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
    let text1 = text.replace(reg1, "<sup>$&</sup>");
    let text2 = text1.replace(reg2, f)
    let text3 = text2.replaceAll("Y", "Ψ")
    let text4 = text3.replaceAll("tau", "τ")
    return text4
    // modification2SupOrSub("m2G")
    // 'm<sup>2</sup>G'
    // modification2SupOrSub("m2,2tau")
    // 'm<sup>2</sup><sub>2</sub>τ'
}
Vue.prototype.modification2SupOrSubForD3 = function (text) {
    // 这个函数用来将文本的修饰名称格式化为有上下标的形式，用于d3的text标签
    // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
    let f = function ($1) {
        return $1.replace(",", "").replaceAll("per", "b");
    }
    let reg1 = new RegExp("[0-9]+", 'g');
    let reg2 = new RegExp(",<tspan baseline-shift=\"super\" style=\"font-size:0.5rem\">[0-9]+</tspan>", 'g');
    let text1 = text.replace(reg1, "<tspan baseline-shift=\"super\" style=\"font-size:0.5rem\">$&</tspan>");
    let text2 = text1.replace(reg2, f)
    let text3 = text2.replaceAll("Y", "Ψ")
    let text4 = text3.replaceAll("tau", "τ")
    return text4
}
Vue.prototype.arrAvg = function(myArray) {
    // 数组求平均数
    let i = 0, sum = 0, ArrayLen = myArray.length;
    while (i < ArrayLen) {
        sum = sum +  parseFloat(myArray[i++]);
    }
    return sum / ArrayLen;
}
new Vue({
    el: '#app',
    render: h => h(App),
    router
});