<template>
  <div>
    <el-row>
      <el-col>
        <h1 style="color:#182978">LC-MS Methods</h1>
      </el-col>
    </el-row>
    <el-row v-loading="Loading_lcms">
      <el-col >
        <vue-good-table
            :columns="columns_lcms"
            :rows="rows_lcms"
        ><template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'PMID'">
            <div style="cursor: pointer;margin-right: 10px" v-for="(item,index) in props.row[props.column.field].split(';')" :key="index" @click="toPub(item)">
              {{  props.row[props.column.field].split(';')[index] }}
              <i class="el-icon-my-export-new-window"></i>
            </div>
            <br/>
          </div>

        </template>
        </vue-good-table>
      </el-col>
    </el-row >
    <el-row>
      <el-col>
        <h1 style="color:#182978">Dynamic Datasets</h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-loading="Loading">
        <vue-good-table
            :columns="columns"
            :rows="rows"
            @on-row-click="onRowClick"
            :group-options="{
    enabled: true

  }"
            ref="table">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'GSM'">
              <div v-for="(item,index) in props.row.GSM.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
            <div v-else-if="props.column.field === 'Treatment'">
              <div v-for="(item,index) in props.row.Treatment.split(';')" :key="index" style="margin-bottom: 10px">
              <span v-if="item==='untreated'"
                    style="color:#FFFFFF;background-color: #6688CC;border-radius: 5px;border: 2px solid #6688cc">
                {{ item }}
                <br/>
              </span>

                <span v-else-if="item==='DM treated'"
                      style="color:#FFFFFF;background-color: #FF6A3D ;border-radius: 5px;border: 2px solid #FF6A3D ">
                {{ item }}
                 <br/>
              </span>
                <span v-else
                      style="color:#FFFFFF;background-color: #5DAA68 ;border-radius: 5px;border: 2px solid #5DAA68;white-space: nowrap">
                {{ item }}
              </span>
              </div>
            </div>
            <div v-else-if="props.column.field === 'Sample'">
              <div v-for="(item,index) in props.row.Sample.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
          </template>
          <template slot="table-header-row" slot-scope="props">
            <div v-if="props.row.label==1">
              <div>DM_MMU_T-cell</div>
              <div style="  float: right;">
                <el-switch
                    v-model="show1"
                    active-text="Show"
                    inactive-text="Hide">
                </el-switch>
              </div>
              <dataset-view-tissue v-if="show1" :label="1"></dataset-view-tissue>
            </div>
            <div v-else-if="props.row.label==2">
              <div>DM_MMU_MC57G</div>
              <div style="  float: right;" >
                <el-switch
                    v-model="show2"
                    active-text="Show"
                    inactive-text="Hide"
                >
                </el-switch>

              </div>
              <dataset-view-tissue v-if="show2" :label="2"></dataset-view-tissue>
            </div>
            <div v-else-if="props.row.label==3">
              <div>AQ_MTU</div>
              <div style="  float: right;" >
                <el-switch
                    v-model="show3"
                    active-text="Show"
                    inactive-text="Hide"
                >
                </el-switch>

              </div>
              <dataset-view-tissue v-if="show3" :label="3"></dataset-view-tissue>
            </div>



          </template>
        </vue-good-table>
      </el-col>
    </el-row>


  </div>

</template>

<script>
import axios from "axios";
import json2csv from "json2csv";
import DatasetViewTissue from "@/components/Dataset/DatasetViewTissue";
export default {
  components:{DatasetViewTissue},
  data() {
    return {
      show1:true,
      show2:false,
      show3:false,
      Loading: true, //加载动画
      Loading_lcms:true,
      columns: [
        {
          label: 'Dataset',
          field: 'Dataset',
        },
        {
          label: 'Species',
          field: 'Species',

        },
        {
          label: 'Sample',
          field: 'Sample',

        },
        {
          label: 'Technology',
          field: 'Technology',

        },
        {
          label: 'Treatment',
          field: 'Treatment',

        },
        {
          label: 'GSE',
          field: 'GSE',

        },
        {
          label: 'UnderConstruction',
          field: 'UnderConstruction',
          hidden: true,

        },
        {
          label: 'TechnologyClass',
          field: 'TechnologyClass',
          hidden: true,

        },
      ],
      rows: [],
      columns_lcms:[
        {label: 'Species',field: 'species'},
        {label: 'Condition/Stress',field: 'condition_stress'},
        {label: 'Strain/Cell Line',field: 'strain_cell_line'},
        {label: 'Method',field: 'method'},
        {label: 'PMID',field: 'PMID'},
        {label: 'Change of tRNA modification',field: 'change'},
      ],
      rows_lcms:[],
      isClickEvent:true,
    };
  },
  methods: {
    warning(){
      this.$message({
        showClose: true,
        message: 'Sorry, this dataset will be processed before November 13',
        type: 'warning'
      });
    },
    async getDataset() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-entry'
      })
      res = res.data
      let rows1 = []
      let rows2 = []
      let rows3 = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].dataset=="DM_MMU_T-cell_set1_Naive"||res[i].dataset=="DM_MMU_T-cell_set1_20"||res[i].dataset=="DM_MMU_T-cell_set1_48_hi-CD62L"||res[i].dataset=="DM_MMU_T-cell_set1_48_lo-CD62L"||res[i].dataset=="DM_MMU_T-cell_set1_72_hi-CD62L"||res[i].dataset=="DM_MMU_T-cell_set1_72_lo-CD62L"||res[i].dataset=="DM_MMU_T-cell_set2_20"||res[i].dataset=="DM_MMU_T-cell_set2_48"||res[i].dataset=="DM_MMU_T-cell_set2_72"||res[i].dataset=="DM_MMU_T-cell_set2_Navie"){
          rows1.push({
            Dataset:res[i].dataset,
            GSE: res[i].gse,
            Technology: res[i].technology,
            Treatment: res[i].treatment,
            Species: res[i].species,
            Sample: res[i].sample,
            UnderConstruction:res[i]["under_construction?"],
            TechnologyClass:res[i].technology_class,
          })
        }
        if (res[i].dataset=="DM_MMU_MC57G_mock"||res[i].dataset=="DM_MMU_MC57G_MHV68"){
          rows2.push({
            Dataset:res[i].dataset,
            GSE: res[i].gse,
            Technology: res[i].technology,
            Treatment: res[i].treatment,
            Species: res[i].species,
            Sample: res[i].sample,
            UnderConstruction:res[i]["under_construction?"],
            TechnologyClass:res[i].technology_class,
          })
        }
        if (res[i].dataset=="AQ_MTU_S4"||res[i].dataset=="AQ_MTU_S0"||res[i].dataset=="AQ_MTU_S10"){
          rows3.push({
            Dataset:res[i].dataset,
            GSE: res[i].gse,
            Technology: res[i].technology,
            Treatment: res[i].treatment,
            Species: res[i].species,
            Sample: res[i].sample,
            UnderConstruction:res[i]["under_construction?"],
            TechnologyClass:res[i].technology_class,
          })
        }

      }
      // 对象数组排序
      rows1.sort((a, b) => {
        return a.Dataset > b.Dataset ? 1 : -1
      })
      rows1.sort((a, b) => {
        return a.UnderConstruction > b.UnderConstruction ? 1 : -1
      })
      let group_rows=[
        {mode:'span',label:"1",children:rows1},
        {mode:'span',label:"2",children:rows2},
        {mode:'span',label:"3",children:rows3},
      ]
      console.log(group_rows)
      this.$data.rows = group_rows
      this.Loading = false
    },
    async getLCMS() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-lcms'
      })
      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
          rows.push({
            species:res[i].Species,
            condition_stress: res[i]["Condition/Stress"],
            strain_cell_line: res[i]["Strain/Cell Line"],
            method: res[i].Method,
            PMID: res[i].PMID,
            change: res[i]["Change of tRNA modification"],
          })
      }
      // 对象数组排序
      rows.sort((a, b) => {
        return a.Dataset > b.Dataset ? 1 : -1
      })
      rows.sort((a, b) => {
        return a.UnderConstruction > b.UnderConstruction ? 1 : -1
      })

      this.$data.rows_lcms = rows
      this.Loading_lcms = false
    },
    toPub(id){
      let url="https://pubmed.ncbi.nlm.nih.gov/"+id
      window.open(url, "_blank");
    },
    onRowClick(params) {
      if (this.isClickEvent){
        if (params.row.UnderConstruction===1){
          this.$message({
            showClose: true,
            message: 'Sorry, this dataset will be processed before November 13',
            type: 'warning'
          });
        }else {
          this.$router.push({
            path: "dataset-view",
            query: {
              dataset: params.row.Dataset,
            }
          })
        }


      }
    },
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    disableClickEvent(){
      this.isClickEvent=false
    },
    ableClickEvent(){
      this.isClickEvent=true
    }
  },
  mounted() {
    this.getDataset()
    this.getLCMS()
  },
}
</script>

<style scoped>

</style>