<template>
  <div>

    <el-row>
      <el-col :span="21">
        <el-form :inline="true" label-position="top" class="demo-form-inline" size="medium">
          <el-form-item label="Cellular Localization: ">
            <el-radio-group v-model="cyOrMt">
              <el-radio-button label="cytoplasmic" value="cytoplasmic"></el-radio-button>
              <el-radio-button label="mitochondrial" value="mitochondrial"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Modification or Enzyme: ">
            <el-radio-group v-model="modOrEnz">
              <el-radio-button label="modification" value="modification"></el-radio-button>
              <el-radio-button label="enzyme" value="enzyme"></el-radio-button>
            </el-radio-group>
            <el-button id="btn-save">Save</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3">
        <el-card shadow="never" v-show="modOrEnz==='enzyme'">
          <div slot="header">
            <span style="color:#182978">Enzyme Type</span>
          </div>
          <span>
            <i class="el-icon-my-export-writer"></i> Writer<br>
          </span>
          <span>
            <i class="el-icon-my-export-eraser"></i> Eraser<br>
          </span>
          <span>
            <i class="el-icon-my-export-reader"></i> Reader<br>
          </span>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
        <div id='pic_mod' style="width: 100%;height: 100%"></div>
    </el-row>
  </div>

</template>
<style>

svg {
  font: 12px "Sofia RE";
}

text {
  pointer-events: none;
}
.inner_node rect {
  pointer-events: all;
}

.inner_node rect.highlight {
  stroke: #315B7E;
  stroke-width: 2px;
}

/*.outer_node circle {*/
/*  fill: #fff;*/
/*  stroke: steelblue;*/
/*  stroke-width: 1.5px;*/
/*  pointer-events: all;*/
/*}*/

.outer_node circle.highlight
{
  stroke: #315B7E;
  stroke-width: 2px;
}

.link {
  fill: none;
}
</style>

<script>
//
// Generated by the Exaile Playlist Analyzer plugin.
// (C) 2014 Dustin Spicuzza <dustin@virtualroadside.com>
//
// This work is licensed under the Creative Commons Attribution 4.0
// International License. To view a copy of this license, visit
// http://creativecommons.org/licenses/by/4.0/.
//
// Inspired by http://www.findtheconversation.com/concept-map/
// Loosely based on http://bl.ocks.org/mbostock/4063550
//
function modification2SupOrSubForD3(text) {
  // 这个函数用来将文本的修饰名称格式化为有上下标的形式，用于d3的text标签
  // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
  let f = function ($1) {
    return $1.replace(",", "").replaceAll("per", "b");
  }
  let reg1 = new RegExp("[0-9]+", 'g');
  let reg2 = new RegExp(",<tspan baseline-shift=\"super\" style=\"font-size:0.5rem\">[0-9]+</tspan>", 'g');
  let text1 = text.replace(reg1, "<tspan baseline-shift=\"super\" style=\"font-size:0.5rem\">$&</tspan>");
  let text2 = text1.replace(reg2, f)
  let text3 = text2.replaceAll("Y", "Ψ")
  let text4 = text3.replaceAll("tau", "τ")
  return text4
  // modification2SupOrSub("m2G")
  // 'm<sup>2</sup>G'
  // modification2SupOrSub("m2,2tau")
  // 'm<sup>2</sup><sub>2</sub>τ'
}
import d3 from 'd3'
import eraser from "../assets/icon/eraser.svg"
import writer from "../assets/icon/pencil.svg"
import reader from "../assets/icon/search.svg"
export default {
  data() {
    return {
      screenWidth: window.innerWidth,   // 屏幕宽
      screenHeight:window.innerHeight,
      cyOrMt:"cytoplasmic",
      modOrEnz:"modification",
      dataCyMod : [
        ["-1",["xG"]],
        [1,[]],
        [2,[]],
        [3,[]],
        [4,["Cm","Um"]],
        [5,[]],
        [6,["m2G"]],
        [7,["m2G"]],
        [8,[]],
        [9,["m1G","m1A"]],
        [10,["m2G"]],
        [11,[]],
        [12,["ac4C"]],
        [13,["Y"]],
        [14,["m1A"]],
        [15,[]],
        [16,["D"]],
        [17,["D"]],
        ["17a",[]],
        [18,["Gm"]],
        [19,[]],
        [20,["D","acp3U","m3C"]],
        ["20a",["D","acp3U","Y"]],
        ["20b",["Y"]],
        [21,[]],
        [22,[]],
        [23,[]],
        [24,[]],
        [25,[]],
        [26,["m2,2G","m2G"]],
        [27,["Y","m2,2G"]],
        [28,["Y"]],
        [29,[]],
        [30,[]],
        [31,["Y","m3C"]],
        [32,["m3C","Y","Cm","Gm","Um","f5Cm","hm5Cm","mcm5Um"]],
        [33,[]],
        [34,["hm5C","hm5Cm","f5C","f5Cm","I","mchm5U","mcm5U","mcm5Um","s2U","mcm5s2U","cm5U","m5C","Q","Gm","Y","Cm","Um","galQ","manQ"]],
        [35,["Y"]],
        [36,[]],
        [37,["I","o2yW","m1I","ms2i6A","yW","t6A","i6A","m6t6A","m1G"]],
        [38,["m5C","Y"]],
        [39,["Y","m1Y","Ym","Gm"]],
        [40,["Y"]],
        [41,[]],
        [42,[]],
        [43,[]],
        [44,["Um"]],
        [45,[]],
        [46,["m7G"]],
        [47,["D","m3C"]],
        [48,["m5C"]],
        [49,["m5C"]],
        [50,["m5C","Y"]],
        [51,[]],
        [52,[]],
        [53,[]],
        [54,["m5U","Y","m5Um"]],
        [55,["Y"]],
        [56,[]],
        [57,[]],
        [58,["m1A"]],
        [59,[]],
        [60,[]],
        [61,[]],
        [62,[]],
        [63,[]],
        [64,[]],
        [65,[]],
        [66,[]],
        [67,["Y"]],
        [68,[]],
        [69,[]],
        [70,[]],
        [71,[]],
        [72,["m5C","Y"]],
        [73,[]],
      ],
      dataMtMod : [
        ["-1",["xG"]],
        [1,[]],
        [2,[]],
        [3,[]],
        [4,[]],
        [5,[]],
        [6,["m2G"]],
        [7,[]],
        [8,[]],
        [9,["m1G","m1A"]],
        [10,["m2G"]],
        [11,[]],
        [12,["ac4C"]],
        [13,["Y"]],
        [14,["m1A"]],
        [15,[]],
        [16,["m1A"]],
        [17,[]],
        ["17a",[]],
        [18,[]],
        [19,[]],
        [20,["D"]],
        ["20a",[]],
        ["20b",[]],
        [21,[]],
        [22,[]],
        [23,[]],
        [24,[]],
        [25,[]],
        [26,["m2,2G","m2G"]],
        [27,["Y"]],
        [28,["Y"]],
        [29,["Y"]],
        [30,[]],
        [31,["Y","m3C"]],
        [32,["m3C","Y"]],
        [33,[]],
        [34,["f5C","taum5U","taum5s2U","Q","m3C","m5C"]],
        [35,["Y"]],
        [36,[]],
        [37,["m1G","t6A","i6A","ms2i6A"]],
        [38,["Y"]],
        [39,["Y"]],
        [40,["Y"]],
        [41,[]],
        [42,[]],
        [43,[]],
        [44,[]],
        [45,[]],
        [46,[]],
        [47,[]],
        [48,["m5C"]],
        [49,["m5C"]],
        [50,["Y"]],
        [51,[]],
        [52,[]],
        [53,[]],
        [54,["m5U"]],
        [55,["Y"]],
        [56,[]],
        [57,["Y"]],
        [58,["m1A"]],
        [59,[]],
        [60,[]],
        [61,[]],
        [62,[]],
        [63,[]],
        [64,[]],
        [65,[]],
        [66,[]],
        [67,["Y"]],
        [68,[]],
        [69,[]],
        [70,[]],
        [71,[]],
        [72,["m5C"]],
        [73,[]]
      ],
      dataCyEnz:
          [
            ["-1",["THG1L"]],
            [1,[]],
            [2,[]],
            [3,[]],
            [4,["TRMT13"]],
            [5,[]],
            [6,["THUMPD3"]],
            [7,["THUMPD3"]],
            [8,[]],
            [9,["TRMT10A","TRMT10B"]],
            [10,["TRMT11"]],
            [11,[]],
            [12,["NAT10"]],
            [13,["PUS7"]],
            [14,[]],
            [15,[]],
            [16,["DUS1L"]],
            [17,["DUS1L"]],
            ["17a",[]],
            [18,[]],
            [19,[]],
            [20,["DUS2"]],
            ["20a",["DUS4L"]],
            ["20b",["DUS4L"]],
            [21,[]],
            [22,[]],
            [23,[]],
            [24,[]],
            [25,[]],
            [26,["TRMT1"]],
            [27,["PUS1"]],
            [28,["PUS1"]],
            [29,[]],
            [30,[]],
            [31,["RPUSD4"]],
            [32,["RPUSD4","METTL2A","METTL2B","METTL6","ALKBH1","ALKBH3"]],
            [33,[]],
            [34,["ALKBH1","PUS1","FTSJ1","NSUN2","ADAT2","ADAT3","ALKBH8","CTU1","CTU2","ELP3","GTPBP3","MTO1","NSUN3","QTRT1","TRMU","WDR6"]],
            [35,[]],
            [36,[]],
            [37,["ADAT1","CDK5RAP1","CDKAL1","LCMT2","OSGEP","TRIT1","TRMO","TRMT12","TRMT5","TYW3"]],
            [38,["TRDMT1","DNMT2"]],
            [39,["RPUSD4","PUS3"]],
            [40,[]],
            [41,[]],
            [42,[]],
            [43,[]],
            [44,["TRMT44"]],
            [45,[]],
            [46,["METTL1"]],
            [47,["DUS3L","ALKBH3"]],
            [48,["NSUN2"]],
            [49,["NSUN2"]],
            [50,["NSUN2"]],
            [51,[]],
            [52,[]],
            [53,[]],
            [54,["TRMT2B","PUS10","TRMT2A"]],
            [55,["TRUB2"]],
            [56,[]],
            [57,[]],
            [58,["TRMT61A","TRMT61B","TRMT6"]],
            [59,[]],
            [60,[]],
            [61,[]],
            [62,[]],
            [63,[]],
            [64,[]],
            [65,[]],
            [66,[]],
            [67,["PUS1"]],
            [68,[]],
            [69,[]],
            [70,[]],
            [71,[]],
            [72,["NSUN6"]],
            [73,[]]
          ],
      dataMtEnz:
          [
            ["-1",["THG1"]],
            [1,[]],
            [2,[]],
            [3,[]],
            [4,[]],
            [5,[]],
            [6,[]],
            [7,[]],
            [8,[]],
            [9,["TRMT10C"]],
            [10,["TRMT112","TRMT11"]],
            [11,[]],
            [12,[]],
            [13,[]],
            [14,[]],
            [15,[]],
            [16,[]],
            [17,[]],
            ["17a",[]],
            [18,[]],
            [19,[]],
            [20,["DUS2"]],
            ["20a",[]],
            ["20b",[]],
            [21,[]],
            [22,[]],
            [23,[]],
            [24,[]],
            [25,[]],
            [26,["TRMT1"]],
            [27,["PUS1"]],
            [28,["PUS1"]],
            [29,["PUS1"]],
            [30,[]],
            [31,[]],
            [32,["METTL2B"]],
            [33,[]],
            [34,["ALKBH1","TRMU","NSF1","GTPBP3","MTO1","NSUN3","QTRT1","QTRT2"]],
            [35,[]],
            [36,[]],
            [37,["OSGEPL1","TRIT1","YRDC","CDK5RAP1","TRMT5"]],
            [38,["PUS3"]],
            [39,["RPUSD4","PUS3"]],
            [40,[]],
            [41,[]],
            [42,[]],
            [43,[]],
            [44,[]],
            [45,[]],
            [46,[]],
            [47,[]],
            [48,["NSUN2"]],
            [49,["NSUN2"]],
            [50,[]],
            [51,[]],
            [52,[]],
            [53,[]],
            [54,["TRMT2B"]],
            [55,["TRUB2"]],
            [56,[]],
            [57,[]],
            [58,["TRMT61B"]],
            [59,[]],
            [60,[]],
            [61,[]],
            [62,[]],
            [63,[]],
            [64,[]],
            [65,[]],
            [66,[]],
            [67,["PUS1"]],
            [68,[]],
            [69,[]],
            [70,[]],
            [71,[]],
            [72,[]],
            [73,[]]
          ]
    }
  },
  methods:{
    draw(data){


// transform the data into a useful representation
// 1 is inner, 2, is outer

// need: inner, outer, links
//
// inner:
// links: { inner: outer: }
      var outer = d3.map();
      var inner = [];
      var links = [];

      var outerId = [0];

      data.forEach(function(d){

        if (d == null)
          return;

        i = { id: 'i' + inner.length, name: d[0], related_links: [] };
        i.related_nodes = [i.id];
        inner.push(i);

        if (!Array.isArray(d[1]))
          d[1] = [d[1]];

        d[1].forEach(function(d1){

          let o = outer.get(d1);

          if (o == null)
          {
            o = { name: d1,	id: 'o' + outerId[0], related_links: [] };
            o.related_nodes = [o.id];
            outerId[0] = outerId[0] + 1;

            outer.set(d1, o);
          }

          // create the links
          let l = { id: 'mod-l-' + i.id + '-' + o.id, inner: i, outer: o }
          links.push(l);

          // and the relationships
          i.related_nodes.push(o.id);
          i.related_links.push(l.id);
          o.related_nodes.push(i.id);
          o.related_links.push(l.id);
        });
      });

      data = {
        inner: inner,
        outer: outer.values(),
        links: links
      }

// sort the data -- TODO: have multiple sort options
      outer = data.outer;
      data.outer = Array(outer.length);


      var i1 = 0;
      var i2 = outer.length - 1;

      for (var i = 0; i < data.outer.length; ++i)
      {
        if (i % 2 == 1)
          data.outer[i2--] = outer[i];
        else
          data.outer[i1++] = outer[i];
      }

      console.log(data.outer.reduce(function(a,b) { return a + b.related_links.length; }, 0) / data.outer.length);


// from d3 colorbrewer:
// This product includes color specifications and designs developed by Cynthia Brewer (http://colorbrewer.org/).
      var colors = ['#5daf34','#6688CC','#FF6A3D','#b37df4','#F4DB7D']

      var x=[263,263,263,263,263,263,263,263,247,226,200,173,146,120,102,81,57,33,16,10,16,32,56,80,102,120,146,173,200,222,236,236,236,236,236,225,220,234,259,282,297,291,282,282,282,282,282,281,308,336,348,333,328,355,382,409,437,461,485,507,518,507,484,461,437,409,382,355,328,309,309,309,309,309,309,309,309]
      var y=[10,37,64,91,118,144,172,198,221,240,253,253,253,253,236,223,221,230,248,273,300,318,327,325,312,298,298,298,298,314,335,362,388,416,441,463,487,509,521,511,488,464,441,416,388,362,335,310,319,329,306,286,260,260,260,260,260,271,276,261,236,213,199,205,214,214,214,214,214,198,172,144,118,91,64,37,10]
      var diameter = 960;


      var link_width = "1px";

      var il = data.inner.length;
      var ol = data.outer.length;


      var mid = (data.outer.length/2.0)
      var outer_x = d3.scale.linear()
          .domain([0, mid, mid, data.outer.length])
          .range([15, 170, 190 ,355]);

      var outer_y = d3.scale.linear()
          .domain([0, data.outer.length])
          .range([0, diameter / 2 - 120]);


// setup positioning
      data.outer = data.outer.map(function(d, i) {
        d.x = outer_x(i);
        d.y = diameter/3;
        return d;
      });

      data.inner = data.inner.map(function(d, i) {
        d.x = x[i]-265
        d.y = y[i]-265
        return d;
      });


      function getCircleColor(name)
      {
        // var c = Math.round(color(name));
        // if (isNaN(c))
        //     return '#dddddd';	// fallback color
        //
        // return colors[c];
        var acceptorArm=[
          1,2,3,4,5,6,7,66,67,68,69,70,71,72,73
        ]
        var dArm=[
          10,11,12,13,14,15,16,17,"17a",18,19,20,"20a","20b",21,22,23,24,25,
        ]
        var anticodonArm=[
          27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43
        ]
        var variableArm=[
          44,45,46,47,48
        ]
        var tArm=[
          49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65
        ]
        if (acceptorArm.includes(name)){
          return colors[0]
        } else if (dArm.includes(name)){
          return colors[1]
        } else if (anticodonArm.includes(name)){
          return colors[2]
        } else if (variableArm.includes(name)){
          return colors[3]
        } else if (tArm.includes(name)){
          return colors[4]
        } else {
          return '#dddddd';	// fallback color
        }
      }
      function getTextColor(name)
      {
        // var c = Math.round(color(name));
        // if (isNaN(c))
        //     return '#dddddd';	// fallback color
        //
        // return colors[c];
        var acceptorArm=[
          1,2,3,4,5,6,7,66,67,68,69,70,71,72,73
        ]
        var dArm=[
          10,11,12,13,14,15,16,17,"17a",18,19,20,"20a","20b",21,22,23,24,25,
        ]
        var anticodonArm=[
          27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43
        ]
        var variableArm=[
          44,45,46,47,48
        ]
        var tArm=[
          49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65
        ]
        if (acceptorArm.includes(name)){
          return "white"
        } else if (dArm.includes(name)){
          return "white"
        } else if (anticodonArm.includes(name)){
          return "white"
        } else if (variableArm.includes(name)){
          return "white"
        } else if (tArm.includes(name)){
          return "black"
        } else {
          return "black";	// fallback color
        }
      }
// Can't just use d3.svg.diagonal because one edge is in normal space, the
// other edge is in radial space. Since we can't just ask d3 to do projection
// of a single point, do it ourselves the same way d3 would do it.


      function projectX(x)
      {
        return ((x - 90) / 180 * Math.PI) - (Math.PI/2);
      }

      var diagonal = d3.svg.diagonal()
          .source(function(d) { return {"x": d.outer.y * Math.cos(projectX(d.outer.x)),
            "y": -d.outer.y * Math.sin(projectX(d.outer.x))}; })
          .target(function(d) { return {"x": d.inner.y ,
            "y": d.outer.x > 180 ? d.inner.x : d.inner.x}; })
          .projection(function(d) { return [d.y, d.x]; });


      var svg = d3.select("#pic_mod").append("svg")
          .attr("width","100%")
          .attr("height", "100%")
          .attr("viewBox", "0 0 960 960")
          .append("g")
          .attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");


// links
      var link = svg.append('g').attr('class', 'links_mod').selectAll(".link_mod")
          .data(data.links)
          .enter().append('path')
          .attr('class', 'link')
          .attr('id', function(d) { return d.id })
          .attr("d", diagonal)
          .attr('stroke', function(d) { return getCircleColor(d.inner.name); })
          .attr('fill-opacity',0)
          .attr('stroke-width', link_width);

// outer nodes
      if  (this.modOrEnz==="modification"){
        var onode = svg.append('g').selectAll(".outer_node")
            .data(data.outer)
            .enter().append("g")
            .attr("class", "outer_node")
            .attr("transform", function(d) { return "rotate(" + (d.x - 90) + ")translate(" + d.y + ")"; })
            .on("mouseover", mouseover)
            .on("mouseout", mouseout)
            .on("click",click_mod);
        onode.append("circle")
            .attr('id', function(d) { return d.id })
            .attr("r", 4.5)
            .attr("fill","#fff")
            .attr("stroke","steelblue")
            .attr("stroke-width","1.5px")
        onode.append("circle")
            .attr('r', 20)
            .attr('visibility', 'hidden');

        onode.append("text")
            .attr('id', function(d) { return d.id + '-txt'; })
            .attr("dy", ".31em")
            .attr("dx", function(d) { return d.x < 180 ? "1em" :"-1em"; })
            .attr("text-anchor", function(d) { return d.x < 180 ? "start" : "end"; })
            .attr("transform", function(d) { return d.x < 180 ? "translate(8)" : "rotate(180)translate(-8)"; })
            .html(function(d) { return modification2SupOrSubForD3(d.name); })

      }else{
        var onode = svg.append('g').selectAll(".outer_node")
            .data(data.outer)
            .enter().append("g")
            .attr("class", "outer_node")
            .attr("transform", function(d) { return "rotate(" + (d.x - 90) + ")translate(" + d.y + ")"; })
            .on("mouseover", mouseover)
            .on("mouseout", mouseout)
            .on("click",click_enz);
        onode.append("circle")
            .attr('id', function(d) { return d.id })
            .attr("r", 4.5)
            .attr("fill","#fff")
            .attr("stroke","steelblue")
            .attr("stroke-width","1.5px")
            .attr('visibility', 'hidden');

        onode.append('image')
            .attr('xlink:href', function(d) { return ['FTO','ALKBH1','ALKBH3'].includes(d.name) ? eraser : writer; })
            .attr('x', function(d) { return d.x < 180 ? -18 : -2; })
            .attr('y',-10)
            .attr('height', 20)
            .attr('width', 20)
            .attr("transform", function(d) { return d.x < 180 ? "translate(8)" : "rotate(180)translate(-8)"; });

        onode.append("circle")
            .attr('r', 20)
            .attr("fill","#fff")
            .attr("stroke","steelblue")
            .attr("stroke-width","1.5px")
            .attr('visibility', 'hidden');


        onode.append("text")
            .attr('id', function(d) { return d.id + '-txt'; })
            .attr("dy", ".31em")
            .attr("dx", function(d) { return d.x < 180 ? "1em" :"-1em"; })
            .attr("text-anchor", function(d) { return d.x < 180 ? "start" : "end"; })
            .attr("transform", function(d) { return d.x < 180 ? "translate(8)" : "rotate(180)translate(-8)"; })
            .html(function(d) { return (d.name); })
      }





// inner nodes

      var inode = svg.append('g').selectAll(".inner_node")
          .data(data.inner)
          .enter().append("g")
          .attr("class", "inner_node")
          .attr("transform", function(d, i) { return "translate(" + d.x + "," + d.y + ")"})
          .on("mouseover", mouseover)
          .on("mouseout", mouseout);

      inode.append('circle')
          // .attr('width', rect_width)
          // .attr('height', rect_height)
          .attr('r',12)
          .attr('stroke', "black")
          .attr('id', function(d) { return d.id; })
          .attr('fill', function(d) { return getCircleColor(d.name); });

      inode.append("text")
          .attr('id', function(d) { return d.id + '-txt'; })
          .attr('text-anchor', 'middle')
          .attr("transform", "translate(0,5)")
          .attr('fill', function(d) { return getTextColor(d.name); })
          .text(function(d) { return d.name; });

// need to specify x/y/etc

      d3.select(self.frameElement).style("height", diameter - 150 + "px");

      function mouseover(d)
      {
        // bring to front
        d3.selectAll('.links .link').sort(function(a, b){ return d.related_links.indexOf(a.id); });

        for (var i = 0; i < d.related_nodes.length; i++)
        {
          d3.select('#' + d.related_nodes[i]).classed('highlight', true);
          d3.select('#' + d.related_nodes[i] + '-txt').attr("font-weight", 'bold');
        }

        for (var i = 0; i < d.related_links.length; i++)
          d3.select('#' + d.related_links[i]).attr('stroke-width', '5px');
      }

      function mouseout(d)
      {
        for (var i = 0; i < d.related_nodes.length; i++)
        {
          d3.select('#' + d.related_nodes[i]).classed('highlight', false);
          d3.select('#' + d.related_nodes[i] + '-txt').attr("font-weight", 'normal');
        }

        for (var i = 0; i < d.related_links.length; i++)
          d3.select('#' + d.related_links[i]).attr('stroke-width', link_width);
      }

      function click_mod(d){

        window.location.href="https://www.tmodbase.com/#/modification-view?name="+d.name
        console.log(d)
      }

      function click_enz(d){

        window.location.href="https://www.tmodbase.com/#/enzyme-view?name="+d.name
        console.log(d)
      }

      d3.select("#btn-save").on("click", function() {
        var svg = d3.select("svg").node(); // 获取SVG元素
        var svgXml = new XMLSerializer().serializeToString(svg); // 将SVG元素转化为XML字符串
        var svgBlob = new Blob([svgXml], {type:"image/svg+xml;charset=utf-8"}); // 创建Blob对象
        var svgUrl = URL.createObjectURL(svgBlob); // 生成URL地址
        var downloadLink = document.createElement("a"); // 创建下载链接
        downloadLink.href = svgUrl; // 设置链接地址
        downloadLink.download = "svg_file.svg"; // 设置文件名
        document.body.appendChild(downloadLink); // 添加链接到文档
        downloadLink.click(); // 模拟点击下载链接
      });
    }
  },
  mounted() {
    this.draw(this.dataCyMod)

  },
  watch:{
    cyOrMt(val){
      if (val==="cytoplasmic"&this.modOrEnz==="modification"){
        d3.select('svg').remove()
        this.draw(this.dataCyMod)
        console.log(val)
      }else if(val==="cytoplasmic"&this.modOrEnz==="enzyme"){
        d3.select('svg').remove()
        this.draw(this.dataCyEnz)
        console.log(val)
      }else if(val==="mitochondrial"&this.modOrEnz==="modification"){
        d3.select('svg').remove()
        this.draw(this.dataMtMod)
        console.log(val)
      }else if(val==="mitochondrial"&this.modOrEnz==="enzyme"){
        d3.select('svg').remove()
        this.draw(this.dataMtEnz)
        console.log(val)
      }
    },
    modOrEnz(val){
      if (val==="modification"&this.cyOrMt==="cytoplasmic"){
        d3.select('svg').remove()
        this.draw(this.dataCyMod)
        console.log(val)
      }else if(val==="modification"&this.cyOrMt==="mitochondrial"){
        d3.select('svg').remove()
        this.draw(this.dataMtMod)
        console.log(val)
      }else if(val==="enzyme"&this.cyOrMt==="cytoplasmic"){
        d3.select('svg').remove()
        this.draw(this.dataCyEnz)
        console.log(val)
      }else if(val==="enzyme"&this.cyOrMt==="mitochondrial"){
        d3.select('svg').remove()
        this.draw(this.dataMtEnz)
        console.log(val)
      }
    }

  }
}
</script>
