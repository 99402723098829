<template>
  <div>
    <el-row>
      <el-col >
        <h1 style="color:#182978">{{ this.$route.query.name }}</h1>
        <h2 style="color:#182978">{{ this.$route.query.spec }}</h2>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :span="12">
        <el-card shadow="never" header="">
          <div slot="header">
            <i class="el-icon-my-export-summary"></i>
            <span style="color:#182978">Summary</span>
          </div>
          <my-summary entity="trna"></my-summary>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never" header="">
          <div slot="header">
            <i class="el-icon-my-export-structure"></i>
            <span style="color:#182978">tRNA Structure</span>
          </div>
          <my-structure></my-structure>
        </el-card>
      </el-col>
    </el-row>
    <el-row >
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-sequence"></i>
            <span style="color:#182978">Sequence</span>
          </div>
          <el-table
              :data="tableData"
              style="width: 100%"
              :show-header=false>
            <el-table-column
                prop="title"
                label="Title"
                width="200px">
              <template slot-scope="scope">
                <span style="margin-left: 10px;font-weight: bold">{{ scope.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="Value"
            >
              <template slot-scope="scope">
                <span style="font-family: 'Courier New';">{{ scope.row.value }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
    </el-row>
    <el-row >
      <div v-if="this.$route.query.technology=='Pseudo-seq'">      <trna-pseudo ></trna-pseudo></div>

    </el-row>
    <el-row>
      <el-col >
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-chart"></i>
            <span style="color:#182978">Charts</span>
          </div>
          <trna-view-chart></trna-view-chart>
        </el-card>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import MySummary from "@/components/MySummary";
import MyStructure from "@/components/MyStructure";
import TrnaViewChart from "@/components/Trna/TrnaViewChart";
import TrnaPseudo from "@/components/Trna/TrnaPseudo";
import axios from "axios";
import TrnaExpression from "@/components/TrnaExpression";

export default {
  components: {TrnaPseudo, MySummary,MyStructure,TrnaViewChart,TrnaExpression},
  data(){
    return{
      tableData: [{
      }],

    }
  },
  methods:{
    async get_structure() {
      let url_base='https://43.138.148.56:20004/get-hg38/'
      if (this.$route.query.spec=="Homo sapiens"){
        url_base='https://43.138.148.56:20004/get-hg38/'
      }else if (this.$route.query.spec=="Mus musculus"){
        url_base='https://43.138.148.56:20004/get-mm39/'
      }else if (this.$route.query.spec=="Saccharomyces cerevisiae"){
        url_base='https://43.138.148.56:20004/get-sce-ref/'
      }
      let res = await axios({
        method: 'get',
        url: url_base + this.$route.query.name.replace(/-/g,"_"),
      })
      res=res.data
      console.log(res)
      this.tableData=[
        {title:"Sequence",value:res[0].sequence_db},
        {title:"Structure",value:res[0].structure},
        {title:"Alignment Sequence",value:res[0].sequence_alignment},
      ]
    },
  },
  mounted() {
    this.get_structure()
  }
}
</script>

<style>
.el-icon-my-export-structure {
  background: url('../../assets/icon/structure.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-summary {
  background: url('../../assets/icon/summary.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-sequence {
  background: url('../../assets/icon/alphabetical.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-chart {
  background: url('../../assets/icon/chart.svg') center no-repeat;
  background-size: cover;
}

/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>