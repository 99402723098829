<template>
  <div>

    <el-row>
      <el-col >
        <h1  style="color:#182978" v-html="this.modification2SupOrSub(this.$route.query.name)"></h1>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :span="12">
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-summary"></i>
            <span style="color:#182978">Summary</span>
          </div>
          <my-summary :entity="entity"></my-summary>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-enzyme"></i>
            <span style="color:#182978">Related Enzyme</span>
          </div>
          <vue-good-table
              :columns="columns_enz"
              :rows="rows_enz"
              @on-row-click="on_row_click_enzyme">
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'type'">
                <div v-for="(item,index) in props.row.type.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
              <div v-else-if="props.column.field === 'category'">
                <div v-for="(item,index) in props.row.category.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
              <div v-else-if="props.column.field === 'substrate'">
                <div v-for="(item,index) in props.row.substrate.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
              <div v-else-if="props.column.field === 'position'">
                <div v-for="(item,index) in props.row.position.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
            </template>

          </vue-good-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-loading="Loading" >
      <el-col>
        <el-card shadow="never" v-if="this.$data.rows_seq.length>0">
          <div slot="header">
            <span style="color:#182978">Known Modified tRNA</span>
          </div>
          <vue-good-table
              :columns="columns_seq"
              :rows="rows_seq"
              @on-row-click="on_row_click_tRNA"
              :pagination-options="{
        enabled: true,
        perPage: 10
        }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.row[props.column.field]===name" class="this" style="color:#FFFFFF;background-color: #6688CC;border-radius: 5px;border: 5px solid #6688cc">
                <div v-html="props.row[props.column.field+'_html']" v-if="props.column.field!=='name'">
                </div>
                <div v-else>
                  {{props.row.name}}
                </div>
              </div>
              <div v-else>
                <div v-html="props.row[props.column.field+'_html']" v-if="props.column.field!=='name'">
                </div>
                <div v-else class="row_name">
                  {{props.row.name}}
                </div>
              </div>

            </template>

          </vue-good-table>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import MySummary from "@/components/MySummary";
import axios from "axios";

export default {
  data() {
    return {
      Loading:true,
      name:this.$route.query.name,
      entity: "modification",
      columns_enz: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Position',
          field: 'position',
        },
      ],
      rows_enz: [],
      columns_seq: [
        {
          label: 'Name',
          field: 'name',
          width:'150px'
        },
        {
          label: 'Species',
          field: 'species',
        },
        {
          label: '-1',
          field: '-1',
          
        },
        {
          label: '1',
          field: '1',
          
        },
        {
          label: '2',
          field: '2',
          
        },
        {
          label: '3',
          field: '3',
          
        },
        {
          label: '4',
          field: '4',
          
        },
        {
          label: '5',
          field: '5',
          
        },
        {
          label: '6',
          field: '6',
          
        },
        {
          label: '7',
          field: '7',
          
        },
        {
          label: '8',
          field: '8',
          
        },
        {
          label: '9',
          field: '9',
          
        },
        {
          label: '10',
          field: '10',
          
        },
        {
          label: '11',
          field: '11',
          
        },
        {
          label: '12',
          field: '12',
          
        },
        {
          label: '13',
          field: '13',
          
        },
        {
          label: '14',
          field: '14',
          
        },
        {
          label: '15',
          field: '15',
          
        },
        {
          label: '16',
          field: '16',
          
        },
        {
          label: '17',
          field: '17',
          
        },
        {
          label: '17a',
          field: '17a'

        },
        {
          label: '18',
          field: '18',
          
        },
        {
          label: '19',
          field: '19',
          
        },
        {
          label: '20',
          field: '20',
          
        },
        {
          label: '20a',
          field: '20a',
          
        },
        {
          label: '20b',
          field: '20b',
          
        },
        {
          label: '21',
          field: '21',
          
        },
        {
          label: '22',
          field: '22',
          
        },
        {
          label: '23',
          field: '23',
          
        },
        {
          label: '24',
          field: '24',
          
        },
        {
          label: '25',
          field: '25',
          
        },
        {
          label: '26',
          field: '26',
          
        },
        {
          label: '27',
          field: '27',
          
        },
        {
          label: '28',
          field: '28',
          
        },
        {
          label: '29',
          field: '29',
          
        },
        {
          label: '30',
          field: '30',
          
        },
        {
          label: '31',
          field: '31',
          
        },
        {
          label: '32',
          field: '32',
          
        },
        {
          label: '33',
          field: '33',
          
        },
        {
          label: '34',
          field: '34',
          
        },
        {
          label: '35',
          field: '35',
          
        },
        {
          label: '36',
          field: '36',
          
        },
        {
          label: '37',
          field: '37',
          
        },
        {
          label: '38',
          field: '38',
          
        },
        {
          label: '39',
          field: '39',
          
        },
        {
          label: '40',
          field: '40',
          
        },
        {
          label: '41',
          field: '41',
          
        },
        {
          label: '42',
          field: '42',
          
        },
        {
          label: '43',
          field: '43',
          
        },
        {
          label: '44',
          field: '44',
          
        },
        {
          label: '45',
          field: '45',
          
        },
        {
          label: 'e11',
          field: 'e11',
          
        },
        {
          label: 'e12',
          field: 'e12',
          
        },
        {
          label: 'e13',
          field: 'e13',
          
        },
        {
          label: 'e14',
          field: 'e14',
          
        },
        {
          label: 'e15',
          field: 'e15',
          
        },
        {
          label: 'e16',
          field: 'e16',
          
        },
        {
          label: 'e17',
          field: 'e17',
          
        },
        {
          label: 'e1',
          field: 'e1',
          
        },
        {
          label: 'e2',
          field: 'e2',
          
        },
        {
          label: 'e3',
          field: 'e3',
          
        },
        {
          label: 'e4',
          field: 'e4',
          
        },
        {
          label: 'e5',
          field: 'e5',
          
        },
        {
          label: 'e27',
          field: 'e27',
          
        },
        {
          label: 'e26',
          field: 'e26',
          
        },
        {
          label: 'e25',
          field: 'e25',
          
        },
        {
          label: 'e24',
          field: 'e24',
          
        },
        {
          label: 'e23',
          field: 'e23',
          
        },
        {
          label: 'e22',
          field: 'e22',
          
        },
        {
          label: 'e21',
          field: 'e21',
          
        },
        {
          label: '46',
          field: '46',
          
        },
        {
          label: '47',
          field: '47',
          
        },
        {
          label: '48',
          field: '48',
          
        },
        {
          label: '49',
          field: '49',
          
        },
        {
          label: '50',
          field: '50',
          
        },
        {
          label: '51',
          field: '51',
          
        },
        {
          label: '52',
          field: '52',
          
        },
        {
          label: '53',
          field: '53',
          
        },
        {
          label: '54',
          field: '54',
          
        },
        {
          label: '55',
          field: '55',
          
        },
        {
          label: '56',
          field: '56',
          
        },
        {
          label: '57',
          field: '57',
          
        },
        {
          label: '58',
          field: '58',
          
        },
        {
          label: '59',
          field: '59',
          
        },
        {
          label: '60',
          field: '60',
          
        },
        {
          label: '61',
          field: '61',
          
        },
        {
          label: '62',
          field: '62',
          
        },
        {
          label: '63',
          field: '63',
          
        },
        {
          label: '64',
          field: '64',
          
        },
        {
          label: '65',
          field: '65',
          
        },
        {
          label: '66',
          field: '66',
          
        },
        {
          label: '67',
          field: '67',
          
        },
        {
          label: '68',
          field: '68',
          
        },
        {
          label: '69',
          field: '69',
          
        },
        {
          label: '70',
          field: '70',
          
        },
        {
          label: '71',
          field: '71',
          
        },
        {
          label: '72',
          field: '72',
          
        },
        {
          label: '73',
          field: '73',
          
        },
        {
          label: '74',
          field: '74',
          
        },
        {
          label: '75',
          field: '75',
          
        },
        {
          label: '76',
          field: '76',
        },
      ],
      rows_seq: [],
    }
  },
  name: "ModView",
  components: {MySummary},
  methods: {
    modification2SupOrSub(text){
      // 这个函数用来将文本的修饰名称格式化为有上下标的形式(html)
      // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      let text2 = text1.replace(reg2, f)
      let text3 = text2.replaceAll("Y", "Ψ")
      let text4 = text3.replaceAll("tau", "τ")
      return text4
      // modification2SupOrSub("m2G")
      // 'm<sup>2</sup>G'
      // modification2SupOrSub("m2,2tau")
      // 'm<sup>2</sup><sub>2</sub>τ'
    },
    async get_related_enzyme() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-related-enzyme/' + this.$route.query.name
      })
      res = res.data
      console.log(res[0])
      let rows_enz = []
      for (let i = 0; i < res.length; i++) {
        rows_enz[i] = {
          id: i + 1, name: res[i].enz_name,
          category: res[i].category,
          type: res[i].type,
          position: res[i].position,
        }
      }

      this.$data.rows_enz = rows_enz
    },
    async get_modomics() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-modomics/' + this.$route.query.name
      })
      res = res.data
      console.log(res)
      let rows_seq = []

      for (let i = 0; i < res.length; i++) {
        rows_seq[i] = res[i]
        let obj=rows_seq[i]
        let obj_key=Object.keys(obj)
        console.log(obj_key)
        let obj_value=Object.values(obj).map((item)=>{
          if (new RegExp("^tRNA").test(item.toString())){
            return item
          }else{
            return this.modification2SupOrSub(item.toString())
          }
        })
        console.log(obj_value)
        let new_obj = {};
        for (var j = 0; j < obj_key.length; j++) {
          new_obj[obj_key[j]+'_html'] = obj_value[j]
        }
        console.log(new_obj)
        rows_seq[i]={...new_obj,...rows_seq[i]}
        console.log(rows_seq[i])
      }

      this.$data.rows_seq = rows_seq
      this.Loading=false
    },
    on_row_click_enzyme(params){
      console.log(params)
      this.$router.push({path:"enzyme-view",query:{name:params.row.name}})
    },
    on_row_click_tRNA(params){
      console.log(params)
      let default_datasets={
        "Homo sapiens":"hydro_HSA_HEK293T",
        "Mus musculus":"QuantM_MMU_Cortex",
        "Saccharomyces cerevisiae":"ARM_SCE"
      }
      this.$router.push({path:"tRNA-view",query:{name:params.row.name,spec:params.row.species,dataset:default_datasets[params.row.species]}})
    },
    test(){
      let th=document.getElementsByClassName("row_name")[0]
      let div=document.getElementsByClassName("this")[0]
      let left=div.getBoundingClientRect().left-th.getBoundingClientRect().left
      document.getElementsByClassName("vgt-responsive")[1].scrollLeft=left-600
    }
  },
  mounted() {
    this.get_related_enzyme()
    this.get_modomics()
  }
}
</script>

<style scoped>
.el-icon-my-export-structure {
  background: url('../assets/icon/structure.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-enzyme {
  background: url('../assets/icon/enzyme.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-summary {
  background: url('../assets/icon/summary.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-sequence {
  background: url('../assets/icon/alphabetical.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-chart {
  background: url('../assets/icon/chart.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-publication {
  background: url('../assets/icon/journal.svg') center no-repeat;
  background-size: cover;
}
/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>