<template>
  <div >
    <div >
      <el-row>
        <el-col>
          <h1 style="color:#182978">Mod2Compare</h1>
        </el-col>
      </el-row>
      <el-row style="display:inline-block ">
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-filter"></i>
            <span style="color:#182978">Chart Options</span>
          </div>
          <el-form :inline="true" label-position="top" class="demo-form-inline" size="medium">
            <el-form-item label="Species: ">
              <el-radio-group v-model="selectedSpecies">
                <el-radio-button label="Homo sapiens" value="Homo sapiens"></el-radio-button>
                <el-radio-button label="Mus musculus" value="Mus musculus"></el-radio-button>
                <el-radio-button label="Saccharomyces cerevisiae" value="Saccharomyces cerevisiae"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Select 2 Datasets: ">
              <vue-good-table
                  @on-selected-rows-change="selectionChanged"
                  ref="my-table"
                  :columns="columns"
                  :rows="rows"
                  :pagination-options="{
        enabled: true,
        perPage: 20
        }"
                  styleClass="vgt-table condensed"
                  :select-options="{ enabled: true,
                   selectOnCheckboxOnly: true}"
              >
<!--                <template slot="table-row" slot-scope="props">-->
<!--                  <div v-if="props.row.gsm==datasetA" style="text-decoration: underline;font-weight: bolder">{{props.row[props.column.field]}}</div>-->

<!--                </template>-->
              </vue-good-table>
            </el-form-item>

            <el-form-item label="Or upload custom data: ">
              <el-upload
                  class="upload-demo"
                  drag
                  action="https://43.138.148.56:20004/uploader"
                  :before-upload="beforeUpload"
                  :limit="5"
                  :on-success="handle_success"
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drag and Drop File Uploading</div>
                <div class="el-upload__text">Only <em>CSV</em> files can be uploaded and not > 10M</div>
              </el-upload>
            </el-form-item>
            <el-popover
                placement="top-start"
                width="auto"
                trigger="hover"
                x-placement="top"
                content="Download Example Table">
              <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows_example,'tmodbase_example.csv')">
                <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
              </el-button>
            </el-popover>
            <br/>
            <el-form-item label="Show">
              <el-button-group>
                <el-button type="primary" style="font-family: 'Sofia RE',serif" icon="el-icon-my-export-intersection" @click="get_intersection()">Intersection</el-button>
                <el-button type="primary" style="font-family: 'Sofia RE',serif" icon="el-icon-my-export-full" @click="get_union()">Union</el-button>
                <el-button type="primary" style="font-family: 'Sofia RE',serif" icon="el-icon-my-export-A" @click="get_A()">A-B</el-button>
                <el-button type="primary" style="font-family: 'Sofia RE',serif" icon="el-icon-my-export-B" @click="get_B()">B-A</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </el-card>


      </el-row>
    </div>
    <div v-loading="Loading">
      <el-row style="text-align: right">
        <el-popover
            placement="top-start"
            width="auto"
            trigger="hover"
            x-placement="top"
            content="Download Whole Table">
          <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows_res,'tmodbase_dataset.csv')">
            <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
          </el-button>
        </el-popover>
        <el-popover
            placement="top-start"
            width="auto"
            trigger="hover"
            x-placement="top"
            content="Download Filtered Table">
          <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows,'tmodbase_dataset.csv')">
            <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
          </el-button>
        </el-popover>
      </el-row>
      <vue-good-table
          :columns="columns_res"
          :rows="rows_res"
          :pagination-options="{
        enabled: true,
        perPage: 20
        }"
      >
        <!--                <template slot="table-row" slot-scope="props">-->
        <!--                  <div v-if="props.row.gsm==datasetA" style="text-decoration: underline;font-weight: bolder">{{props.row[props.column.field]}}</div>-->

        <!--                </template>-->
      </vue-good-table>
      <div id="heatmap"  style="width: 800px; height: 800px" ref="container" v-loading="Loading"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import json2csv from "json2csv";
import { v4 as uuidv4 } from 'uuid';

const relativePos=["-1","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","17a","18","19","20","20a","20b","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","e11","e12","e13","e14","e15","e16","e17","e1","e2","e3","e4","e5","e27","e26","e25","e24","e23","e22","e21","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76"]
export default {
  data(){
    return{
      file_name:'',
      custom_data:[],
      rows_example:[
          {
            position_id:'HSA-mt-tRNA-Asn-GTT-11-m2G',
            A:857,
            C:485,G:0,T:476,stop:0.0035,cov:125986,ref:"G",dataset:"hydro_HSA_HEK293T"
          },
        {
          position_id:'HSA-mt-tRNA-Asn-GTT-11-m2G',
          A:41,C:40,G:0,T:34,stop:0.2715,cov:111882,ref:"G",dataset:"DM_HSA_HEK293T"
        }
      ],
      Loading:false,
      selectedSpecies:"Homo sapiens",
      min:0.1,
      dataset:[],
      columns: [
        {
          label: 'Dataset',
          field: 'Dataset',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Species',
          field: 'Species',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Sample',
          field: 'Sample',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Technology',
          field: 'Technology',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Treatment',
          field: 'Treatment',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'GSE',
          field: 'GSE',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'UnderConstruction',
          field: 'UnderConstruction',
          hidden: true,
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'TechnologyClass',
          field: 'TechnologyClass',
          hidden: true,
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
      ],
      rows:[],
      columns_res: [
        {
          label: 'Position ID',
          field: 'position_id',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetA A%',
          field: 'resA_A',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetA C%',
          field: 'resA_C',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetA G%',
          field: 'resA_G',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetA T%',
          field: 'resA_T',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetA total%',
          field: 'resA_total',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetB A%',
          field: 'resB_A',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetB C%',
          field: 'resB_C',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetB G%',
          field: 'resB_G',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetB T%',
          field: 'resB_T',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'DatasetB total%',
          field: 'resB_total',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },

      ],
      rows_res:[],
      options : {
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        tooltip:{
          formatter: function (params) {
            console.log(params)
            return (
                '<b>tRNA:  </b>'+
                params.data.name +
                '<br/>'+
                '<b>Dataset A Mismatch Rate:  </b>'+

                params.data.value[0].toFixed(2)  +
                '<br/>' +
                '<b>Dataset B Mismatch Rate: </b>'+
                params.data.value[1].toFixed(2)
            );

          }
        },
        xAxis: {
          max:1,
          name: 'DatasetA',
          nameLocation:'center',
          nameTextStyle:{
            fontSize:18,// 字体大小
            fontWeight: 400, // 字体粗细
          }
        },
        yAxis: {
          max: 1,
          name: 'DatasetB',
          nameLocation: 'center',
          nameTextStyle: {
            fontSize: 18,// 字体大小
            fontWeight: 400, // 字体粗细
          },
        },
        series:
          {
            symbolSize: 20,
            data: [{name:'hi',value:[10.0, 8.04]},
              {name:'hi',value:[20, 15]}
            ],
            type: 'scatter',
            markLine: {
              animation: false,
              lineStyle: {
                type: 'solid'
              },
              data: [
                [
                  {
                    coord: [0, 0],
                    symbol: 'none'
                  },
                  {
                    coord: [1, 1],
                    symbol: 'none'
                  }
                ]
              ]
            }
          }

      }
    }
  },
  methods:{
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["csv"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: 'Only CSV files can be uploaded',
          type: 'warning'
        })
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message({
          showClose: true,
          message: 'The size exceeds the limit',
          type: 'warning'
        })
        return false
      }
    },
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    async get_dataset() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-entry'
      })
      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].species==this.selectedSpecies){
          if (res[i]["under_construction?"] !== 1){
            rows.push({
              // vgtDisabled:res[i]["under_construction?"] === 1,
              Dataset:res[i].dataset,
              GSE: res[i].gse,
              Technology: res[i].technology,
              Treatment: res[i].treatment,
              Species: res[i].species,
              Sample: res[i].sample,
              UnderConstruction:res[i]["under_construction?"],
              TechnologyClass:res[i].technology_class,
            })
          }

        }
      }
      this.$data.rows = rows
    },
    selectionChanged(){
      console.log(this.$refs['my-table'].selectedRows)
      if (this.$refs['my-table'].selectedRows.length===2){
        this.$message({
          showClose: true,
          message: 'Maximum: 2',
          type: 'warning'
        });
      }
      if (this.$refs['my-table'].selectedRows.length>=3){
        this.$refs['my-table']. unselectAllInternal();
      }
    },
    async get_intersection() {
      this.Loading=true
      let url
      if (this.file_name!==''){
        url='https://43.138.148.56:20004/get-position-intersection-custom/'+this.file_name
      }else{
        url='https://43.138.148.56:20004/get-position-intersection/'+this.$refs['my-table'].selectedRows[0].Dataset.replaceAll("-","~")+"/"+this.$refs['my-table'].selectedRows[1].Dataset.replaceAll("-","~")
      }
      console.log(this.file_name)
      console.log(url)
      let res = await axios({
        method: 'get',
        url: url
      })

      res = res.data
      let rows_res=[]
      let data = []
      for (let i = 0; i < res.length; i++) {
          rows_res.push({
            position_id:res[i].position_id,
            resA_A:res[i].resA_A,
            resA_C:res[i].resA_C,
            resA_G:res[i].resA_G,
            resA_T:res[i].resA_T,
            resA_total:res[i].resA_total,
            resB_A:res[i].resB_A,
            resB_C:res[i].resB_C,
            resB_G:res[i].resB_G,
            resB_T:res[i].resB_T,
            resB_total:res[i].resB_total,
          })
        data.push({name:res[i].position_id,value:[res[i].resA_total,res[i].resB_total]})

      }
      this.rows_res=rows_res
      this.$data.options.series.data=data




      this.Loading=false
    },
    async get_union() {
      this.Loading=true
      let url
      if (this.file_name!==''){
        url='https://43.138.148.56:20004/get-position-union-custom/'+this.file_name
      }else{
        url='https://43.138.148.56:20004/get-position-union/'+this.$refs['my-table'].selectedRows[0].Dataset.replaceAll("-","~")+"/"+this.$refs['my-table'].selectedRows[1].Dataset.replaceAll("-","~")
      }
      console.log(this.file_name)
      console.log(url)
      let res = await axios({
        method: 'get',
        url: url
      })

      res = res.data
      let rows_res=[]
      let data = []
      for (let i = 0; i < res.length; i++) {
        rows_res.push({
          position_id:res[i].position_id,
          resA_A:res[i].resA_A,
          resA_C:res[i].resA_C,
          resA_G:res[i].resA_G,
          resA_T:res[i].resA_T,
          resA_total:res[i].resA_total,
          resB_A:res[i].resB_A,
          resB_C:res[i].resB_C,
          resB_G:res[i].resB_G,
          resB_T:res[i].resB_T,
          resB_total:res[i].resB_total,
        })
        data.push({name:res[i].position_id,value:[res[i].resA_total,res[i].resB_total]})

      }
      this.rows_res=rows_res
      this.$data.options.series.data=data




      this.Loading=false
    },
    async get_A() {
      this.Loading=true
      let url
      if (this.file_name!==''){
        url='https://43.138.148.56:20004/get-position-A-custom/'+this.file_name
      }else{
        url='https://43.138.148.56:20004/get-position-A/'+this.$refs['my-table'].selectedRows[0].Dataset.replaceAll("-","~")+"/"+this.$refs['my-table'].selectedRows[1].Dataset.replaceAll("-","~")
      }
      console.log(this.file_name)
      console.log(url)
      let res = await axios({
        method: 'get',
        url: url
      })

      res = res.data
      let rows_res=[]
      let data = []
      for (let i = 0; i < res.length; i++) {
        rows_res.push({
          position_id:res[i].position_id,
          resA_A:res[i].resA_A,
          resA_C:res[i].resA_C,
          resA_G:res[i].resA_G,
          resA_T:res[i].resA_T,
          resA_total:res[i].resA_total,
          resB_A:res[i].resB_A,
          resB_C:res[i].resB_C,
          resB_G:res[i].resB_G,
          resB_T:res[i].resB_T,
          resB_total:res[i].resB_total,
        })
        data.push({name:res[i].position_id,value:[res[i].resA_total,res[i].resB_total]})

      }
      this.rows_res=rows_res
      this.$data.options.series.data=data




      this.Loading=false
    },
    async get_B() {
      this.Loading=true
      let url
      if (this.file_name!==''){
        url='https://43.138.148.56:20004/get-position-B-custom/'+this.file_name
      }else{
        url='https://43.138.148.56:20004/get-position-B/'+this.$refs['my-table'].selectedRows[0].Dataset.replaceAll("-","~")+"/"+this.$refs['my-table'].selectedRows[1].Dataset.replaceAll("-","~")
      }
      console.log(this.file_name)
      console.log(url)
      let res = await axios({
        method: 'get',
        url: url
      })

      res = res.data
      let rows_res=[]
      let data = []
      for (let i = 0; i < res.length; i++) {
        rows_res.push({
          position_id:res[i].position_id,
          resA_A:res[i].resA_A,
          resA_C:res[i].resA_C,
          resA_G:res[i].resA_G,
          resA_T:res[i].resA_T,
          resA_total:res[i].resA_total,
          resB_A:res[i].resB_A,
          resB_C:res[i].resB_C,
          resB_G:res[i].resB_G,
          resB_T:res[i].resB_T,
          resB_total:res[i].resB_total,
        })
        data.push({name:res[i].position_id,value:[res[i].resA_total,res[i].resB_total]})

      }
      this.rows_res=rows_res
      this.$data.options.series.data=data




      this.Loading=false
    },
    handle_success(res,file) {
      this.$message.success('Upload Successes'+uuidv4())
      console.log(res)
      console.log(file)
      this.custom_data=res
      this.file_name=file.name
    }
  },

  mounted() {
    this.echartsinstance = echarts.init(this.$refs.container)
    this.echartsinstance.setOption(this.options)
    this.get_dataset()

  },
  watch:{

    selectedSpecies(){
      this.dataset=[],
      this.get_dataset()
    },
    'options.series.data'(){
      this.echartsinstance.setOption(this.options)
    },

    deep:true,
  },
  computed:{
    filteredRows:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.myTable.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }
  }

}
</script>

<style scoped>

</style>