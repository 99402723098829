<template>
  <div>
    <div>
      <el-row>
        <el-col>
          <h1 style="color:#182978">Modifications</h1>
        </el-col>
      </el-row>
      <el-row>
        <el-card shadow="never" header="">
          <div slot="header">
            <i class="el-icon-my-export-filter"></i>
            <span style="color:#182978">Quick Filter</span>
          </div>
          <el-form :inline="true" class="demo-form-inline" size="medium">
            <el-form-item label="Reference: ">
              <div>
                <el-checkbox-group v-model="reference.checked">
                  <el-checkbox-button :class="ref" v-for="ref in reference.reference" :label="ref" :key="ref">
                    {{ ref }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </div>
            </el-form-item>
            <el-form-item label="Category: ">
              <el-select v-model="category.checked" multiple placeholder="">
                <el-option
                    v-for="cat in category.category" :label="cat" :key="cat"
                    :value="cat">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Site: ">
              <el-select v-model="site.checked" multiple placeholder="">
                <el-option
                    v-for="site in site.site" :label="site" :key="site"
                    :value="site">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
        <el-col v-show="false">
          <el-card shadow="never">
            <el-row>
              <el-col :span="8">
                <el-card shadow="never" header="Reference">
                  <el-row>
                    <el-checkbox :indeterminate="reference.isIndeterminate" v-model="reference.checkAll"
                                 >All
                    </el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="reference.checked">
                      <el-checkbox v-for="ref in reference.reference" :label="ref" :key="ref">{{ ref }}</el-checkbox>
                    </el-checkbox-group>
                  </el-row>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card shadow="never" header="Category">
                  <el-row>
                    <el-checkbox :indeterminate="category.isIndeterminate" v-model="category.checkAll"
                                >
                    </el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="category.checked" >
                      <el-checkbox v-for="cat in category.category" :label="cat" :key="cat">{{ cat }}</el-checkbox>
                    </el-checkbox-group>
                  </el-row>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card shadow="never" header="Site">
                  <el-row>
                    <el-checkbox :indeterminate="site.isIndeterminate" v-model="site.checkAll"
                                  >
                    </el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="site.checked">
                      <el-checkbox v-for="site in site.site" :label="site" :key="site">{{ site }}</el-checkbox>
                    </el-checkbox-group>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows,'tmodbase_modification.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows,'tmodbase_modification.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row v-loading="Loading">
      <el-col>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            @on-row-click="on_row_click"
        ref="table">
          <!--          <template slot="table-row" slot-scope="props">-->
          <!--      <div v-if="props.column.field == 'name'">-->
          <!--      <div style="font-weight: bold; color: blue;">{{props.row.name}}</div>-->
          <!--      </div>-->
          <!--            <div v-else>-->
          <!--      {{props.formattedRow[props.column.field]}}-->
          <!--    </div>-->
          <!--          </template>-->
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'site'">
              <div v-for="(item,index) in props.row.site.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
            <div v-else-if="props.column.field === 'category'">
              <div v-for="(item,index) in props.row.category.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
            <div v-html="props.row.name_html" v-else-if="props.column.field === 'name'">

            </div>
            <span v-else-if="props.column.field === 'reference'">
             <span v-if="props.formattedRow.reference==='A'"
                   style="color:#FFFFFF;background-color: #5DAA68 ;border-radius: 5px;border: 5px solid #5DAA68 ">
                {{ props.formattedRow.reference }}
              </span>
              <span v-else-if="props.formattedRow.reference==='C'"
                    style="color:#FFFFFF;background-color: #6688CC ;border-radius: 5px;border: 5px solid #6688CC ">
                {{ props.formattedRow.reference }}
              </span>
              <span v-else-if="props.formattedRow.reference==='G'"
                    style="color:#FFFFFF;background-color: #F4DB7D ;border-radius: 5px;border: 5px solid #F4DB7D ">
                {{ props.formattedRow.reference }}
              </span>
              <span v-else-if="props.formattedRow.reference==='U'"
                    style="color:#FFFFFF;background-color: #FF6A3D ;border-radius: 5px;border: 5px solid #FF6A3D ">
                {{ props.formattedRow.reference }}
              </span>
              </span>
          </template>
        </vue-good-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import json2csv from "json2csv";

const referenceOptions = ['A', 'C', 'G', 'U'];
const categoryOptions = ['methylation', 'pseudouridine', 'others'];
const siteOptions = ['N1', 'N2', 'N3', 'N4', 'N5', 'N6', 'N7', 'sugar', 'others'];

export default {
  data() {
    return {
      Loading:true,
      reference: {
        checkAll: false,
        checked: [],
        reference: referenceOptions,
        isIndeterminate: true,
      },
      category: {
        checkAll: false,
        checked: [],
        category: categoryOptions,
        isIndeterminate: true,
      },
      site: {
        checkAll: false,
        checked: [],
        site: siteOptions,
        isIndeterminate: true,
      },
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'use Y for Ψ ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
          html: true
        },
        {
          label: 'Full Name',
          field: 'full_name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },
        {
          label: 'Reference',
          field: 'reference',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },

        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },
        {
          label: 'Site',
          field: 'site',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },

      ],
      rows: [],
    };
  },
  methods: {
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    on_row_click(params) {
      console.log(params)
      this.$router.push({path: "modification-view", query: {dataset: "none", name: params.row.name}})
    },
    to_sub_or_sup(text) {
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      return text1.replace(reg2, f)
    },
    Y_to_psi(text) {
      return text.replaceAll("Y", "Ψ")
    },
    tau_to_tau(text) {
      return text.replaceAll("tau", "τ")
    },

    columnFilterFn(data, filterString) {
      // 筛选函数
      // 筛选条件用逗号分割
      // 数据用分号分割
      let x = filterString.trim().split(",")
      let y = data.trim().split(";")
      // 筛去空字符串
      const filtered_x = x.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });
      const filtered_y = y.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });

      let res2 = filtered_x.map((item_x) => {
        let res1 = filtered_y.map((item_y) => {
          return item_y.toLowerCase().includes(item_x.toLowerCase())
        })
        return res1
      })
      return res2.flat().indexOf(true) !== -1
    },
    async get_testmod() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-modification-entry'
      })
      res = res.data
      console.log(res[0])
      let rows = []
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          id: i + 1,
          name: res[i].mod_name,
          reference: res[i].mod_reference,
          category: res[i].mod_category,
          site: res[i].mod_site,
          full_name: res[i].mod_full_name,
          name_html: this.modification2SupOrSub(res[i].mod_name)
        }
      }
      this.$data.rows = rows
      this.Loading=false
    }
  },
  computed:{
    filteredRows:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.table.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }},
  mounted() {
    this.get_testmod()
  },
  watch:{
    'reference.checked'(val){
      if (val===undefined){
        this.columns[2].filterOptions.filterValue=''
      }else{
        this.columns[2].filterOptions.filterValue=val.toString()
      }
    },
    'category.checked'(val){
      if (val===undefined){
        this.columns[3].filterOptions.filterValue=''
      }else{
        this.columns[3].filterOptions.filterValue=val.toString()
      }
    },
    'site.checked'(val){
      if (val===undefined){
        this.columns[4].filterOptions.filterValue=''
      }else{
        this.columns[4].filterOptions.filterValue=val.toString()
      }
    }
  }
};
</script>

<style scoped>
.el-form--inline .el-form-item {
  margin-right: 40px;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-icon-my-export-filter {
  background: url('../assets/icon/filter.svg') center no-repeat;
  background-size: cover;
}

/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}

</style>

<style>
.el-checkbox-button.is-checked.A .el-checkbox-button__inner {
  background-color: #5daf34;
}

.el-checkbox-button.is-checked.C .el-checkbox-button__inner {
  background-color: #6688CC;
}

.el-checkbox-button.is-checked.G .el-checkbox-button__inner {
  background-color: #F4DB7D;
}

.el-checkbox-button.is-checked.U .el-checkbox-button__inner {
  background-color: #FF6A3D;
}
</style>