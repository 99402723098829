<template>
  <div v-loading="Loading">
    <div id="echartsdemo1" :style="{width: '600px', height: '500px'}" style="display: inline-block" ref="container1"></div>
    <div id="echartsdemo2" :style="{width: '500px', height: '500px'}"  style="display: inline-block" ref="container2"></div>
  </div>

</template>

<script>
import * as echarts from 'echarts'
const arr=[];
for (let i = 0; i < 100; i++) {
  arr.push(i + 1);
}
export default {
  data() {
    return {
      Loading:true,
      options_pie:{
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        title: {
          text: 'Top 10 Counts',
          left: 'center'
        },
        tooltip: {
          trigger:'item',
          formatter: function (params) {
          return params.data.name+'<br/>'+params.marker+'<span style="font-weight: bold">'+params.data.value.toFixed(2)+'</span>'
          }
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series:
          {
            name:'tRNA',
            type: 'pie',
            radius: '50%',
            data: this.series_pie,
            itemStyle : {
              normal : {
                label : {
                  show : false
                },
                labelLine : {
                  show : false
                }
              },

            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              }
            }
          }

      },
      options_stack:{
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        animation: false,
        title: {
          text: 'Coverage',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data:arr
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: this.series_stack
      }
    }
  },
  methods:{
    Connect(chart1,chart2){
      chart1.on('mouseover',function(params){
        chart2.dispatchAction({
          type: 'legendInverseSelect',
        });
        if (params.name!="Others"){
          chart2.dispatchAction({
            type: 'legendSelect',
            name: params.name
          })
        }else{
          chart2.dispatchAction({
            type: 'legendAllSelect',
          });
        }
      });
      chart1.on('mouseout',function(){
        chart2.dispatchAction({
          type: 'legendAllSelect',
        });
      })
    }
  },
  mounted(){
    this.echartsinstance1 = echarts.init(this.$refs.container1)
    this.echartsinstance2 = echarts.init(this.$refs.container2)
    this.echartsinstance1.setOption(this.options_pie)
    this.echartsinstance2.setOption(this.options_stack)


  },

  watch:{
    'options_pie.series.data'(){
      this.echartsinstance1.setOption(this.options_pie)
    },
    'options_stack.series'(){
      this.echartsinstance2.setOption(this.options_stack)
    },
    series_pie(){
      this.options_pie.series.data=this.series_pie
      this.Loading=false
    },
    series_stack(){
      this.options_stack.series=this.series_stack
      this.Loading=false
      this.Connect(this.echartsinstance1,this.echartsinstance2)
    },

    deep: true
  },
  props: ['series_pie','series_stack']
}
</script>

<style scoped>

</style>