<template>
  <div>
    <el-row>
      <el-col >
        <h1 style="color:#182978">Modification to Disease</h1>
      </el-col>
    </el-row>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows,'tmodbase_disease.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows,'tmodbase_disease.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row v-loading="Loading">
      <el-col >
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :group-options="{
    enabled: true
  }"
        ref="table">
          <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'pmid'">

            <div  v-for="(item,index) in props.row[props.column.field].split(';')" :key="index" @click="toPub(item)">
              <span v-if="props.row.source.split(';')[index]=='r'" style="cursor: pointer;margin-right: 10px;color:#FFFFFF;background-color: #6688CC;border-radius: 5px;border: 2px solid #6688cc">
                {{  props.row[props.column.field].split(';')[index] }}
              </span>
              <span v-else-if="props.row.source.split(';')[index]=='pr'" style="cursor: pointer;margin-right: 10px;color:#FFFFFF;background-color: #FF6A3D;border-radius: 5px;border: 2px solid #FF6A3D">
                {{  props.row[props.column.field].split(';')[index] }}
              </span>
              <span v-else-if="props.row.source.split(';')[index]=='cr'" style="cursor: pointer; margin-right: 10px;color:#FFFFFF;background-color:#5DAA68;border-radius: 5px;border: 2px solid #5DAA68">
                {{  props.row[props.column.field].split(';')[index] }}
              </span>
              <br/><br/>
            </div>

            <br/>
          </div>
          <div v-else-if="(props.column.field === 'Mechanism')&(props.row.comments!=null)">
            <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                :content=props.row.comments>
              <span slot="reference">{{ props.row[props.column.field] }}</span>
            </el-popover>
            </div>
            <div v-html="props.row.mod_html" v-else-if="props.column.field === 'mod'">
              {{props}}
            </div>
        </template>
          <template slot="table-column" slot-scope="props">
            <div v-if="props.column.field == 'pmid'">
              PMID
              <div v-html="props.column.legends"></div>
            </div>
          </template>
        </vue-good-table>
      </el-col>
    </el-row >
  </div>
</template>

<script>
import axios from "axios";
import json2csv from "json2csv";

export default {
  name: 'my-component',
  data() {
    return {
      Loading:true,
      columns: [
        {label:"Disease",field:"disease"},
        {label:"Gene",field:"gene"},
        {label:"RNA Modification",field:"mod"},
        {label:"tRNA Type",field:"type"},
        {label:"Mechanism",field:"Mechanism"},
        {label:"PMID",
          field:"pmid",
          legends:'<span style="margin-right: 10px;font-weight:normal;font-size: 14px; 10px;color:#6688cc;white-space: nowrap">Review</span>' +
              '<br/>' +
              '<span style="margin-right: 10px;font-weight:normal;font-size: 14px;margin-bottom: 10px;color:#FF6A3D;white-space: nowrap">Primary Research</span>'+
              '<br/>' +
              '<span style="margin-right: 10px;font-weight:normal;font-size: 14px;margin-bottom: 10px;color:#5DAA68;white-space: nowrap">Case Report</span>'
        },
        {label:"Comments",field:"comments",hidden:true},
        {label:"Source",field:"source",hidden:true},
      ],
      rows: [],
    };
  },
  computed:{
    filteredRows:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.table.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }},
  methods: {
    modification2SupOrSub(text){
      // 这个函数用来将文本的修饰名称格式化为有上下标的形式(html)
      // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      let text2 = text1.replace(reg2, f)
      let text3 = text2.replaceAll("Y", "Ψ")
      let text4 = text3.replaceAll("tau", "τ")
      return text4
      // modification2SupOrSub("m2G")
      // 'm<sup>2</sup>G'
      // modification2SupOrSub("m2,2tau")
      // 'm<sup>2</sup><sub>2</sub>τ'
    },
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    toTS(id){
      let url="https://rna.sysu.edu.cn/tsRFun/searchDetail-tsRNA.php?tsRNAid="+id
      window.open(url, "_blank");
    },
    toPub(id){
      let url="https://pubmed.ncbi.nlm.nih.gov/"+id
      window.open(url, "_blank");
    },
    columnFilterFn(data, filterString) {
      // 筛选函数
      // 筛选条件用逗号分割
      // 数据用分号分割
      let x = filterString.toString().trim().split(",")
      let y = data.toString().trim().split(";")
      // 筛去空字符串
      const filtered_x = x.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });
      const filtered_y = y.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });

      let res2=filtered_x.map((item_x) => {
        let res1=filtered_y.map((item_y)=>{
          return item_y.toLowerCase().includes(item_x.toLowerCase())
        })
        return res1
      })
      return res2.flat().indexOf(true)!==-1
    },
    async get_disease() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-disease'
      })

      res = res.data
      let rows=[]
      let rows_md=[]
      let rows_nd=[]
      let rows_c=[]
      let rows_d=[]
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          disease:res[i].Disease,
          mod:res[i]['RNA modification'],
          mod_html:this.modification2SupOrSub(res[i]['RNA modification']),
          gene:res[i].Gene,
          type:res[i]['tRNA species'],
          pmid:res[i].PMID,
          Mechanism:res[i]['Mechanism'],
          comments:res[i]['Comments'],
          source:res[i]['source'],
        }
        switch (res[i].Category){
          case "Mitochondrial Disease":
            rows_md.push(rows[i])
                break;
          case "Neurological Disorder":
            rows_nd.push(rows[i])
            break;
          case "Cancer":
            rows_c.push(rows[i])
            break;
          case "Diabete":
            rows_d.push(rows[i])
            break;
        }
      }
      console.log(rows_md)
          let group_rows=[
          {mode:'span',label:"Mitochondrial disease",children:rows_md},
              {mode:'span',label:"Neurological disorder",children:rows_nd},
              {mode:'span',label:"Cancer",children:rows_c},
              {mode:'span',label:"Diabetes",children:rows_d}
          ]
      this.$data.rows = group_rows
      this.Loading=false
    },
    to_sub_or_sup(text) {
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      return text1.replace(reg2, f)
    },
    Y_to_psi(text) {
      return text.replaceAll("Y", "Ψ")
    },
    tau_to_tau(text) {
      return text.replaceAll("tau", "τ")
    },
    clear_selected_technology(){
      this.selected_technology=[]
    },
    clear_selected_sample(){
      this.selected_sample=[]
    },
    on_row_click(params){
      console.log(params)
      this.$router.push({path:"dataset-view",query:{dataset:"mim-tRNA-seq-dataset1",name:params.row.gsm}})
    },
  },

  mounted() {
    this.get_disease()
  },
  watch: {
    'form.selected_species'(val){
      console.log(val)
      if (val===undefined){
        this.columns[0].filterOptions.filterValue=''
      }else {
        this.columns[0].filterOptions.filterValue=val
      }
    },
    'form.selected_tRNA'(val){
      if (val[0]===undefined){
        this.columns[0].filterOptions.filterValue=''
      }else {
        this.columns[0].filterOptions.filterValue=val[0]
      }
      if (val[1]===undefined){
        this.columns[1].filterOptions.filterValue=''
      }else {
        this.columns[1].filterOptions.filterValue=val[1]
      }
    },
    'form.selected_modification'(val){
      console.log(val)
      if (val===undefined){
        this.columns[2].filterOptions.filterValue=''
      }else {
        this.columns[2].filterOptions.filterValue=val
      }
    },
    'form.chr'(val){
      console.log(val)
      if (val===undefined){
        this.columns[3].filterOptions.filterValue=''
      }else {
        this.columns[3].filterOptions.filterValue=val
      }
    },

    deep:true
  }
};
</script>

<style scoped>
.el-icon-my-export-new-window {
  background: url('../assets/icon/new_window.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-human {
  background: url('../assets/icon/human.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-mouse {
  background: url('../assets/icon/mouse.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-delete-white {
  background: url('../assets/icon/delete_white.svg') center no-repeat;
  background-size: cover;
}

/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>