<template>
<div v-loading="Loading">
<el-form>
  <el-form-item label="tRNA: ">
    <el-cascader-panel v-model="selected_trna" :options="tRNAList" :props="{
      emitPath:false
    }">

    </el-cascader-panel>
  </el-form-item>
  <el-form-item label="Normalization to Enzyme Expression: ">
    <el-switch
        v-model="normalization"
        active-text="Yes"
        inactive-text="No">
    </el-switch>
  </el-form-item>
  <el-form-item label="Related Enzyme(s): ">
    <el-button-group>
      <el-button type="primary" style="font-family: 'Sofia RE';font-size: 16px" v-for="(item,index) in related_enzymes" :key="index" @click="click(item)">
        {{item}}
      </el-button>
    </el-button-group>

  </el-form-item>
</el-form>
  <div id="echartsdemo" :style="{width: '1200px', height: '500px'}" ref="container"></div>
</div>
</template>

<script>

import axios from "axios";
import * as echarts from "echarts";
function ArrayAvg(myArray) {
  var i = 0, summ = 0, ArrayLen = myArray.length;
  while (i < ArrayLen) {
    summ = summ +  parseFloat(myArray[i++]);
  }
  return summ / ArrayLen;
}
function ArraySum(arr1,arr2){
  var result = arr1.map(function(index, item) {
    return index+ arr2[item];
  });
  return result
}

function ArrayDiv(arr1,arr2){
 let res=[]
  for (let i = 0; i <arr1.length ; i++) {
    res[i]=arr1[i]/arr2[i]
  }
  return res
}
const TCGA_abbr={
  "ACC":"Adrenocortical carcinoma",
  "BLCA":"Bladder Urothelial Carcinoma",
  "BRCA":"Breast invasive carcinoma",
  "CESC":"Cervical squamous cell carcinoma and endocervical adenocarcinoma",
  "CHOL":"Cholangiocarcinoma",
  "COAD":"Colon adenocarcinoma",
  "COADREAD":"Colon adenocarcinoma/Rectum adenocarcinoma Esophageal carcinoma",
  "DLBC":"Lymphoid Neoplasm Diffuse Large B-cell Lymphoma",
  "ESCA":"Esophageal carcinoma",
  "FPPP":"FFPE Pilot Phase II",
  "GBM":"Glioblastoma multiforme",
  "GBMLGG":"Glioma",
  "HNSC":"Head and Neck squamous cell carcinoma",
  "KICH":"Kidney Chromophobe",
  "KIPAN":"Pan-kidney cohort (KICH+KIRC+KIRP)",
  "KIRC":"Kidney renal clear cell carcinoma",
  "KIRP":"Kidney renal papillary cell carcinoma",
  "LAML":"Acute Myeloid Leukemia",
  "LGG":"Brain Lower Grade Glioma",
  "LIHC":"Liver hepatocellular carcinoma",
  "LUAD":"Lung adenocarcinoma",
  "LUSC":"Lung squamous cell carcinoma",
  "MESO":"Mesothelioma",
  "OV":"Ovarian serous cystadenocarcinoma",
  "PAAD":"Pancreatic adenocarcinoma",
  "PCPG":"Pheochromocytoma and Paraganglioma",
  "PRAD":"Prostate adenocarcinoma",
  "READ":"Rectum adenocarcinoma",
  "SARC":"Sarcoma",
  "SKCM":"Skin Cutaneous Melanoma",
  "STAD":"Stomach adenocarcinoma",
  "STES":"Stomach and Esophageal carcinoma",
  "TGCT":"Testicular Germ Cell Tumors",
  "THCA":"Thyroid carcinoma",
  "THYM":"Thymoma",
  "UCEC":"Uterine Corpus Endometrial Carcinoma",
  "UCS":"Uterine Carcinosarcoma",
  "UVM":"Uveal Melanoma",

}
export default {

  data(){
    return{
      Loading:true,
      activeName:"TCGA",
      selected_trna:'tRNA-Ala-AGC-8',
      enzyme_dict:{
        'tRNA-Ala-AGC-8':['TRMT11','DUS1L','DUS2','TRMT44','ADAT2','TRMT1','PUS3','METTL1','PUS10'],
        'tRNA-Ala-AGC-11':['TRMT11','DUS1L','DUS2','TRMT44','ADAT2','TRMT1','PUS3','METTL1','PUS10'],
        'tRNA-Glu-CTC-1':['TRMT11','PUS10','PUS1','PUS7','NSUN2','DUS2'],
        'tRNA-Phe-GAA-1':['TRMT11','DUS1L','TRMT1','PUS1','FTSJ1','LCMT2','TRMT12','TYW3']
      },
      normalization:true,
      tRNAList:[
        {value:"title",label: "Different AAs",disabled:true},
        {
        value:"Ala",
        label:"Ala",
        children:[
          {value:"title",label: "Isoacceptors",disabled:true},
          { value:"Ala-AGC",
            label:"Ala-AGC",
          children:[
            {value:"title",label: "Isodecoders",disabled:true},
            {value:"tRNA-Ala-AGC-8",
              label:"tRNA-Ala-AGC-8"},
            {value:"tRNA-Ala-AGC-11",
              label:"tRNA-Ala-AGC-11"}
          ]},
        ]
      },
        {

          value:"Glu",
          label:"Glu",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Glu-CTC",
              label:"Glu-CTC",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Glu-CTC-1",
                  label:"tRNA-Glu-CTC-1",}
              ]}
          ]
        },
        {

          value:"Phe",
          label:"Phe",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Phe-GAA",
              label:"Phe-GAA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Phe-GAA-1",
                  label:"tRNA-Phe-GAA-1",}
              ]}
          ]
        },
        {

          value:"Gly",
          label:"Gly",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Gly-CCC",
              label:"Gly-CCC",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Gly-CCC-2",
                  label:"tRNA-Gly-CCC-2",}
              ]},
            { value:"Gly-GCC",
              label:"Gly-GCC",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Gly-GCC-2",
                  label:"tRNA-Gly-GCC-2",}
              ]}
          ]
        },
        {

          value:"His",
          label:"His",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"His-GTG",
              label:"His-GTG",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-His-GTG-1",
                  label:"tRNA-His-GTG-1",}
              ]}
          ]
        },
        {

          value:"Leu",
          label:"Leu",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Leu-CAA",
              label:"Leu-CAA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Leu-CAA-1",
                  label:"tRNA-Leu-CAA-1",},
                {value:"tRNA-Leu-CAA-4",
                  label:"tRNA-Leu-CAA-4",}
              ]},
            { value:"Leu-CAG",
              label:"Leu-CAG",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Leu-CAG-1",
                  label:"tRNA-Leu-CAG-1",},
                {value:"tRNA-Leu-CAG-2",
                  label:"tRNA-Leu-CAG-2",}
              ]}
          ]
        },
        {

          value:"Met",
          label:"Met",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Met-CAT",
              label:"Met-CAT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Met-CAT-1",
                  label:"tRNA-Met-CAT-1",},
                {value:"tRNA-Met-CAT-2",
                  label:"tRNA-Met-CAT-2",}
              ]}
          ]
        },
        {

          value:"Asn",
          label:"Asn",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Asn-GTT",
              label:"Asn-GTT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Asn-GTT-1",
                  label:"tRNA-Asn-GTT-1",}
              ]}
          ]
        },
        {

          value:"Gln",
          label:"Gln",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Gln-TTG",
              label:"Gln-TTG",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Gln-TTG-1",
                  label:"tRNA-Gln-TTG-1",},
                {value:"tRNA-Gln-TTG-2",
                  label:"tRNA-Gln-TTG-2",}
              ]}
          ]
        },
        {

          value:"Ser",
          label:"Ser",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Ser-TGA",
              label:"Ser-TGA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Ser-TGA-1",
                  label:"tRNA-Ser-TGA-1",},
                {value:"tRNA-Ser-TGA-2",
                  label:"tRNA-Ser-TGA-2",}
              ]},
            { value:"Ser-AGA",
              label:"Ser-AGA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Ser-AGA-1",
                  label:"tRNA-Ser-AGA-1",}
              ]},
            { value:"Ser-CGA",
              label:"Ser-CGA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Ser-CGA-1",
                  label:"tRNA-Ser-CGA-1",}
              ]},
            { value:"Ser-GCT",
              label:"Ser-GCT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Ser-GCT-1",
                  label:"tRNA-Ser-GCT-1",}
              ]}
          ]
        },
        {

          value:"Val",
          label:"Val",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Val-AAC",
              label:"Val-AAC",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Val-AAC-1",
                  label:"tRNA-Val-AAC-1",}
              ]}
          ]
        },
        {

          value:"iMet",
          label:"iMet",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"iMet-CAT",
              label:"iMet-CAT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-iMet-CAT-1",
                  label:"tRNA-iMet-CAT-1",}
              ]}
          ]
        },
        {

          value:"Tyr",
          label:"Tyr",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Tyr-GTA",
              label:"Tyr-GTA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Tyr-GTA-2",
                  label:"tRNA-Tyr-GTA-2",},
                {value:"tRNA-Tyr-GTA-5",
                  label:"tRNA-Tyr-GTA-5",}
              ]}
          ]
        },
        {

          value:"SeC",
          label:"SeC",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"SeC-TCA",
              label:"SeC-TCA",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-SeC-TCA-1",
                  label:"tRNA-SeC-TCA-1",}
              ]}
          ]
        },
        {

          value:"Arg",
          label:"Arg",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Arg-CCT",
              label:"Arg-CCT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Arg-CCT-1",
                  label:"tRNA-Arg-CCT-1",}
              ]},
            { value:"Arg-TCT",
              label:"Arg-TCT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Arg-TCT-1",
                  label:"tRNA-Arg-TCT-1",}
              ]}
          ]
        },
        {

          value:"Thr",
          label:"Thr",
          children:[
            {value:"title",label: "Isoacceptors",disabled:true},
            { value:"Thr-AGT",
              label:"Thr-AGT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Thr-AGT-1",
                  label:"tRNA-Thr-AGT-1",}
              ]},
            { value:"Thr-CGT",
              label:"Thr-CGT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Thr-CGT-1",
                  label:"tRNA-Thr-CGT-1",}
              ]},
            { value:"Thr-TGT",
              label:"Thr-TGT",
              children:[
                {value:"title",label: "Isodecoders",disabled:true},
                {value:"tRNA-Thr-TGT-1",
                  label:"tRNA-Thr-TGT-1",}
              ]}
          ]
        },
      ],
      chartOptions:{
        chart: {
          type: 'column'
        },
        title: {
          text: 'Expression'
        },
        xAxis: {
          categories: [
            "ACC","BLCA","BRCA","CESC","CHOL","COAD","DLBC","ESCA","GBM","HNSC","KICH","KIRC","KIRP","LAML","LGG","LIHC","LUAD","LUSC","MESO","OV","PAAD","PCPG","PRAD","READ","SARC","SKCM","STAD","TGCT","THCA","THYM","UCEC","UCS","UVM"
          ],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: 'value'
          }
        },
        tooltip: {
          // head + 每个 point + footer 拼接成完整的 table
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            groupPadding: 0.05,
            pointPadding: 0.1,
            borderWidth: 0
          }
        },
        series: []
      },
      options:{
        tooltip:{
          formatter: function (params) {
            console.log(params)
            return  (params.seriesName+'<br/>'+
                params.marker+' '+params.name+' ('+ TCGA_abbr[params.name]+'): '+
                params.data.toFixed(2))
          }
        },
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        legend: {
        },
        xAxis: {
          type:'category',
          axisLabel: {
            interval:0,
            rotate:45
          },
          data: [
            "CESC","CHOL","COAD","DLBC","ESCA","GBM","HNSC","KICH","KIRC","KIRP","LGG","LIHC","LUAD","MESO","OV","PAAD","PCPG","PRAD","READ","SARC","SKCM","STAD","TGCT","THCA","THYM","UCEC","UCS","UVM"
          ],
        },
        yAxis: {
          type: 'value',
          name: 'score',
        },
        series:[]
      }
      }

  },
  methods:{
    click(enzyme){
      this.$router.push({path:"enzyme-view",query:{name:enzyme}})
    },
    async get_trna_expression(){
      this.Loading=true
      if (this.normalization==true&& this.related_enzymes!==undefined){
        var arr_n=[0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001]
        var arr_t=[0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001,0.001]

        for (let j = 0; j < this.related_enzymes.length; j++) {
          let res0 = await axios({
            method: 'get',
            url: 'https://43.138.148.56:20004/get-rmp/'+this.related_enzymes[j]
          })
          let res=res0.data
          let normalData=[]
          let tumorData=[]
          console.log(res[0])
          for (let i = 0; i < res.length; i++) {
            normalData[i]=ArrayAvg(eval(res[i].normal))
            tumorData[i]=ArrayAvg(eval(res[i].tumor))
          }
         arr_n=ArraySum(arr_n,normalData)
         arr_t=ArraySum(arr_t,tumorData)
        }

        arr_n.splice(0,3).splice(10,1).splice(14,1)
        arr_t.splice(0,3).splice(10,1).splice(14,1)

      }else{
        var arr_n=[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
        var arr_t=[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
      }
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-trna-expression/'+this.selected_trna+'-1'
      })
      res = res.data
      console.log(res)

      let normalData=[]
      let tumorData=[]
      console.log(res[0])
      for (let i = 0; i < res.length; i++) {
        normalData[i]=ArrayAvg(eval(res[i].normal))
        tumorData[i]=ArrayAvg(eval(res[i].tumor))

      }
      normalData=ArrayDiv(normalData,arr_n)
      tumorData=ArrayDiv(tumorData,arr_t)
      normalData = normalData.map(function (index) {
        return index *1000000
      })
     tumorData = tumorData.map(function (index) {
        return index *1000000
      })
      this.$data.options.series=[{name:"normal",color:"#6688CC",data:normalData,type:'bar'},{name:"tumor",color:"#FF6A3D",data:tumorData,type:'bar'}]
      this.Loading=false
    }
  },
  mounted() {

    this.echartsinstance = echarts.init(this.$refs.container)
    this.echartsinstance.setOption(this.options)
    this.get_trna_expression()

    let a= document.querySelectorAll('.el-cascader-menu__wrap');
    console.log(a)
  },
  watch:{
    'options.series'(){
      this.echartsinstance.setOption(this.options)
    },
    selected_trna(){
      this.get_trna_expression()
    },
    normalization(){this.get_trna_expression()}

  },
  computed:{
    related_enzymes: function () {
      return this.enzyme_dict[this.selected_trna]
    }
  }
}
</script>

<style >
.is-disabled {
  cursor:pointer !important;
}
.el-cascader-menu{
  width: 33%;
}
</style>