<template xmlns="http://www.w3.org/1999/html">
  <div v-loading="Loading">
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :fixed-header=true
        :sort-options="{
        enabled: true
        }"
        :pagination-options="{
        enabled: true,
        perPage: 10
        }"

        @on-row-click="on_row_click"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="misOrStp==='misincorporation'">
                <span v-if="props.column.field == 'sequence'">
        <div v-if="props.row.modomics!=''">
          <div style="display: inline-block;font-family: 'Courier New';font-weight: bold" v-for="(item,index) in props.row.sequence.split('')" :key="index">
            <div v-if=" (Math.max(...(props.row.mismatch[index]))>=value/100)&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])==-1">

              <el-popover
                  placement="top-start"
                  title="Mismatch% & Modification"
                  width="200"
                  trigger="hover"
              >
                <el-row style="margin: 0px">
                  <el-col :span="4">A:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][0]*100" color="#5DAA68"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">C:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][1]*100" color="#6688CC"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">G:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][2]*100" color="#F4DB7D"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">T:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][3]*100" color="#FF6A3D"></el-progress>
                  </el-col>
                </el-row>

          <span style="text-decoration: underline;color: #FFFFFF;background-color:#FF6A3D" slot="reference">{{ item }}</span>
                <span>Known modification:</span>
                <span v-html="modification2SupOrSub(props.row.modomics.split(';')[index]) "></span>
          </el-popover>
            </div>
            <div v-else-if="Math.max(...(props.row.mismatch[index]))>=value/100&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])!=-1">
              <el-popover
                  placement="top-start"
                  title="Mismatch%"
                  width="200"
                  trigger="hover"
              >
                <el-row style="margin: 0px">
                  <el-col :span="4">A:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][0]*100" color="#5DAA68"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">C:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][1]*100" color="#6688CC"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">G:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][2]*100" color="#F4DB7D"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">T:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][3]*100" color="#FF6A3D"></el-progress>
                  </el-col>
                </el-row>
          <span style="color: #FFFFFF;background-color: #182978" slot="reference">{{ item }}</span>
          </el-popover>
            </div>
            <div v-else-if="Math.max(...(props.row.mismatch[index]))<value/100&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])==-1">
              <el-popover
                  placement="top-start"
                  title="Modification"
                  width="200"
                  trigger="hover"
              >
                 <span>Known modification:</span>
                <span v-html="modification2SupOrSub(props.row.modomics.split(';')[index]) "></span>
          <span  style="text-decoration: underline;background-color:#F4DB7D" slot="reference">{{ item }}</span>
          </el-popover>
            </div>
            <div v-else-if="Math.max(...(props.row.mismatch[index]))<value/100&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])!=-1">
              <span>{{item}}</span>
            </div>
            <div v-else>
              <span >{{item}}</span>
            </div>

          </div>
        </div>
        <div v-else>
          <div style="display: inline-block;font-family: 'Courier New';font-weight: bold" v-for="(item,index) in props.row.sequence.split('')" :key="index">
            <div v-if=" (Math.max(...(props.row.mismatch[index]))>=value/100)">
              <el-popover
                  placement="top-start"
                  title="Mismatch%"
                  width="200"
                  trigger="hover"
              >
                <el-row style="margin: 0px">
                  <el-col :span="4">A:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][0]*100" color="#5DAA68"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">C:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][1]*100" color="#6688CC"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">G:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][2]*100" color="#F4DB7D"></el-progress>
                  </el-col>
                </el-row>
                <el-row style="margin: 0px">
                  <el-col :span="4">T:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.mismatch[index][3]*100" color="#FF6A3D"></el-progress>
                  </el-col>
                </el-row>
          <span  style="color: #FFFFFF;background-color: #182978" slot="reference">{{ item }}</span>
          </el-popover>
            </div>
            <div v-else>
              <span>{{item}}</span>
            </div>
          </div>

        </div>
    </span>
          <span v-else>
                {{ props.row[props.column.field] }}
          </span>
        </div>
        <div v-if="misOrStp==='stop'">
                <span v-if="props.column.field == 'sequence'">
        <div v-if="props.row.modomics!=''">
          <div style="display: inline-block;font-family: 'Courier New';font-weight: bold" v-for="(item,index) in props.row.sequence.split('')" :key="index">
            <div v-if=" (props.row.stop[index]>=value/100)&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])==-1">

              <el-popover
                  placement="top-start"
                  title="Stop% & Modification"
                  width="200"
                  trigger="hover"
              >
                <el-row style="margin: 0px">

                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.stop[index]*100" color="#6688CC"></el-progress>
                  </el-col>
                </el-row>


          <span style="text-decoration: underline;color: #FFFFFF;background-color:#FF6A3D" slot="reference">{{ item }}</span>
                <span>Known modification:</span>
                <span v-html="modification2SupOrSub(props.row.modomics.split(';')[index]) "></span>          </el-popover>
            </div>
            <div v-else-if=" (props.row.stop[index]>=value/100)>=value/100&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])!=-1">
              <el-popover
                  placement="top-start"
                  title="Stop%"
                  width="200"
                  trigger="hover"
              >
                <el-row style="margin: 0px">
                  <el-col :span="4">A:</el-col>
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.stop[index]*100" color="#6688CC"></el-progress>
                  </el-col>
                </el-row>

          <span style="color: #FFFFFF;background-color: #182978" slot="reference">{{ item }}</span>
          </el-popover>
            </div>
            <div v-else-if=" (props.row.stop[index]>=value/100)<value/100&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])==-1">
              <el-popover
                  placement="top-start"
                  title="Modification"
                  width="200"
                  trigger="hover"
              >
                 <span>Known modification:</span>
                <span v-html="modification2SupOrSub(props.row.modomics.split(';')[index]) "></span>
          <span  style="text-decoration: underline;background-color:#F4DB7D" slot="reference">{{ item }}</span>
          </el-popover>
            </div>
            <div v-else-if=" (props.row.stop[index]>=value/100)<value/100&['A','C','G','U','-'].indexOf(props.row.modomics.split(';')[index])!=-1">
              <span>{{item}}</span>
            </div>
            <div v-else>
              <span >{{item}}</span>
            </div>

          </div>
        </div>
        <div v-else>
          <div style="display: inline-block;font-family: 'Courier New';font-weight: bold" v-for="(item,index) in props.row.sequence.split('')" :key="index">
            <div v-if=" (props.row.stop[index]>=value/100)">
              <el-popover
                  placement="top-start"
                  title="Stop%"
                  width="200"
                  trigger="hover"
              >
                <el-row style="margin: 0px">
                  <el-col :span="20">
                    <el-progress :text-inside="false" :format="format" :stroke-width="5" :percentage="props.row.stop[index]*100" color="#6688CC"></el-progress>
                  </el-col>
                </el-row>

          <span  style="color: #FFFFFF;background-color: #182978" slot="reference">{{ item }}</span>
          </el-popover>
            </div>
            <div v-else>
              <span>{{item}}</span>
            </div>
          </div>

        </div>
    </span>
          <span v-else>
                {{ props.row[props.column.field] }}
          </span>
        </div>
      </template>
      <template slot="column-filter" slot-scope="props">
        <div v-if="props.column.field == 'sequence'">
          <div v-html="props.column.structure"></div>
          <br/>
          <div v-html="props.column.stem_loop"></div>
        </div>
      </template>
      <template slot="table-actions-bottom">
        <el-row class="th-like">
          <b>Table Options</b>
        </el-row>
        <el-row>
          <div style="margin-left: 20px;margin-right: 20px">
            <el-row>
              <el-col :span="6">
                <el-form  label-position="top">
                  <el-form-item label="Misincorporation or Stop Signal: ">
                    <el-radio-group v-model="misOrStp">
                      <el-radio-button label="misincorporation" value="misincorporation"></el-radio-button>
                      <el-radio-button label="stop" value="stop"></el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </el-col>

              <el-col :span="10">
                <el-card shadow="never">
                  <div slot="header">
                    <span style="color:#182978">Description</span>
                  </div>
                  <span style="color: #FFFFFF;background-color: #182978;font-family: 'Courier New'">A</span>
                  <span> Base With Signal > Threshold; </span><br/>
                  <span style="text-decoration: underline;background-color:#F4DB7D;font-family: 'Courier New'">A</span>
                  <span> Base With Known Modification; </span><br/>
                  <span style="text-decoration: underline;color: #FFFFFF;background-color:#FF6A3D;font-family: 'Courier New'">A</span>
                  <span> Base With Signal > Threshold & Known Modification</span>
                </el-card>
              </el-col>
            </el-row>
            <el-form  label-position="top">
              <el-form-item label="Signal Threshold:" style="width: 60%">
                <el-slider
                    v-model="value"
                    show-input
                    :min="1"
                >
                </el-slider>
              </el-form-item>
            </el-form>
          </div>
        </el-row>

      </template>
    </vue-good-table>
  </div>

</template>

<script>

export default {
  name: "DataViewTable",
  data(){
    return{
      species_conversion:{
        'HSA':'Homo sapiens',
        'MMU':'Mus musculus',
        'SCE':'Saccharomyces cerevisiae',
      },
      Loading:true,
      misOrStp:"misincorporation",
      value:10,
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Sequence',
          field: 'sequence',
          structure:'<div style="font-family: Courier New">.<span style="background-color: #FFCC99">(((((((</span>..<span style="background-color: #CCFFCC">((((</span>...........<span style="background-color: #CCFFCC">))))</span>.<span style="background-color:#66CCFF">(((((</span>.......<span style="background-color: #66CCFF">)))))</span>........................<span style="background-color: #FFCCCC">(((((</span>.......<span style="background-color:#FFCCCC">)))))</span><span style="background-color: #FFCC99">)))))))</span>.<span style="background-color:#ABFFFF">...</span></div>',
          stem_loop:'<div style="font-family: Courier New;white-space:pre"> <span style="background-color: #FFCC99"> Acc-s </span>  <span style="background-color:#CCFFCC">D-s </span>  D-loop   <span style="background-color:#CCFFCC">D-s </span> <span style="background-color: #66CCFF">Ac-s </span>Ac-loop<span style="background-color: #66CCFF">Ac-s </span>    Variable-Region     <span style="background-color: #FFCCCC"> T-s </span>T-loop <span style="background-color:#FFCCCC"> T-s </span><span style="background-color: #FFCC99"> Acc-s </span> <span style="background-color: #ABFFFF">ACC</span></div>',
          filterOptions: {
            styleClass:"class1"
          }
        }
      ],
    }
  },
  methods:{
    format(percentage){
      return ''
    },
    on_row_click(params){
      console.log(this.$data.species_conversion[this.$route.query.dataset.split('_')])
        this.$router.push({path:"tRNA-view",query:{name:params.row.name,spec:this.$data.species_conversion[this.$route.query.dataset.split('_')[1]],dataset:this.$route.query.dataset}})
    },
    modification2SupOrSub(text){
      // 这个函数用来将文本的修饰名称格式化为有上下标的形式(html)
      // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      let text2 = text1.replace(reg2, f)
      let text3 = text2.replaceAll("Y", "Ψ")
      let text4 = text3.replaceAll("tau", "τ")
      return text4
      // modification2SupOrSub("m2G")
      // 'm<sup>2</sup>G'
      // modification2SupOrSub("m2,2tau")
      // 'm<sup>2</sup><sub>2</sub>τ'
    },
  },
  components:{

  },
  watch:{
    rows(){
      this.Loading=false
    },
  },
  props:['rows']
}
</script>

<style>
 .class1{
   padding: 1px 6px !important;
   text-align: left !important;
 }
 .th-like{
   color: #606266;
   vertical-align: bottom;
   border: 1px solid #dcdfe6;
   background: linear-gradient(#f4f5f8,#f1f3f6);
   padding: .75em 1.5em .75em .75em;

   position: relative;
   text-align: left;
   font-size: 16px;
   border-collapse: collapse;

   width: 100%;
   max-width: 100%;
   table-layout: auto;

 }
</style>