<template>
  <div>
  <el-row>
<!--    屏幕宽度小于一定值后，用下拉菜单代替导航栏-->
    <el-container v-if="screenWidth>910">
      <el-col class="menu-background" style="display: inherit">
        <el-menu class="iconbar"   mode="horizontal"
                 unique-opened
                 router
                 text-color="#182978"
                 active-text-color="#FF6A3D">
          <el-menu-item disabled >
            <router-link to="/home" tag="div">
              <i class="el-icon-my-export-icon" style="font-size: 40px"></i>
            </router-link>
          </el-menu-item>
        </el-menu>
        <el-menu class="menubar" :default-active="routerPath2Index[this.$route.path]" mode="horizontal"
                 unique-opened
                 router
                 text-color="#182978"
                 active-text-color="#FF6A3D">

          <el-menu-item index="home">
            <span  class="menu-title">Home</span>
          </el-menu-item>

          <el-menu-item index="dataset">
            <span  class="menu-title">Dataset</span>
          </el-menu-item>
          <el-menu-item index="dynamics">
            <span  class="menu-title">Dynamics</span>
          </el-menu-item>
          <el-menu-item index="compare">
            <span class="menu-title">Mod2Compare</span>
          </el-menu-item>
          <el-menu-item index="trna">
            <span  class="menu-title">tRNA</span>
          </el-menu-item>
          <el-menu-item index="position">
            <span  class="menu-title">Position</span>
          </el-menu-item>
          <el-menu-item index="modification">
            <span  class="menu-title">Modification</span>
          </el-menu-item>
          <el-menu-item index="enzyme">
            <span  class="menu-title">Enzyme</span>
          </el-menu-item>
          <el-menu-item index="mod-to-tsrna">
            <span  class="menu-title">Mod2tsRNA</span>
          </el-menu-item>
          <el-menu-item index="mod-to-disease">
            <span  class="menu-title">Mod2Disease</span>
          </el-menu-item>

          <el-menu-item index="mod-to-var">
            <span  class="menu-title">Mod2Var</span>
          </el-menu-item>
          <el-menu-item index="tutorial">
            <span  class="menu-title">Tutorial</span>
          </el-menu-item>
          <el-menu-item index="download">
            <span class="menu-title">Download</span>
          </el-menu-item>
          <el-menu-item index="about">
            <span class="menu-title">About</span>
          </el-menu-item>

        </el-menu>
      </el-col>
    </el-container>
    <el-container v-else>
      <div>
        <el-dropdown>
          <el-button type="primary">
            <i class="el-icon-my-export-menu"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link :to="{ path: '/home' }" tag="div">Home</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/dataset' }" tag="div">Dataset</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/dynamics' }" tag="div">Dynamics</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/compare' }" tag="div">Mod2Compare</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/trna' }" tag="div">tRNA</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/position' }" tag="div">Position</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/modification' }" tag="div">Modification</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/enzyme' }" tag="div">Enzyme</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/mod-to-disease' }" tag="div">Mod2Disease</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/mod-to-tsRNA' }" tag="div">Mod2tsRNA</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/mod-to-var' }" tag="div">Mod2Var</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/tutorial' }" tag="div">Tutorial</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/download' }" tag="div">Download</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ path: '/about' }" tag="div">About</router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-container>
  </el-row>
    <el-row>
<!--      在xs,sm和md屏幕取消4的offset-->
      <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:16,offset:4}" :lg="{span:16,offset:4}" :xl="{span:16,offset:4}">
        <el-card shadow="never">
          <router-view></router-view>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import logo from '@/assets/logo.svg'
export default {
  data() {
    return {
      src: logo,
      screenWidth: document.body.clientWidth, //屏幕尺寸
      routerPath2Index:{
        "/home":"home",
        "/dataset":"dataset",
        "/dataset-view":"dataset",
        "/trna":"trna",
        "/trna-view":"trna",
        "/modification":"modification",
        "/modification-view":"modification",
        "/enzyme":"enzyme",
        "/enzyme-view":"enzyme",
        "/mod-to-disease":"mod-to-disease",
        "/mod-to-tsrna":"mod-to-tsrna",
        "/mod-to-var":"mod-to-var",
        "/tutorial":"tutorial",
        "/download":"download",
      } //页面跳转和导航栏绑定
    }
  },
  mounted() {
    // 获取屏幕尺寸
      const that = this
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          that.screenWidth = window.screenWidth
        })()
    }
  }
}
</script>

<style scoped>
.el-menu-item [class^=el-icon-]{
  width: 60px;
}

.menu-title{
  font-weight: bold;
  font-size: medium;
}
.el-menu-item.is-disabled{
  cursor: pointer;
  opacity: 1;
  float: left;
}
.menu-background{
  background-color: #fff;
}
.menubar{
  margin-left: 5%;
  margin-right: 5%;
}
</style>

