<template>
  <div>
    <el-row>
      <el-col >
        <h1 style="color:#182978">{{this.$route.query.name}}</h1>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :span="12">
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-summary"></i>
            <span style="color:#182978">Summary</span>
          </div>
          <my-summary :entity="entity"></my-summary>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-pencil"></i>
            <span style="color:#182978">Related Modification</span>
          </div>
          <vue-good-table
              :columns="columns_mod"
              :rows="rows_mod"
              @on-row-click="on_row_click_modification"
          >
            <template slot="table-row" slot-scope="props">

              <div v-if="props.column.field === 'category'">
                <div v-for="(item,index) in props.row.category.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
              <div v-else-if="props.column.field === 'substrate'">
                <div v-for="(item,index) in props.row.substrate.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
              <div v-else-if="props.column.field === 'position'">
                <div v-for="(item,index) in props.row.position.split(';')" :key="index">
                  {{item}}
                  <br/>
                </div>
              </div>
              <div  v-html="props.row.name_html" v-else-if="props.column.field === 'name'">
              </div>
            </template>

          </vue-good-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
<!--          <el-card shadow="never" >-->
<!--            <div slot="header">-->
<!--              <i class="el-icon-my-export-publication"></i>-->
<!--              <span style="color:#182978">Publication</span>-->
<!--            </div>-->
<!--            <vue-good-table-->
<!--                :columns="columns_pub"-->
<!--                :rows="rows_pub"-->
<!--                @on-row-click="on_row_click_pub"-->
<!--            >-->
<!--              <template slot="table-row" slot-scope="props">-->
<!--                <div v-if="props.column.field === 'author'">-->
<!--                  <div v-for="(item,index) in props.row.author.split(';')" :key="index" style="white-space: nowrap">-->
<!--                    {{item}}-->
<!--                    <br/>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
<!--            </vue-good-table>-->
<!--          </el-card>-->
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-card shadow="never" >
          <div slot="header">
            <i class="el-icon-my-export-chart"></i>
            <span style="color:#182978">Expression in Cancers</span>
          </div>
          <enz-expression></enz-expression>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EnzExpression from "@/components/EnzymeExpression";
import MySummary from "@/components/MySummary";
import axios from "axios";
export default {
  components:{EnzExpression,MySummary},
  data(){
    return{
      entity:"enzyme",
      columns_mod: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Position',
          field: 'position',
        },
      ],
      rows_mod: [],
      columns_pub: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Author',
          field: 'author',
        },
        {
          label: 'Year',
          field: 'year',
        },
        {
          label: 'Journal',
          field: 'journal',
        },
        {
          label: 'Volume',
          field: 'volume',
        },
        {
          label: 'Issue',
          field: 'issue',
        },
        {
          label: 'DOI',
          field: 'doi',
        },
        {
          label: 'PMID',
          field: 'accession',
        },
      ],
      rows_pub: [],
    }
  },
  methods:{
    to_sub_or_sup(text) {
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      return text1.replace(reg2, f)
    },
    Y_to_psi(text) {
      return text.replaceAll("Y", "Ψ")
    },
    async get_related_modification() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-related-modification/' + this.$route.query.name
      })
      res = res.data
      console.log(res)
      let rows_mod = []
      for (let i = 0; i < res.length; i++) {
        rows_mod[i] = {
          id: i + 1,
          name: res[i].mod_name,
          name_html:this.Y_to_psi(this.to_sub_or_sup(res[i].mod_name)),
          category: res[i].mod_category,
          position: res[i].position,
        }
      }
      this.$data.rows_mod = rows_mod
    },
    async get_related_publication() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-related-publication/' + this.$route.query.name
      })
      res = res.data
      console.log(res)
      let rows_pub = []
      for (let i = 0; i < res.length; i++) {
        rows_pub[i] = {
          title:res[i].Title,
          author:res[i].Author,
          year:res[i].Year,
          journal:res[i].journal,
          volume:res[i].Volume,
          issue:res[i].Issue,
          doi:res[i].DOI,
          accession:res[i].accession,
        }
      }
      this.$data.rows_pub = rows_pub
    },
    on_row_click_modification(params){
      console.log(params)
      this.$router.push({path:"modification-view",query:{name:params.row.name}})
    },
    on_row_click_pub(params){
      let url="https://pubmed.ncbi.nlm.nih.gov/"+params.row.accession
      window.open(url, "_blank");
    }
  },
  mounted() {
    this.get_related_modification()
    this.get_related_publication()
  }
}
</script>

<style scoped>
.el-icon-my-export-structure {
  background: url('../assets/icon/structure.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-enzyme {
  background: url('../assets/icon/enzyme.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-summary {
  background: url('../assets/icon/summary.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-pencil {
  background: url('../assets/icon/pencil.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-chart {
  background: url('../assets/icon/chart.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-publication {
  background: url('../assets/icon/journal.svg') center no-repeat;
  background-size: cover;
}
/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>