<template>
  <div>
    <el-row>
      <el-col>
        <h1 style="color:#182978">Tutorial</h1>
      </el-col>
    </el-row>
<el-row>
  <el-tabs  style="height:auto;">
    <el-tab-pane>
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Dataset</span>
      </div>
      <p style="font-size: 20px">The <b>"Dataset"</b> provides detailed information on all sequencing datasets used in tModeBase, including four sequencing methods, multiple tissues or cells in two species.
        Users can browse specific information about each dataset, including the <font style="color: #FF6A3D;">species of the sample</font>, the <font style="color: #FF6A3D;">source of the sample</font>, the <font style="color: #FF6A3D;">sequencing method</font>, <font style="color: #FF6A3D;">whether it was specially processed</font>, and the specific <font style="color: #FF6A3D;">GEO accession number</font> in the summary table.</p>
      <el-image :src="dataset1" fit="contain" style="width: 100%"></el-image>
      <p style="font-size: 20px">
        The <b>"Dataset"</b> provides a quick filter that allows the user to choose to view datasets for a specific <font style="color: #FF6A3D;">species</font> or <font style="color: #FF6A3D;">sample</font>; or for a particular <font style="color: #FF6A3D;">sequencing method</font> and its <font style="color: #FF6A3D;">treatment</font>. Users can click on the dataset of interest to go to the <font style="color: #FF6A3D;">Dataset View</font> page and browse the details.
      </p>
      <p style="font-size: 20px">
        The <b>"Dataset View"</b> provides detailed information about the dataset. In addition to the summary information above, users can also click on <b>"Publication Table"</b> to view the relevant publications of the dataset. In addition, users can browse the <font style="color: #FF6A3D;">abundance of tRNAs</font> detected in the dataset, the <font style="color: #FF6A3D;">mismatches</font> of tRNA loci and the mismatches in <font style="color: #FF6A3D;">different samples</font>.
      </p>
      <p style="font-size: 20px">
        At the bottom of the page, the <font style="color: #FF6A3D;">sequence</font> information of each tRNA and its <font style="color: #FF6A3D;">modification</font> and <font style="color: #FF6A3D;">mismatch bases</font> for each locus are displayed, users can click the tRNA of interest to enter the <b>"tRNA View"</b> page to browse the specific information.
      </p>
      <el-image :src="dataset2" fit="contain" style="width: 100%"></el-image>
    </el-tab-pane>
    <el-tab-pane>
      <div slot="label">
        <span style="color:#182978;font-size: 24px">tRNA</span>
      </div>
      <p style="font-size: 20px">
        The  <b>"tRNA"</b> provides a list of cytoplasmic and mitochondrial tRNA, a table containing tRNA sequences with modification, and a figure comparing the expression levels of tRNA in normal and tumor cells. In the figure, users can freely choose the tRNA they are interested in and whether normalizing tRNA expression to enzyme expression.
      </p>
      <el-image :src="tRNA1" fit="contain" style="width: 100%"></el-image>
      <el-image :src="tRNA2" fit="contain" style="width: 100%"></el-image>
      <el-image :src="tRNA3" fit="contain" style="width: 100%"></el-image>
      <el-image :src="tRNA4" fit="contain" style="width: 100%"></el-image>
    </el-tab-pane>
    <el-tab-pane>
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Modification</span>
      </div>
      <p style="font-size: 20px">
        The <b>"Modification"</b> builds up a list of several types of tRNA modifications and provides a quick filter based on the reference, category of the modification, and the site of modification.
      </p>
      <el-image :src="mod1" fit="contain" style="width: 100%"></el-image>
      <p style="font-size: 20px">
        By clicking one of the modifications, summary information about the modification, its related enzyme, and the known modified tRNAs will be introduced. The information of the related enzyme includes its name, category, type, and position. The related tRNA’s name, species that express them, sequence, and modification site are introduced.      </p>
      <el-image :src="mod2" fit="contain"  style="width: 100%"></el-image>

    </el-tab-pane>
    <el-tab-pane >
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Enzyme</span>
        </div>
        <p style="font-size: 20px">
          The <b>"Enzyme"</b> lists the related enzymes of modifications.

          The list includes the <font style="color: #FF6A3D;">abbreviation name, full name, category, and type of enzymes</font>. Enzymes can be filtered by selecting category, type, or entering keywords.
        </p>
      <el-image :src="enz1" fit="contain"  style="width: 100%"></el-image>
      <p style="font-size: 20px">
        By clicking one of the enzymes on the list, its detailed information, related modification, and the expression in cancer will be provided.
        The related modification includes the name, category, and position of the modification. The expression levels of the enzyme in different cancers are evaluated as FPKM.
        The enzyme expression levels in normal cells and tumor cells of various cancers are visualized as a bar plot.
      </p>
      <el-image :src="enz2" fit="contain"  style="width: 100%"></el-image>
      <el-image :src="enz3" fit="contain"  style="width: 100%"></el-image>
    </el-tab-pane>
    <el-tab-pane >
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Mod2Disease</span>
      </div>
      <p style="font-size: 20px">
        The  <b>"Mod2Disease"</b> lists 30 different diseases related to RNA modifications. These diseases are divided into 4 categories: mitochondrial diseases, neurological diseases, cancers, and diabetes. For the Modification/ Modification Enzyme column, we annotated whether the phenotype is caused by the influence of tRNA modification enzymes or the modification themselves in detail and briefly summarized the corresponding pathogenic mechanisms.      </p>
      <el-image :src="mod2disease" fit="contain" style="width: 100%"></el-image>
    </el-tab-pane>
    <el-tab-pane >
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Mod2tsRNA</span>
      </div>
      <p style="font-size: 20px">
        The <b>"Mod2tsRNA"</b> includes two tables. The first one, Modification Position & tsRNA Cleavage Site, provides a <font style="color: #FF6A3D;">list of tsRNAs with identical tsRNA cleavage sites and tRNA modification sites</font>. The second table, Modification & tsRNA with References, <font style="color: #FF6A3D;">introduces 11 modifications with detailed mechanisms and references</font>.
        The table, Modification Position & tsRNA Cleavage Site, includes the name, modification, position, tRF, type, tsRNA ID, the start position, the end position, and the sequence of tsRNA modifications. A specific modification can be found by searching the keywords on the list. When a user clicks one of the tsRNA IDs, the website will link to another database, <b>tsRFun</b>, which provides detailed information of this tsRNA.
      </p>
      <el-image :src="mod2ts" fit="contain" style="height: 1000px;width: 1000px"></el-image>
    </el-tab-pane>
    <el-tab-pane >
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Mod2Var</span>
      </div>
      <p style="font-size: 20px">
        The <b>"Mod2Var"</b> lists 654 modifications on single nucleotide polymorphisms (SNPs).
        The table includes the <font style="color: #FF6A3D;">tRNA, tRNA variants, modification, chromosome, position, dbSNP ID, variation type, and alleles</font>. A specific modification can be filtered by searching the keywords on either of the rows. When a user clicks one of the dbSNP IDs, the website will link to the reference SNP report of NIH, National Library of Medicine, which provides detailed information of this SNP.
      </p>
      <el-image :src="mod2var" fit="contain" style="width: 100%"></el-image>
    </el-tab-pane>
    <el-tab-pane >
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Mod2Campare</span>
      </div>
      <p style="font-size: 20px">
        In <b>"Mod2Compare"</b>, users can select any two datasets for comparison to obtain an interactive Venn diagram, which can be illustrated as intersection, union, a-b, and b-a. The "a-b" and "b-a" represents the data of one dataset minus the intersection data. In addition, Mod2Compare provides a downloadable table showing the misincorporated signal values detected in that part of the dataset.      <br/>
      <p style="font-size: 20px">In addition, Mod2Compare provides an upload function, that users can not only compare any two datasets contained in tModBase but can also upload a new dataset to compare it with the intersection or difference between the initially selected two datasets.</p>
      <el-image :src="Mod3" fit="contain" style="width: 1000px"></el-image>
      <el-image :src="Mod4" fit="contain" style="width: 1000px"></el-image>

    </el-tab-pane>
    <el-tab-pane >
      <div slot="label">
        <span style="color:#182978;font-size: 24px">Position</span>
      </div>
      <p style="font-size: 20px">
        The <b>"Position"</b> shows a table providing a list of possible tRNA modification sites of three speices, including Homo sapien, Mus musculus, and Saccharomyces cerevisiae. The table  includes the names, supporting evidence, and supporting evidence datasets of possible tRNA modification sites.
        We have standardized the naming rule for each possible tRNA modification site included in tModBase, and the rule is as follows: species abbreviation + tRNA name + corresponding position of the modified base in the standard tRNA structure + modification type. For example: has_tRNA-Leu-CAA-4_37_m1G, where "has" is the abbreviation for Homo sapiens, and each species is abbreviated with three letters, downloaded from miRbase. "tRNA-Leu-CAA-4" is the nomenclature of tRNA molecules in GtRNAdb, 37 is the 37th position in the tRNA sequence, and "m1G" is the known modification type of the site, or the site is labeled as NA (not available) if the base at the site has not been previously identified as containing a modification.
        The Supporting Evidence column illustrates whether the modification sites were identified by other datasets. The more datasets identified the same modification site, the stronger the evidence is. Although the fewer datasets support, the weaker the evidence, this may also indicate the possibility of a new modification site. The Supporting Evidence Datasets column shows the number of datasets that provides supporting evidence.
      </p>
      <br/>
      <el-image :src="Pos" fit="contain" style="width: 100%"></el-image>
    </el-tab-pane>
  </el-tabs>
</el-row>

  </div>
</template>

<script>
import dataset1 from "../assets/img/dataset1.png"
import dataset2 from "../assets/img/dataset2.png"
import tRNA1 from "../assets/img/tRNA1.png"
import tRNA2 from "../assets/img/tRNA2.png"
import tRNA3 from "../assets/img/tRNA3.png"
import tRNA4 from "../assets/img/tRNA4.png"
import mod1 from "../assets/img/Mod1.jpg"
import mod2 from "../assets/img/Mod2.jpg"
import enz1 from "../assets/img/enz1.png"
import enz2 from "../assets/img/enz2.png"
import enz3 from "../assets/img/enz3.png"
import mod2disease from "../assets/img/mod2disease.jpg"
import mod2ts from "../assets/img/mod2ts.png"
import mod2var from "../assets/img/mod2var.png"
import Mod1 from "../assets/img/Mod1.jpg"
import Mod2 from "../assets/img/Mod2.jpg"
import Mod3 from "../assets/img/Mod3.jpg"
import Mod4 from "../assets/img/Mod4.jpg"
import Pos from "../assets/img/Pos.jpg"
export default {
  data(){
    return{
      dataset1:dataset1,
      dataset2:dataset2,
      tRNA1:tRNA1,
      tRNA2:tRNA2,
      tRNA3:tRNA3,
      tRNA4:tRNA4,
      mod1:mod1,
      mod2:mod2,
      enz1:enz1,
      enz2:enz2,
      enz3:enz3,
      mod2disease:mod2disease,
      mod2ts:mod2ts,
      mod2var:mod2var,
      Mod1:Mod1,
      Mod2:Mod2,
      Mod3:Mod3,
      Mod4:Mod4,
      Pos:Pos
    }
  }
}
</script>

<style scoped>

</style>