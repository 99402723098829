<template>
  <div >
    {{this.$route.query.species}}
    <el-popover
        placement="right"
        width="1000"
        trigger="hover">
      <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table condensed"
          @on-row-click="on_row_click"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.row.Dataset===dataset" style="text-decoration: underline;font-weight: bolder">{{props.row[props.column.field]}}</div>

        </template>
      </vue-good-table>
      <el-button slot="reference" style="font-family: 'Sofia RE',serif" type="primary">Select Other Samples</el-button>
    </el-popover>
    <div v-loading="Loading" id="echartsdemo" :style="{width: '1200px', height: '500px'}" ref="container"></div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
const arr=[];
for (let i = 0; i < 105; i++) {
  arr.push(i + 1);
}

export default {
  name: "SidebarChart",
  components: {},
  data() {
    return {
      Loading:true,
      species:'Homo sapiens',
      dataset:'hydro_HSA_HEK293T',
      columns:[

        {
          label: 'Dataset',
          field: 'Dataset',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Species',
          field: 'Species',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Sample',
          field: 'Sample',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Technology',
          field: 'Technology',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Treatment',
          field: 'Treatment',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'GSE',
          field: 'GSE',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'UnderConstruction',
          field: 'UnderConstruction',
          hidden: true,
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'TechnologyClass',
          field: 'TechnologyClass',
          hidden: true,
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this columnc
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        ],
      rows:[],
      options:{
        tooltip:{},
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        legend: {
        },
        xAxis: {
          data: arr
        },
        yAxis: [ {
          type: 'value',
          name: 'coverage',
          axisLine: {
            lineStyle: {
              color: '#5e859e',
              width: 10
            }
          }
        },{
          type: 'value',
          name: 'stop/mismatch rate',
          min: 0,
          max: 1,
          axisLine: {
            lineStyle: {
              color: '#5e859e',//纵坐标轴和字体颜色
              width: 2
            }
          }
        }],
        series:[]
      }
  }
  },
  mounted() {

    if (this.$route.query.dataset!=null){
      this.dataset=this.$route.query.dataset
    }

    this.echartsinstance = echarts.init(this.$refs.container)
    this.echartsinstance.setOption(this.options)
    this.get_trna_view_chart()
    console.log(this.$route.query)
    this.get_options()
  },
  props:['technology'],

  methods:{
    on_row_click(params){
      this.dataset=params.row.Dataset
      this.get_trna_view_chart()
    },
    async get_trna_view_chart() {
      let res = await axios({
        method: 'get',
         url: 'https://43.138.148.56:20004/get-trna-view-chart/'
             +this.$route.query.name.replace(/-/g,"_")
             +'/'+this.dataset.replaceAll("-","~")
      })
      res=res.data
      console.log(res)
      let mis_arr=eval(res[0].per_mismatch_ng)
      let t_arr = mis_arr[0].map(function (col, i) {
        return mis_arr.map(function (row) {
          return row[i];
        })
      })
      this.$data.options.series=[{name:"coverage",symbol: "none",type:"line",data:eval(res[0].cov_ng),itemStyle: {
          normal: {
            color: '#C0C4CC', //改变折线点的颜色
            lineStyle: {
              color: '#C0C4CC' //改变折线颜色
            }
          }
        }, areaStyle: {color:"#C0C4CC"},zlevel:0},
      {name:"stop rate",type:"line",symbol: "none",data:eval(res[0].stop_ng),itemStyle: {
          normal: {
            color: '#182978', //改变折线点的颜色
            lineStyle: {
              color: '#182978' //改变折线颜色
            }
          }
        },smooth:true,yAxisIndex : 1,zlevel:100},
        {name:"A mismatch %",type: "bar",stack:"base",emphasis: {
            focus: 'series'
          },data: t_arr[0],color:'#5DAA68',yAxisIndex : 1,zlevel:100},
        {name:"C mismatch %",type: "bar",stack:"base",     emphasis: {
            focus: 'series'
          },data: t_arr[1], color:'#6688CC',yAxisIndex : 1,zlevel:100},
        {name:"G mismatch %",type: "bar",stack:"base",emphasis: {
            focus: 'series'
          },data: t_arr[2],color:"#F4DB7D",yAxisIndex : 1,zlevel:100},
        {name:"T mismatch %",type: "bar",stack:"base",emphasis: {
            focus: 'series'
          },data: t_arr[3], color:'#FF6A3D',yAxisIndex : 1,zlevel:100}]
      let arr=[];
      for (let i = 0; i < eval(res[0].cov_ng).length; i++) {
        arr.push(i + 1);
      }
      this.$data.options.xAxis.data=arr
      this.Loading=false
    },
    async get_options() {

      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-entry'
      })
      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
          rows.push({
            Dataset:res[i].dataset,
            GSE: res[i].gse,
            Technology: res[i].technology,
            Treatment: res[i].treatment,
            Species: res[i].species,
            Sample: res[i].sample,
            UnderConstruction:res[i]["under_construction?"],
            TechnologyClass:res[i].technology_class,
          })

      }
      console.log(rows)

      let index = rows.findIndex( item => item.Dataset === this.$route.query.dataset);
      let species= rows[index].Species
      let new_rows = rows.filter(item=>(item.Species === species & item.UnderConstruction !== 1))

      this.$data.rows = new_rows
    }
  },
  watch:{
    'options.series'(){
      this.echartsinstance.setOption(this.options)
    },
    'sample.selectedSample'(){
      this.get_trna_view_chart()
      console.log("hi")
      this.echartsinstance.setOption(this.options)
    },
    'treatment.selectedTreatment'(){
      this.get_trna_view_chart()
      this.echartsinstance.setOption(this.options)
    },
    deep: true
  }
}
</script>