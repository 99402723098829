import { render, staticRenderFns } from "./MyAbout.vue?vue&type=template&id=241b5288&scoped=true&"
import script from "./MyAbout.vue?vue&type=script&lang=js&"
export * from "./MyAbout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241b5288",
  null
  
)

export default component.exports