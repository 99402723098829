<template>
  <div>
    <el-row>
      <el-col>
        <h1  style="color:#182978;font-size: 64px">Welcome to tModBase!</h1>
      </el-col>
    </el-row>
    <el-row >
      <el-card shadow="never">
          <span style="font-size: 16px">
tRNA molecules contain abundant and dense modifications that affect tRNA structure, precise codon recognition, and tRNA fragment formation. tRNA modifications and related enzymes undergo dynamic changes under different conditions and are associated with a range of physiological and pathological processes. However, there is a lack of resource that can mine and analyze these dynamic tRNA modifications. In this study, we established tModBase, deciphering the landscape of tRNA modifications and their dynamic changes from epitranscriptome data. tModBase accurately annotates the positions of 27 types of chemical modifications and corresponding modifying enzymes on 523 tRNA isodecoders in human and mouse. In addition, we analyzed 25 datasets generated by second- and third-generation sequencing technologies specifically designed to detect and quantify tRNA molecules and their modifications, and illustrated the sequencing signal characteristics of different modification sites. Based on this, we systematically demonstrate the distribution of tRNA modification patterns in different tissues and summarize the characteristics of tRNA-associated human diseases. By integrating multi-omics sequencing data from 33 cancers, we developed novel tools to analyze the relationship between tRNA modifications and modification enzymes, the expression of 1442 tRNA-derived small RNAs (tsRNAs), and 654 DNA variation sites. Our database will provide new insights into the mechanisms of dynamic changes in tRNA modifications and the biological pathways they participate in for a comprehensive analysis.</span>
      </el-card>
    </el-row>
    <el-row>
      <el-card shadow="never">
          <span style="font-size: 24px">
            How to cite:
          </span>
        <br/>
        <span @click="toURL('https://academic.oup.com/nar/article/51/D1/D315/6835363')" style="font-size: 16px;cursor: pointer;color:#6688CC">
          tModBase: deciphering the landscape of tRNA modifications and their dynamic changes from epitranscriptome data
          <i class="el-icon-my-export-new-window"></i>
        </span>
        <span  style="font-size: 16px">
          Hao-Tian Lei, Zhang-Hao Wang, Bin Li, Yang Sun, Shi-Qiang Mei, Jian-Hua Yang, Liang-Hu Qu, Ling-Ling Zheng
        </span>
      </el-card>
    </el-row>
    <el-row hidden>
      <el-image :src="main"></el-image>
    </el-row>
    <el-row >
      <el-card header="" shadow="never">
        <div slot="header">
          <i class="el-icon-my-export-filter"></i>
          <span style="color:#182978">Human tRNA Modification Map</span>
        </div>
        <concept-map></concept-map>
      </el-card>


    </el-row>
  </div>

</template>
<script>
import main from "../assets/img/main.jpg"
import ConceptMap from "@/components/ConceptMap";

export default {
  components:{ConceptMap},
  data(){
    return{
      main:main,
    }
  },
  methods:{
    toURL(url) {
      window.open(url, "_blank");
    },
  }
}
</script>
<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
#pic_mod{
  text-align: center;
}
.el-icon-my-export-new-window {
  background: url('../assets/icon/new_window.svg') center no-repeat;
  background-size: cover;
}
</style>