<template>
  <div>
    <div>
      <el-row>
        <el-col>
          <h1 style="color:#182978">Datasets</h1>
        </el-col>
      </el-row>
      <el-row>
        <el-card header="" shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-filter"></i>
            <span style="color:#182978">Quick Filter</span>
          </div>
          <el-form :inline="true" label-position="top" class="demo-form-inline" size="medium">
            <el-form-item label="Species/Sample: ">
              <el-cascader
                  v-model="selectedSample"
                  :options="optionSample"
                  :props="{ checkStrictly: true }"
                  clearable
                  placeholder="">
              </el-cascader>
            </el-form-item>
            <el-form-item label="Technology: ">
              <el-cascader
                  v-model="selectedTechnology"
                  :options="optionTechnology"
                  :props="{ checkStrictly: true }"
                  clearable
                  placeholder=""
              >
              </el-cascader>
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
    </div>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows,'tmodbase_dataset.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows,'tmodbase_dataset.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row>
      <el-col v-loading="Loading">
        <vue-good-table
            :columns="columns"
            :rows="rows"
            @on-row-click="onRowClick"
            ref="myTable"
            id="big-table"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'GSM'">
              <div v-for="(item,index) in props.row.GSM.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
            <div v-else-if="props.column.field === 'SRR'">
              <div v-for="(item,index) in props.row.SRR.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
            <div v-else-if="props.column.field === 'Treatment'">
              <div v-for="(item,index) in props.row.Treatment.split(';')" :key="index" style="margin-bottom: 10px">
              <span v-if="item==='untreated'"
                    style="color:#FFFFFF;background-color: #6688CC;border-radius: 5px;border: 2px solid #6688cc">
                {{ item }}
                <br/>
              </span>

                <span v-else-if="item==='DM treated'"
                      style="color:#FFFFFF;background-color: #FF6A3D ;border-radius: 5px;border: 2px solid #FF6A3D ">
                {{ item }}
                 <br/>
              </span>
                <span v-else
                      style="color:#FFFFFF;background-color: #5DAA68 ;border-radius: 5px;border: 2px solid #5DAA68;white-space: nowrap">
                {{ item }}
              </span>
              </div>
            </div>
            <div v-else-if="props.column.field === 'Sample'">
              <div v-for="(item,index) in props.row.Sample.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
          </template>
        </vue-good-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import json2csv from "json2csv";

export default {
  data() {
    return {
      Loading: true, //加载动画
      selectedTechnology: [],
      selectedSample: [],
      optionTechnology: [
          {
        value: 'Next Generation Sequencing',
        label: 'Next Generation Sequencing',
        children: [{
          value: 'mim-tRNA-seq',
          label: 'mim-tRNA-seq',
        }, {
          value: 'QuantM-tRNA-seq',
          label: 'QuantM-tRNA-seq',
        }, {
          value: 'hydro-tRNA-seq',
          label: 'hydro-tRNA-seq',
        },
          {
            value: 'DM-tRNA-seq',
            label: 'DM-tRNA-seq',
          },
          {
            value: 'ARM-tRNA-seq',
            label: 'ARM-tRNA-seq',
          },
          {
            value: 'YAMAT-seq',
            label: 'YAMAT-seq',
          },
          {
            value: 'AQRNA-seq',
            label: 'AQRNA-seq',
          },
        ]
      },
        {
          value: 'Sequencing for Specific Modifications',
          label: 'Sequencing for Specific Modifications',
          children: [{
            value: 'Pseudo-seq',
            label: 'Pseudo-seq',
          },
          //   {
          //   value: 'BS-seq',
          //   label: 'BS-seq',
          // },
            {
              value: 'm1A-seq',
              label: 'm1A-seq',
            },

          ]
        },
        {
          value: 'Third Generation Sequencing',
          label: 'Third Generation Sequencing',
          children: [{
            value: 'nanopore',
            label: 'nanopore',
          }
          ]
        },

      ],
      optionSample: [
          {
        value: 'Homo sapiens',
        label: 'Homo sapiens',
        children: [
          {value: 'kucg hiPSC', label: 'kucg hiPSC'},
          {value: 'K562', label: 'K562'},
          {value: 'HEK293T', label: 'HEK293T'},
          {value: 'GM05372', label: 'GM05372'},
          {value: 'BT-20', label: 'BT-20'},
          {value: 'SK-BR-3', label: 'SK-BR-3'},
          {value: 'MCF-7', label: 'MCF-7'},
          {value: 'NHEK', label: 'NHEK'},
          {value: 'FaDu cell line', label: 'FaDu cell line'},
        ]
      },
        {
          value: 'Mus musculus',
          label: 'Mus musculus',
          children: [
            {value: 'heart', label: 'heart'},
            {value: 'liver', label: 'liver'},
            {value: 'cerebellum', label: 'cerebellum'},
            {value: 'cortex', label: 'cortex'},
            {value: 'medulla oblongata',label: 'medulla oblongata'},
            {value: 'spinal cord', label: 'spinal cord'},
            {value: 'tibialis', label: 'tibialis'},
            {value: 'brain',label: 'brain'},
            {value: 'intestine', label: 'intestine'},
            {value: 'kidney', label: 'kidney'},
            {value: 'CD4 T-cells, B6', label: 'CD4 T-cells, B6'},
            {value: 'fibroblasts, MC57G', label: 'fibroblasts, MC57G'}
          ]
        },
        {
          value: 'Saccharomyces cerevisiae',
          label: 'Saccharomyces cerevisiae',
          children: [
            {value: 'BY4741', label: 'BY4741'},
            {value: 'YWG11', label: 'YWG11'},
          ]
        },
        {
          value: 'Escherichia coli',
          label: 'Escherichia coli',
          children: [
            {value: 'MRE600', label: 'MRE600'},
          ]
        },
        // {
        //   value: 'Schizosaccharomyces pombe',
        //   label: 'Schizosaccharomyces pombe',
        //   children: [
        //     {value: 'ED668 h+, ade6-M216 ura4-D18 leu1-32', label: 'ED668 h+, ade6-M216 ura4-D18 leu1-32'},
        //   ]
        // },
        {
          value: 'Drosophila melanogaster',
          label: 'Drosophila melanogaster',
          children: [
            {value: 'BG3-c2', label: 'BG3-c2'},
          ]
        },
        // {
        //   value: 'Solanum tuberosum',
        //   label: 'Solanum tuberosum',
        //   children: [
        //     {value: 'Leaf', label: 'Leaf'},
        //   ]
        // },
        // {
        //   value: 'Oryza sativa',
        //   label: 'Oryza sativa',
        //   children: [
        //     {value: 'Leaf', label: 'Leaf'},
        //   ]
        // },
        // {
        //   value: 'Medicago truncatula',
        //   label: 'Medicago truncatula',
        //   children: [
        //     {value: 'Leaf', label: 'Leaf'},
        //   ]
        // },
        {
          value: 'Arabidopsis thaliana',
          label: 'Arabidopsis thaliana',
          children: [
            {value: 'Leaf', label: 'Leaf'},
          ]
        },
        {
          value: 'Mycobacterium tuberculosis variant bovis BCG',
          label: 'Mycobacterium tuberculosis variant bovis BCG',
          children: [
            {value: 'Pasteur 1173P2', label: 'Pasteur 1173P2'},
          ]
        },

      ],
      columns: [
        {
          label: 'Dataset',
          field: 'Dataset',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Species',
          field: 'Species',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Sample',
          field: 'Sample',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Technology',
          field: 'Technology',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Treatment',
          field: 'Treatment',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'GSE',
          field: 'GSE',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'GSM',
          field: 'GSM',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'UnderConstruction',
          field: 'UnderConstruction',
          hidden: true,
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'TechnologyClass',
          field: 'TechnologyClass',
          hidden: true,
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },

        },
        {
          label: 'SRR',
          field: 'SRR',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          }
        }
      ],
      rows: [],
      isClickEvent:true,
    };
  },
  methods: {
    async getDataset() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-entry'
      })
      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          Dataset:res[i].dataset,
          GSE: res[i].gse,
          GSM: res[i].gsm,
          Technology: res[i].technology,
          Treatment: res[i].treatment,
          Species: res[i].species,
          Sample: res[i].sample,
          UnderConstruction:res[i]["under_construction?"],
          TechnologyClass:res[i].technology_class,
          SRR:res[i].SRR
        }
      }
      // 对象数组排序
      rows.sort((a, b) => {
        return a.Dataset > b.Dataset ? 1 : -1
      })
      rows.sort((a, b) => {
        return a.UnderConstruction > b.UnderConstruction ? 1 : -1
      })
      this.$data.rows = rows
      this.Loading = false
    },
    onRowClick(params) {
      if (this.isClickEvent){
        if (params.row.UnderConstruction===1){
          this.$message({
            showClose: true,
            message: 'Sorry, this dataset will be processed before November 13',
            type: 'warning'
          });
        }else {
          this.$router.push({
            path: "dataset-view",
            query: {
              dataset: params.row.Dataset,
            }
          })
        }


      }
    },
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    disableClickEvent(){
      this.isClickEvent=false
    },
    ableClickEvent(){
      this.isClickEvent=true
    }
  },
  mounted() {
    this.getDataset()

  },
  computed:{
    filteredRows:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.myTable.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }
  },

  watch: {
    selectedSample(val) {
      // column[0]   Dataset
      // column[1]   Species
      // column[2]   Sample
      // column[3]   Technology
      // column[4]   Treatment
      // ...
      if (val[0] === undefined) {
        this.columns[1].filterOptions.filterValue = ''
      } else {
        this.columns[1].filterOptions.filterValue = val[0]
      }
      if (val[1] === undefined) {
        this.columns[2].filterOptions.filterValue = ''
      } else {
        this.columns[2].filterOptions.filterValue = val[1]
      }
    },
    selectedTechnology(val) {
      if (val[0] === undefined) {
        this.columns[8].filterOptions.filterValue = ''
      } else {
        this.columns[8].filterOptions.filterValue = val[0]
      }
      if (val[1] === undefined) {
        this.columns[3].filterOptions.filterValue = ''
      } else {
        this.columns[3].filterOptions.filterValue = val[1]
      }
    },
  }
};
</script>

<style>

</style>
