<template>
  <body>
  <div id="app">
    <div v-if="shouldShowRouterView" class="structure_web">
      <router-view ></router-view>
    </div>
    <div v-else class="default_web">
      <nav-menu></nav-menu>
      <el-footer style="text-align: center">
        © Copyright 2020-2021 Qu Lab. School of Life Science, Sun Yat-sen University, China.
      </el-footer>
      <el-footer style="text-align: center">
        <div @click="toRescue()">粤ICP备2022117507号</div>
      </el-footer>
    </div>
  </div>
  </body>

</template>

<script>
import NavMenu from "@/components/NavMenu";
import axios from "axios";
import json2csv from "json2csv";

export default {
  name: 'app',
  components: {
    NavMenu
  },
  methods:{
    toRescue() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
  },
  computed: {
    shouldShowRouterView() {
      return this.$route.path === '/structure'
    }
  },
}


</script>

<style>
/*背景色、字体*/

.default_web {
  background: #EBEEF5;
  font-family:"Sofia RE",sans-serif;
}
.structure_web {
  background: #ffffff;
  font-family:"Sofia RE",sans-serif;
}

/*各种图标*/
.el-icon-my-export-icon {
  background: url('assets/logo.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-pointer-white {
  background: url('assets/icon/pointer-white.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-writer {
  background: url('assets/icon/pencil.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-eraser {
  background: url('assets/icon/eraser.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-reader {
  background: url('assets/icon/search.svg') center no-repeat;
  background-size: cover;
}


.el-icon-my-export-pointer-grey {
  background: url('assets/icon/pointer-grey.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-download-white {
  background: url('assets/icon/download-white.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-filtered-download-white {
  background: url('assets/icon/filtered-download-white.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-human {
  background: url('assets/icon/human.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-menu {
  background: url('assets/icon/menu-white.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-mouse {
  background: url('assets/icon/mouse.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-delete-white {
  background: url('assets/icon/delete_white.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-filter {
  background: url('assets/icon/filter.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-A {
  background: url('assets/icon/A.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-B {
  background: url('assets/icon/B.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-full {
  background: url('assets/icon/full.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-intersection {
  background: url('assets/icon/intersection.svg') center no-repeat;
  background-size: cover;
}

/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}

[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}

.el-input__inner {
  font-family: "Sofia RE", sans-serif;
}

.vgt-input, .vgt-select {
  font-family: "Sofia RE", sans-serif;
}

.el-input__suffix {
  font-size: 20px;
}

</style>

