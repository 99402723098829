<template>
  <div>
    <div>
      <el-row>
        <el-col>
          <h1 style="color:#182978">Enzymes</h1>
        </el-col>
      </el-row>
      <el-row>
        <el-card shadow="never" header="">
          <div slot="header">
            <i class="el-icon-my-export-filter"></i>
            <span style="color:#182978">Quick Filter</span>
          </div>
          <el-form :inline="true" class="demo-form-inline" size="medium">
            <el-form-item label="Category: ">
              <el-select v-model="category.checked" multiple placeholder="">
                <el-option
                    v-for="cat in category.category" :label="cat" :key="cat"
                    :value="cat">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Type: ">
              <el-select v-model="type.checked" multiple placeholder="">
                <el-option
                    v-for="type in type.type" :label="type" :key="type"
                    :value="type">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
    </div>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows,'tmodbase_enzyme.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows,'tmodbase_enzyme.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row v-loading="Loading">
      <el-col>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            @on-row-click="on_row_click"
            :pagination-options="{
        enabled: true,
        perPage: 10
        }"
        ref="table">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'category'">
              <div v-for="(item,index) in props.row.category.split(';')" :key="index">
                {{item}}
                <br/>
              </div>
            </div>
            <div v-else-if="props.column.field === 'synonym'">
              <div v-for="(item,index) in props.row.synonym.split(';')" :key="index">
                {{item}}
                <br/>
              </div>
            </div>
            <div v-else-if="props.column.field === 'type'">
              <div v-for="(item,index) in props.row.type.split(';')" :key="index">
                {{item}}
                <br/>
              </div>
            </div>
          </template>
        </vue-good-table>

      </el-col>
    </el-row>
    <enzyme-de></enzyme-de>
  </div>

</template>

<script>
import axios from "axios";
import json2csv from "json2csv";

const categoryOptions = ['Methylation', 'Deaminase', 'Pseudouridylase'];
const typeOptions = ['writer','eraser'];
import EnzymeDe from "@/components/EnzymeDe";
export default {
  components: {EnzymeDe},
  data() {
    return {
      Loading:true,
      category: {
        checkAll: false,
        checked: [],
        category: categoryOptions,
        isIndeterminate: true,
      },
      type: {
        checkAll: false,
        checked: [],
        type: typeOptions,
        isIndeterminate: true,
      },
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },
        {
          label: 'Full Name',
          field: 'full_name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas ', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
          },
        },
      ],
      rows: [],
    }
  },
  computed:{
    filteredRows:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.table.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }},
  methods: {
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    async get_enzyme() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-enzyme'
      })
      res = res.data
      console.log(res[0])
      let rows = []
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          name:res[i].enz_name,
          full_name:res[i].full_name,
          category:res[i].category,
          type:res[i].type,
        }

        this.$data.rows = rows
        this.Loading=false

      }
    },
    on_row_click(params){
      console.log(params)
      this.$router.push({path:"enzyme-view",query:{name:params.row.name}})
    },
    columnFilterFn(data, filterString) {
      // 筛选函数
      // 筛选条件用逗号分割
      // 数据用分号分割
      let x = filterString.trim().split(",")
      let y = data.trim().split(";")
      // 筛去空字符串
      const filtered_x = x.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });
      const filtered_y = y.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });

      let res2 = filtered_x.map((item_x) => {
        let res1 = filtered_y.map((item_y) => {
          return item_y.toLowerCase().includes(item_x.toLowerCase())
        })
        return res1
      })
      return res2.flat().indexOf(true) !== -1
    },
  },
  mounted() {
    this.get_enzyme()
  },
  watch:{
    'type.checked'(val){
      if (val===undefined){
        this.columns[3].filterOptions.filterValue=''
      }else{
        this.columns[3].filterOptions.filterValue=val.toString()
      }
    },
    'category.checked'(val){
      if (val===undefined){
        this.columns[2].filterOptions.filterValue=''
      }else{
        this.columns[2].filterOptions.filterValue=val.toString()
      }
    }
    }
}
</script>

<style scoped>
.el-form--inline .el-form-item {
  margin-right: 40px;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-icon-my-export-filter {
  background: url('../assets/icon/filter.svg') center no-repeat;
  background-size: cover;
}

/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}

</style>

  <style>
  .el-checkbox-button.is-checked.A .el-checkbox-button__inner {
    background-color: #5daf34;
  }

.el-checkbox-button.is-checked.C .el-checkbox-button__inner {
  background-color: #6688CC;
}

.el-checkbox-button.is-checked.G .el-checkbox-button__inner {
  background-color: #F4DB7D;
}

.el-checkbox-button.is-checked.U .el-checkbox-button__inner {
  background-color: #FF6A3D;
}
</style>