import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/components/HomePage'
import TrnaView from "@/components/Trna/TrnaView"
import DatasetView from "@/components/Dataset/DatasetView";
import ModificationEntry from "@/components/ModificationEntry";
import DatasetEntry from "@/components/Dataset/DatasetEntry";
import ModificationView from "@/components/ModificationView";
import EnzymeView from "@/components/EnzymeView";
import EnzymeEntry from "@/components/EnzymeEntry";
import TrnaEntry from "@/components/Trna/TrnaEntry";
import Modification2SNP from "@/components/Modification2SNP";
import Modification2TS from "@/components/Modification2TS";
import Modification2Disease from "@/components/Modification2Disease";
import MyTutorial from "@/components/MyTutorial";
import MyDownload from "@/components/MyDownload";
import MyAbout from "@/components/MyAbout";
import TrnaCompare from "@/components/TrnaCompare";
import UnderConstruction from "@/components/UnderConstruction";
import PositionList from "@/components/PositionList";
import MyDynamics from "@/components/MyDynamics";
import GlobalSearch from "@/components/GlobalSearch";
import Structure from "@/components/TrnaStructure";
Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            component: HomePage,
        },
        {
            path: '/home',
            component: HomePage
        },
        {
            path: '/homepage',
            component: HomePage
        },
        {
            path: '/trna-view',
            component: TrnaView
        },
        {
            path:'/dataset-view',
            component:DatasetView
        },
        {
          path:'/modification',
          component: ModificationEntry
        },
        {
            path:'/dataset',
            component: DatasetEntry
        },
        {
            path:'/modification-view',
            component: ModificationView
        },
        {
            path:'/enzyme-view',
            component:EnzymeView
        },
        {
            path:'/enzyme',
            component:EnzymeEntry
        },
        {
            path:'/trna',
            component:TrnaEntry
        },
        {
          path:'/mod-to-var',
          component:  Modification2SNP
        },
        {
            path:'/mod-to-tsrna',
            component:  Modification2TS
        },
        {
            path:'/mod-to-disease',
            component:  Modification2Disease
        },
        {
            path:'/tutorial',
            component:  MyTutorial
        },
        {
            path:'/download',
            component:  MyDownload
        },
        {
            path:'/about',
            component:  MyAbout
        },
        {
            path:'/compare',
            component:  TrnaCompare
        },
        {
            path:'/under-construction',
            component:  UnderConstruction
        },
        {
            path:'/position',
            component:  PositionList
        },
        {
            path:'/dynamics',
            component:  MyDynamics
        },
        {
            path:'/test',
            component:  GlobalSearch
        },
        {
            path:'/structure',
            component:  Structure,
            props: route => ({
                seqChrom: route.query.seqChrom || '',
                structure: route.query.structure || '',
                color: route.query.color || ''
            })
        },

    ]
})
