<template>
<div>
  <div id='rna'></div>
</div>
</template>

<script>
import * as fornac from "@/fornac/fornac.js";

export default {
  name: "TrnaStructure",
  data() {
    return {
      options: {
        name: 'tRNA',
        // structure: '.(((((((..((((...........)))).(((((.......))))).(.....................)(((((.......))))))))))))....',
        // sequence: '-1,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,17a,18,19,20,20a,20b,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,e11,e12,e13,e14,e15,e16,e17,e1,e2,e3,e4,e5,e27,e26,e25,e24,e23,e22,e21,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76',
        structure:'',
        sequence:'',
        color: ''
      },
      screenHeight: null,
      screenWidth: null,
    }
  },
  methods: {
    drawPlot(id) {
      let container = new fornac.FornaContainer(("#"+id),
          {
            'animation': true
            , 'zoomable': true, 'editable': false
          });
      container.displayNumbering(false)
      container.addRNA(this.options.structure, this.options);
      container.setSize(this.screenWidth, this.screenHeight)
      container.addCustomColorsText(this.options.color)
    },
    processColor(inputColor) {
      const inputLines = inputColor.trim().split("\\n");
      let output = "";
      for (let i = 0; i < inputLines.length; i++) {
        if (inputLines[i] === "1") {
          output += `${i+1}:#FF0000 `;
        }
      }
      return output;
    },
    updateFromQueryParams() {
      const query = this.$route.query;
      this.options.sequence = query.seqChrom;
      this.options.structure = query.structure;
      this.options.color = this.processColor(query.color);
    },
    handleResize() {
      // Update the screen dimensions
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateFromQueryParams();
    this.drawPlot('rna')
  },
  created() {
    // Listen for window resize events
    window.addEventListener("resize", this.handleResize);
    // Set the initial screen dimensions
    this.handleResize();
  },
  destroyed() {
    // Remove the window resize event listener
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    '$route.query': 'updateFromQueryParams'
  }
}
</script>

<style scoped>
</style>
