<template>
  <div>
    <el-row>
      <el-col>
        <h1 style="color:#182978">Download</h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-card shadow="never">
          <el-form>
            <el-form-item label="Manually Colleted hg38 tRNA List:">
              <el-button style="font-family: 'Sofia RE',serif" @click="download('https://43.138.148.56:20004/get-all-hg38','hg38.csv')">Download</el-button>
            </el-form-item>
            <el-form-item label="Manually Colleted mm39 tRNA List:">
              <el-button style="font-family: 'Sofia RE',serif" @click="download('https://43.138.148.56:20004/get-all-mm39','mm39.csv')">Download</el-button>
            </el-form-item>
            <el-form-item label="Mismatch, Stop and Coverage Data in all Datasets:">
              <el-button style="font-family: 'Sofia RE',serif" @click="download('https://43.138.148.56:20004/get-dataset-views','all_entity.csv')">Download</el-button>
            </el-form-item>
            <el-form-item label="Mismatch, Stop and Coverage Data in Selected Dataset:">

              <vue-good-table
                  :columns="columns"
                  :rows="rows"
                  styleClass="vgt-table condensed"
              >
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field=='download'">
                    <el-button style="font-family: 'Sofia RE',serif" @click="download('https://43.138.148.56:20004/get-entity-by-dataset/'+props.row.gsm,'entity.csv')">Download</el-button>
                  </div>
                </template>
              </vue-good-table>
            </el-form-item>
          </el-form>

        </el-card>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import json2csv from 'json2csv'
import axios from "axios";
export default {
  name: 'App',
  data() {
    return {
      dataset:'GSM4202814;GSM4202815',
      columns:[{
        label: 'Species',
        field: 'species',
      },
        {
          label: 'Sample',
          field: 'sample',
        },
        {
          label: 'Technology',
          field: 'technology',
        },
        {
          label: 'Treatment',
          field: 'treatment',
        },
        {
          label:"Download",
          field: 'download',
        }],
      rows:[],
    }
  },
  components: {
  },
  methods: {
    on_row_click(params){
      this.dataset=params.row.gsm
    },
   async download(url='https://43.138.148.56:20004/get-all-hg38',file_name='hg38.csv'){
     let res = await axios({
       method: 'get',
       url:url
     })
     res = res.data
     //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(res);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, file_name);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    async get_testmod() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-entry'
      })
      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          id: i + 1,
          gse:res[i].gse,
          gsm:res[i].gsm,
          technology:res[i].technology,
          treatment:res[i].treatment,
          species:res[i].species,
          sample:res[i].sample,
        }
      }
      this.$data.rows = rows
    },
  },
  mounted() {
    this.get_testmod()
  }
}
</script>

