<template>
  <div>
    <el-row>
      <el-col >
        <h1 style="color:#182978">Modification to Variations</h1>
      </el-col>
    </el-row>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows,'tmodbase_variation.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows,'tmodbase_variation.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row v-loading="Loading">
      <el-col >
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
          enabled: true

          }"
            ref="table"
        >
          <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'snp'">
            <div style="cursor: pointer" @click="toSNP(props.row[props.column.field])">
              {{ props.row[props.column.field] }}
              <i class="el-icon-my-export-new-window"></i>
            </div>
          </div>
            <div v-else-if="props.column.field === 'type'">
             <span v-if="props.formattedRow.type==='snv'"
                   style="color:#FFFFFF;background-color: #5DAA68 ;border-radius: 5px;border: 5px solid #5DAA68 ">
                {{ props.formattedRow.type }}
              </span>
              <span v-else-if="props.formattedRow.type==='deletion'"
                    style="color:#FFFFFF;background-color: #6688CC ;border-radius: 5px;border: 5px solid #6688CC ">
                {{ props.formattedRow.type }}
              </span>
              <span v-else-if="props.formattedRow.type==='indel'"
                    style="color:#FFFFFF;background-color: #F4DB7D ;border-radius: 5px;border: 5px solid #F4DB7D ">
                {{ props.formattedRow.type }}
              </span>
              <span v-else-if="props.formattedRow.type==='insertion'"
                    style="color:#FFFFFF;background-color: #FF6A3D ;border-radius: 5px;border: 5px solid #FF6A3D ">
                {{ props.formattedRow.type }}
              </span>
              </div>
            <div v-html="props.row.modification_html" v-else-if="props.column.field === 'modification'">

            </div>
        </template>
        </vue-good-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import json2csv from "json2csv";

export default {
  name: 'my-component',
  data() {
    return {
      Loading:true,
      columns: [
        {
          label: 'tRNA',
          field: 'parent',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'tRNA Variants',
          field: 'child',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Modification',
          field: 'modification',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Chromosome',
          field: 'chromosome',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Position',
          field: 'position',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'dbSNP ID',
          field: 'snp',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Variation Type',
          field: 'type',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Alleles',
          field: 'alleles',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn:this.columnFilterFn,
            trigger: '',
          },
        },
      ],
      rows: [],
    };
  },

  computed:{
    filteredRows:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.table.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }
  },
  methods: {
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    toSNP(rs){
      let url="https://www.ncbi.nlm.nih.gov/snp/"+rs
      window.open(url, "_blank");
    },
    columnFilterFn(data, filterString) {
      // 筛选函数
      // 筛选条件用逗号分割
      // 数据用分号分割
      let x = filterString.toString().trim().split(",")
      let y = data.toString().trim().split(";")
      // 筛去空字符串
      const filtered_x = x.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });
      const filtered_y = y.filter((item) => {
        return item !== null && typeof item !== "undefined" && item !== "";
      });

      let res2=filtered_x.map((item_x) => {
        let res1=filtered_y.map((item_y)=>{
          return item_y.toLowerCase().includes(item_x.toLowerCase())
        })
        return res1
      })
      return res2.flat().indexOf(true)!==-1
    },
    async get_snp() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-snp'
      })

      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          id: i + 1,
          parent:res[i].parent,
          child:res[i].child,
          chromosome:res[i].chr.replace("chr",""),
          position:res[i].position,
          modification:res[i].modification,
          modification_html:this.modification2SupOrSub(res[i].modification),
          snp:res[i].rs,
          alleles:res[i].Allele,
          type:res[i].type,
        }
      }
      this.$data.rows = rows
      this.Loading=false
    },
    to_sub_or_sup(text) {
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      return text1.replace(reg2, f)
    },
    modification2SupOrSub(text){
      // 这个函数用来将文本的修饰名称格式化为有上下标的形式(html)
      // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      let text2 = text1.replace(reg2, f)
      let text3 = text2.replaceAll("Y", "Ψ")
      let text4 = text3.replaceAll("tau", "τ")
      return text4
      // modification2SupOrSub("m2G")
      // 'm<sup>2</sup>G'
      // modification2SupOrSub("m2,2tau")
      // 'm<sup>2</sup><sub>2</sub>τ'
    },
    clear_selected_technology(){
      this.selected_technology=[]
    },
    clear_selected_sample(){
      this.selected_sample=[]
    },
    on_row_click(params){
      console.log(params)
      this.$router.push({path:"dataset-view",query:{dataset:"mim-tRNA-seq-dataset1",name:params.row.gsm}})
    },
  },

  mounted() {
    this.get_snp()
  },
  watch: {
    'form.selected_species'(val){
      console.log(val)
      if (val===undefined){
        this.columns[0].filterOptions.filterValue=''
      }else {
        this.columns[0].filterOptions.filterValue=val
      }
    },
    'form.selected_tRNA'(val){
      if (val[0]===undefined){
        this.columns[0].filterOptions.filterValue=''
      }else {
        this.columns[0].filterOptions.filterValue=val[0]
      }
      if (val[1]===undefined){
        this.columns[1].filterOptions.filterValue=''
      }else {
        this.columns[1].filterOptions.filterValue=val[1]
      }
    },
    'form.selected_modification'(val){
      console.log(val)
      if (val===undefined){
        this.columns[2].filterOptions.filterValue=''
      }else {
        this.columns[2].filterOptions.filterValue=val
      }
    },
    'form.chr'(val){
      console.log(val)
      if (val===undefined){
        this.columns[3].filterOptions.filterValue=''
      }else {
        this.columns[3].filterOptions.filterValue=val
      }
    },
    start_end(val){
      console.log(val)
      if (val===undefined){
        this.columns[4].filterOptions.filterValue=''
      }else {
        this.columns[4].filterOptions.filterValue=val
      }
    },

    deep:true
  }
};
</script>

<style scoped>
.el-icon-my-export-new-window {
  background: url('../assets/icon/new_window.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-human {
  background: url('../assets/icon/human.svg') center no-repeat;
  background-size: cover;
}

.el-icon-my-export-mouse {
  background: url('../assets/icon/mouse.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-delete-white {
  background: url('../assets/icon/delete_white.svg') center no-repeat;
  background-size: cover;
}

/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>