<template>
  <div v-loading="Loading">
    <div >
      <el-row style="display:inline-block ">
        <el-radio-group v-model="checked_base" style="margin: 20px">
          <el-radio-button :class="base" v-for="base in bases" :label="base" :key="base">{{base}}</el-radio-button>
        </el-radio-group>
        <el-switch
            v-model="gap"
            active-text="Standard"
            inactive-text="No Gap">
        </el-switch>
      </el-row>
    </div>
    <div id="heatmap"  style="width: 1200px; height: 500px" ref="container1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import axios from "axios";
import arrow from "@/assets/icon/arrow.svg";
const arr=[];
for (let i = 0; i < 105; i++) {
  arr.push(i + 1);
}
const  known_modification_rate={
      'Homo sapiens':{
        'A':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.26 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.41 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.39 ,0.02 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'C':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.04 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.04 ,0.13 ,0.02 ,0.02 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.04 ,0.07 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.15 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.26 ,0.20 ,0.11 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'G':[0.04 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.11 ,0.00 ,0.00 ,0.17 ,0.46 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.28 ,0.04 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.13 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.17 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'T':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.04 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.15 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.24 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00]
      },
      'Mus musculus':{
        'A':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.08 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.58 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.92 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'C':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.08 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.50 ,0.50 ,0.25 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.08 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'G':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.17 ,0.00 ,0.00 ,0.33 ,0.67 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.50 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.58 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'T':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.08 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.08 ,0.00 ,0.08 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.25 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00]
      },
      'Saccharomyces cerevisiae':{
        'A':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.46 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.46 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'C':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.15 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.06 ,0.00 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.04 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.35 ,0.19 ,0.02 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'G':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.21 ,0.40 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.58 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.23 ,0.04 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.25 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00],
        'T':[0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.27 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.02 ,0.88 ,0.06 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00 ,0.00]
      },
    }
export default {
  data(){
    return {
      Loading:true,
      bases:['Overall','A','C','G','T'],
      gap:true,

      checked_base:'Overall',
      options : {
        grid: [{
          height: '40%',
          top: '30%',
          right: '10%',
          width: '80%',
          left: '10%',
        },
          {
            height: '15%',
            top: '5%',
            right: '10%',
            width: '80%',
            left: '10%',
          },
        ],
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        title: [{
          text: 'Mismatch Landscape',
          left: 'center',
          top:'24%',
        },{    text: 'Known Modification Rate',
          left: 'center',
          top:'0%',
        }],
        tooltip: {
          formatter: function (params) {
            console.log(params)
            if (params.componentSubType==='bar'){
              return ' position: '+params.dataIndex+ '<br/>'+params.marker+params.seriesName+': '+params.data.toFixed(2)
            }else if (params.componentSubType==='heatmap'){
              return params.data[1]+' position: '+params.data[0]+ '<br/>'+params.marker+'<span style="font-weight: bold">'+params.data[2].toFixed(2)+'</span>'
            }
          },
        },
        xAxis: [{
          type: 'category',
          data: arr,
          gridIndex:0
        },{
          type: 'category',
          data: arr,
          gridIndex:1
        }],
        yAxis: [{
          show:false,
          type: 'category',
          axisTick:{
            show:false, //隐藏Y轴刻度
          },
          gridIndex:0
          },
          {
            type: 'value',
            max:1,
            gridIndex:1
          }],
        visualMap: [{
          orient:"horizontal",
          left:"center",
          min: 0,
          max: 0.5,
          calculable: true,
          realtime: false,
          precision:2,
          inRange: {
            color: [
              '#74add1',
              '#abd9e9',
              '#e0f3f8',
              '#ffffbf',
              '#fee090',
              '#fdae61',
              '#f46d43',
              '#d73027',
              '#a50026'
            ]
          }
        },{
          min: 0,
          max: 1,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '0%',
          show: false,
          color: ['#5DAA68'],
          seriesIndex:1,
        },
          {
            min: 0,
            max: 1,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '0%',
            show: false,
            color: ['#6688CC'],
            seriesIndex:2,
          },
          {
            min: 0,
            max: 1,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '0%',
            show: false,
            color: ['#F4DB7D'],
            seriesIndex:3,
          },
          {
            min: 0,
            max: 1,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '0%',
            show: false,
            color: ['#FF6A3D'],
            seriesIndex:4,
          }],
        series:[
            {
              name: 'heatmap',
              type: 'heatmap',
              data: [],
              emphasis: {
                itemStyle: {
                  borderColor: '#333',
                  borderWidth: 1
                }
              },
              progressive: 1000,
              animation: false
            },
          {
            name: 'A',
            type: 'bar',
            stack: 'stack',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: [],
            color: "#5DAA68"
          },
          {
            name: 'C',
            type: 'bar',
            stack:'stack',
            xAxisIndex: 1,
            yAxisIndex:1,
            data: [],
            color:"#6688CC"
          },
              {
                name: 'G',
                    type: 'bar',
                  stack:'stack',
                  xAxisIndex: 1,
                  yAxisIndex:1,
                  data: [],
                color:"#F4DB7D"
              },
          {
            name: 'U',
            type: 'bar',
            stack:'stack',
            xAxisIndex: 1,
            yAxisIndex:1,
            data: [],
            color:"#FF6A3D"}
          ]
      },
    }
  },
  methods:{
    async get_dataset() {

      let res1 = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-heatmap/'+this.$route.query.dataset.replaceAll("-","~")+'/'+this.$data.checked_base+'/'+(this.$data.gap?'gap':'nogap')
      })
      let res2 = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-view/'+this.$route.query.dataset
      })
      // let res3 = await axios({
      //   method: 'get',
      //   url: 'https://43.138.148.56:20004/get-heatmap/'+this.$route.query.dataset+'/'+this.$data.checked_base+'/'+(this.$data.gap?'gap':'nogap')+'/rowname'
      // })
      res1 = res1.data
      res2 = res2.data
      // res3=res3.data

      this.$data.options.series[0].data=res1
      // this.$data.options.yAxis[0].data=res3
      this.Loading=false
    },
  },
  mounted(){
    let species_short=(this.$route.query.dataset.split('_')[1])
    if (species_short=="HSA"){
      this.$data.options.series[1].data=known_modification_rate["Homo sapiens"].A
      this.$data.options.series[2].data=known_modification_rate["Homo sapiens"].C
      this.$data.options.series[3].data=known_modification_rate["Homo sapiens"].G
      this.$data.options.series[4].data=known_modification_rate["Homo sapiens"].T
    }else if(species_short=="MMU"){
      this.$data.options.series[1].data=known_modification_rate["Mus musculus"].A
      this.$data.options.series[2].data=known_modification_rate["Mus musculus"].C
      this.$data.options.series[3].data=known_modification_rate["Mus musculus"].G
      this.$data.options.series[4].data=known_modification_rate["Mus musculus"].T
    }else if(species_short=="SCE"){
      this.$data.options.series[1].data=known_modification_rate["Saccharomyces cerevisiae"].A
      this.$data.options.series[2].data=known_modification_rate["Saccharomyces cerevisiae"].C
      this.$data.options.series[3].data=known_modification_rate["Saccharomyces cerevisiae"].G
      this.$data.options.series[4].data=known_modification_rate["Saccharomyces cerevisiae"].T
    }
    this.echartsinstance1 = echarts.init(this.$refs.container1)
    this.echartsinstance1.setOption(this.options)
    this.get_dataset()



  },
  computed:{
    watchInputData(){ return this.options.series[0].data }
  },
  watch:{
    watchInputData(){
      this.echartsinstance1.setOption(this.options)
    },
    checked_base(){
      this.Loading=true
      this.get_dataset()
    },
    gap(){
      this.Loading=true
      this.get_dataset()
    },
    deep: true
  }
}
</script>
<style >
.el-radio-button.is-active.A .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #5daf34;
}
.el-radio-button.is-active.C .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #6688CC;
}
.el-radio-button.is-active.G .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #F4DB7D;
}
.el-radio-button.is-active.T .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #FF6A3D;
}
</style>
