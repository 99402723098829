import { render, staticRenderFns } from "./PositionList.vue?vue&type=template&id=c9ca7064&scoped=true&"
import script from "./PositionList.vue?vue&type=script&lang=js&"
export * from "./PositionList.vue?vue&type=script&lang=js&"
import style0 from "./PositionList.vue?vue&type=style&index=0&id=c9ca7064&scoped=true&lang=css&"
import style1 from "./PositionList.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9ca7064",
  null
  
)

export default component.exports