<template>
  <div>
    <el-row>
      <el-col>
        <h1 style="color:#182978">About</h1>
      </el-col>
    </el-row>
    <el-card shadow="never">
      <el-row>
        <h3>Contact us</h3>
        <p>tModBase is under constant maintenance and improvement. Any questions about the usage, comments or suggestions are appreciated.</p>
        <h3>contact us :</h3>
        <p>zhengll33@mail.sysu.edu.cn<p>
        <p>or</p>
        <p>yangjh7@mail.sysu.edu.cn.</p>
        <h3>The contact :</h3>
        <p>Lingling Zheng</p>
        <p>or</p>
        <p>Jian-Hua Yang</p>
        <h3>Address:</h3>
        <p>RNA Information Center, State Key Laboratory for Biocontrol, Sun Yat-sen University, Guangzhou 510275, P. R. China</p>
      </el-row>
      <el-image :src="map" fit="contain" style="height: 320px;width: 590px"></el-image>
    </el-card>
    <br/>
    <el-card shadow="never">
      <el-row>
        <h3>Our Tools</h3>
        <p>In addition to tModBase, we have also developed several databases and softwares to</p>
        <p>investigate the biological functions and mechanisms of various RNA in biological processes.</p>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="RMBase" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://rna.sysu.edu.cn/rmbase/index.php')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>RMBase V2.0</h4>
          <p>Deciphering the Map of RNA Modifications from Epitranscriptome Sequencing Data. (Xuan et al. Nucleic Acids Res. 2018 Jan 4;46(D1):D327-34.)</p>
        </el-col>
      </el-row>

      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="ChipBase" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://rna.sysu.edu.cn/chipbase/')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>ChIPBase v2.0</h4>
          <p>Decoding transcriptional regulatory networks of non-coding RNAs and protein-coding genes from ChIP-seq data. (Zhou et al. Nucleic Acids Res. 2017 Jan 04;45(D1):D43-D50.)</p>
        </el-col>
      </el-row>

      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="DeepSeq" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://rna.sysu.edu.cn/deepbase3/')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>deepBase v3.0</h4>
          <p>expression atlas and interactive analysis of ncRNAs from thousands of deep-sequencing data. (Xie et al. Nucleic Acids Res.2020 Nov 11;gkaa1039.)</p>
        </el-col>
      </el-row>

      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="Encori" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://starbase.sysu.edu.cn/index.php')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>ENCORI</h4>
          <p>A database for exploring miRNA-mRNA and miRNA-ncRNA interaction maps from Argonaute and other RBP CLIP-Seq data. (Li et al. Nucleic Acids Res. 2014;42:D92-7.)</p>
        </el-col>
      </el-row>

      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="DreamBase" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://rna.sysu.edu.cn/dreamBase/')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>dreamBase</h4>
          <p>DNA modification, RNA regulation and protein binding of expressed pseudogenes in human health and disease. (Zheng et al. Nucleic Acids Res. 2018 Jan 4;46(D1):D85-D91.)</p>
        </el-col>
      </el-row>

      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="ColorCells" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://rna.sysu.edu.cn/colorcells/')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>ColorCells</h4>
          <p>A database of expression, classification and functions of lncRNAs in single cells. (Zheng et al. Briefings in bioinformatics. 2020 Dec 14;bbaa325.)</p>
        </el-col>
      </el-row>

      <el-row :gutter="60">
        <el-col :span="4">
          <el-image :src="tsRFun" fit="fill" style="height: 100px;width: 200px;cursor: pointer;" @click="toWindow('https://rna.sysu.edu.cn/tsRFun/index.php')"></el-image>
        </el-col>
        <el-col :span="20">
          <h4>tsRFun</h4>
          <p>A comprehensive platform for decoding human tsRNA expression, functions and prognostic value by high-throughput small RNA-Seq and CLIP-Seq data.</p>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import map from "../assets/img/map.png"
import RMBase from "../assets/img/rmBaseLogo.png"
import ChipBase from "../assets/img/contact-chipbase-logo.png"
import DeepSeq from "../assets/img/contact-deepbase-logo.png"
import Encori from "../assets/img/starBase_logo.png"
import DreamBase from "../assets/img/contact-dreambase-logo.png"
import ColorCells from "../assets/img/colorcells.png"
import tsRFun from "../assets/img/tsRFun.png"
export default {
  data(){
    return{
      map:map,
      RMBase:RMBase,
      ChipBase,
      DeepSeq,
      Encori,
      ColorCells,
      DreamBase,
      tsRFun
    }
  },
  methods:{
    toWindow(url){
      window.open(url, "_blank");
    },
  }
}
</script>

<style scoped>

</style>