<template>
  <div>

    <el-table
        :data="tableData"
        style="width: 100%"
        :show-header=false>
      <el-table-column
          prop="title"
          label="Title"
          width="auto">
        <template slot-scope="scope">
          <span style="margin-left: 10px;font-weight: bold">{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="value"
          label="Value"
      >
        <template slot-scope="scope">
            <div style="margin-left: 10px">
              <div  v-if="scope.row.type==='modification'">
                <div v-html="scope.row.name_html"></div>
              </div>
              <div  v-else-if="scope.row.type==='array'">
                <div v-for="(item,index) in scope.row.value" :key="index">
                  {{item}}
                </div>
              </div>
              <div  v-else-if="scope.row.type==='library_construction'">
                <div v-html="scope.row.library_html"></div>
              </div>
              <div  v-else-if="scope.row.link==='out'">
                <div style="cursor: pointer;margin-right: 10px" v-for="(item,index) in scope.row.url" :key="index" @click="toRescue(item)">
                {{ scope.row.value[index] }}
                  <i class="el-icon-my-export-new-window"></i>
              </div>
              </div>
              <div  v-else-if="scope.row.link==='in'">
                <router-link tag="div" style="cursor: pointer;margin-right: 10px" :to="{path:scope.row.url[index],query: {name: scope.row.value[index],dataset:'none'}}" v-for="(item,index) in scope.row.url" :key="index">
                {{ scope.row.value[index] }}
                  <i class="el-icon-my-export-new-window"></i>
              </router-link>
              </div>
              <div  v-else>
                {{ scope.row.value }}
              </div>
            </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
import json2csv from "json2csv";
import MODOMICS from "../assets/modomics.json"


export default {
  data() {
    return {
      tableData: [{

      }]
    }
  },
  methods: {
    toRescue(url) {
      console.log("hi")
      window.open(url, "_blank");
    },
    async get_summary() {
      let url
      if (this.entity==="dataset"){
        url='https://43.138.148.56:20004/get-dataset-summary/'+this.$route.query.dataset
        let res = await axios({
          method: 'get',
          url:url
        })
        res = res.data
        console.log(res)
        // console.log(eval(res[0]))
        // this.$data.tableData=eval(res[0].value)

        let gse_arr=res[0].gse.split(';')
        console.log(gse_arr)
        let gse_url=gse_arr.map(function(x){return "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc="+x})
        let gsm_arr=res[0].gsm.split(';')
        let gsm_url=gsm_arr.map(function(x){return "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc="+x})

        this.$data.tableData=[
          {title:"Technology",value:res[0].technology},
          {title:"Treatment",value:res[0].treatment},
          {title:"Species",value:res[0].species},
          {title:"Sample",value:res[0].sample},
          {title:"Library Construction",value:res[0].library_construction,type:'library_construction',library_html:res[0].library_construction.replaceAll('1.','<b>1.</b>').replaceAll('2.','<br/><b>2.</b>').replaceAll('3.','<br/><b>3.</b>').replaceAll('4.','<br/><b>4.</b>')},
          {title:"RT enzyme",value:res[0].rt_enzyme},
        ]
        if (gse_arr.length!==0&&gse_arr[0]!=='not applicable'){
          this.$data.tableData.push( {title: "GSE", value: gse_arr,link:"out",url:gse_url},)
        }
        if (gsm_arr.length!==0&&gsm_arr[0]!=='not applicable'){
          this.$data.tableData.push( {title: "GSM", value: gsm_arr,link:"out",url:gsm_url},)
        }
      }
      if (this.entity==="trna"){
        url='https://43.138.148.56:20004/get-trna-entry/'+this.$route.query.spec+'/'+this.$route.query.name.replace(/-/g,"_")
        let res = await axios({
          method: 'get',
          url:url
        })
        res = res.data
        console.log(res)
        let var_url=''
        let var_arr=[]
        // console.log(eval(res[0]))
        // this.$data.tableData=eval(res[0].value)
        if (res[0].variants!=null) {
          var_arr = res[0].variants.split(';')
          if (this.$route.query.spec == "Homo sapiens") {
            console.log(this.$route.query.species)
            var_url = var_arr.map(function (x) {
              return "http://gtrnadb.ucsc.edu/genomes/eukaryota/Hsapi38/genes/" + x + ".html"
            })
          } else if (this.$route.query.spec == "Mus musculus") {
            console.log(this.$route.query.species)
            var_url = var_arr.map(function (x) {
              return "http://gtrnadb.ucsc.edu/genomes/eukaryota/Mmusc39/genes/" + x + ".html"
            })
          }else if (this.$route.query.spec == "Saccharomyces cerevisiae") {
            console.log(this.$route.query.species)
            var_url = var_arr.map(function (x) {
              return "http://gtrnadb.ucsc.edu/genomes/eukaryota/Scere3/genes/" + x + ".html"
            })
          }
        }

        this.$data.tableData=[
          {title:"Name",value:res[0].raw_name},
          {title:"Species",value:res[0].species},
          {title:"Amino Acid",value:res[0].AA},
          {title:"Anticodon",value:res[0].anticodon},
          {title:"Type",value:res[0].type},
          {title:"Comment",value:res[0].comment},
          {title: "Variants", value: var_arr,link:"out",url:var_url},
        ]
      }
      if (this.entity==="modification"){
        url='https://43.138.148.56:20004/get-modification-summary/'+this.$route.query.name
        let res = await axios({
          method: 'get',
          url:url
        })
        res = res.data
        console.log(MODOMICS)
        let id = 0
        for(var i in MODOMICS) {
          if(MODOMICS[i].short_name===this.$route.query.name){
            id = i
          }
        }
        this.$data.tableData=[
          {title:"Name",value: res[0].mod_name,type:'modification',name_html:this.modification2SupOrSub(res[0].mod_name)},
          {title:"Full Name",value: res[0].mod_full_name},
          {title:"Category",value: res[0].mod_category.split(';'),type:'array'},
          {title:"Site",value: res[0].mod_site},
          {title:"MODOMICS",value: [this.$route.query.name],link:"out",url:['https://iimcb.genesilico.pl/modomics/modifications/'+id+'/']},
        ]
      }
      if (this.entity==="enzyme"){
        url='https://43.138.148.56:20004/get-enzyme-summary/'+this.$route.query.name
        let res = await axios({
          method: 'get',
          url:url
        })
        console.log(this.$route.query.name)
        res = res.data
        console.log(res)
        if(res[0].enz_synonym!==''){
          this.$data.tableData=[
            {title:"Name",value:res[0].enz_name},
            {title:"Full Name",value:res[0].full_name},
            {title:"Category",value:res[0].category},
            {title:"Type",value:res[0].type},
            {title:"UniProt",value:[res[0].uniprot],link:"out",url:['https://www.uniprot.org/uniprotkb/'+res[0].uniprot+'/entry']},
          ]
        }else{
          this.$data.tableData=[
            {title:"Name",value:res[0].enz_name},
            {title:"Full Name",value:res[0].enz_full_name},
            {title:"Species",value:res[0].enz_species},
            {title:"Category",value:res[0].enz_category},
            {title:"Type",value:res[0].enz_type},
            {title:"UniProt",value:[res[0].enz_uniprot],link:"out",url:['https://www.uniprot.org/uniprot/'+res[0].enz_uniprot]},
          ]
        }
      }
    },
    to_sub_or_sup(text){
      let f = function ($1){
        return $1.replace(",","").replaceAll("p","b");
      }
      let reg1 = new RegExp("[0-9]+",'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>",'g');
      let text1 = text.replace(reg1,"<sup>$&</sup>");
      return text1.replace(reg2, f)
    },
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    Y_to_psi(text){
      return text.replaceAll("Y", "Ψ")
    },
  },
  mounted() {
    this.get_summary()
  },
  props:['entity']
}
</script>


<style >
.el-icon-my-export-new-window {
  background: url('../assets/icon/new_window.svg') center no-repeat;
  background-size: cover;
}


/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>