<template>
  <div v-loading="Loading">
    <el-form>
      <el-form-item label="Project: ">
        <el-select v-model="selected_project" filterable placeholder="Choose or Search Project">
          <el-option
              v-for="item in this.project_list"
              :key="item.abbr"
              :label="item.abbr"
              :value="item.abbr">
            <span style="float: left;margin-right: 10px">{{ item.abbr }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{item.name}}</span>
          </el-option>
        </el-select>

      </el-form-item>
    </el-form>
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
        enabled: true,
        perPage: 10
        }"
        ref="myTable"
    >
    </vue-good-table>
    <div id="heatmap"  :style="{width: '100%', height: '500px'}" ref="container" v-loading="Loading"></div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
export default {
  data(){
    return{
      Loading:true,
      rows:[],
      columns: [
        {
          label: 'Project',
          field: 'project',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'log Fold Change',
          field: 'logFC',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'P Value',
          field: 'p_value',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'adj P Value',
          field: 'adj_p_value',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: '', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
      ],
      project_list:[
        {abbr:"CESC",name:"Cervical squamous cell carcinoma and \n endocervical adenocarcinoma"},
        {abbr:"CHOL",name:"Cholangiocarcinoma"},
        {abbr:"COAD",name:"Colon adenocarcinoma"},
        {abbr:"ESCA",name:"Esophageal carcinoma"},
        {abbr:"HNSC",name:"Head and Neck squamous cell carcinoma"},
        {abbr:"KICH",name:"Kidney Chromophobe"},
        {abbr:"KIRC",name:"Kidney renal clear cell carcinoma"},
        {abbr:"KIRP",name:"Kidney renal papillary cell carcinoma"},
        {abbr:"LICH",name:"Liver hepatocellular carcinoma"},
        {abbr:"LUAD",name:"Lung adenocarcinoma"},
        {abbr:"PCPG",name:"Pheochromocytoma and Paraganglioma"},
        {abbr:"PRAD",name:"Prostate adenocarcinoma"},
        {abbr:"SKCM",name:"Skin Cutaneous Melanoma"},
        {abbr:"STAD",name:"Stomach adenocarcinoma"},
        {abbr:"THCA",name:"Thyroid carcinoma"},
        {abbr:"THYM",name:"Thymoma"},
        {abbr:"UCEC",name:"Uterine Corpus Endometrial Carcinoma"},
      ],
      selected_project:'CESC',
      options : {
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        tooltip:{
          formatter: function (params) {
            console.log(params)
            return (
                '<b>tRNA:  </b>'+
                params.data.name.replaceAll("~","-") +
                '<br/>'+
                '<b>log2 Fold Change:  </b>'+

                params.data.value[0].toFixed(2)  +
                '<br/>' +
                '<b>-log10 P value: </b>'+
                params.data.value[1].toFixed(2)
            );

          }
        },
        xAxis: {
          max:5,
          min:-5,
          name: 'log2 Fold Change',
          nameLocation:'center',
          nameTextStyle:{
            fontSize:18,// 字体大小
            fontWeight: 400, // 字体粗细
          }
        },
        yAxis: {
          max:10,
          name: '-log10 P value',
          nameLocation: 'center',
          nameTextStyle: {
            fontSize: 18,// 字体大小
            fontWeight: 400, // 字体粗细
          },
        },
        series:
            {
              symbolSize: 10,
              data: [
              ],
              itemStyle: {
                normal: {
                  color:function(params){  //根据不同数据显示不同颜色的标记
                    console.log("------",params)
                    if (params.data.value[1]>1.30103&&params.data.value[0]>1 ) {
                      return '#ff8181';
                    } else if(params.data.value[1]>1.30103&&params.data.value[0]< -1){
                      return '#6688cc';
                    }else {
                      return '#ffffff';
                    }
                  },
                  // color: '#f4e925',
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              type: 'scatter',
              markLine: {
                animation: false,

                data: [
                  [
                    {
                      coord: [1, 0],
                      symbol: 'none'
                    },
                    {
                      coord: [1, 10],
                      symbol: 'none'
                    }
                  ],
                  [
                    {
                      coord: [-1, 0],
                      symbol: 'none'
                    },
                    {
                      coord: [-1, 10],
                      symbol: 'none'
                    }
                  ],
                  [
                    {
                      coord: [-5, 1.30103],
                      symbol: 'none'
                    },
                    {
                      coord: [5, 1.30103],
                      symbol: 'none'
                    }
                  ],
                  [
                    {
                      coord: [1, 0],
                      symbol: 'none'
                    },
                    {
                      coord: [1, 10],
                      symbol: 'none'
                    }
                  ],
                ]
              }
            }

      }
    }

  },
  methods:{
    async get_rmp_de(){
      this.Loading=true
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-rmp-de/'+this.selected_project
      })
      res = res.data
      console.log(res)

      let rows = []
      let data = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].logFC!=null){
          rows.push( {
            project:res[i].project,
            name: res[i].name.replaceAll("~","-"),
            logFC: res[i].logFC,
            p_value: res[i].pvalue,
            adj_p_value: res[i].adj_pvalue
          })
          data.push(
              {name:res[i].name,value:[res[i].logFC,-Math.log10(res[i].pvalue)]}
          )
        }

      }
      this.$data.rows=rows
      this.$data.options.series.data=data
      this.Loading=false
    }
  },
  mounted() {
    this.echartsinstance = echarts.init(this.$refs.container)
    this.echartsinstance.setOption(this.options)
    this.get_rmp_de()
  },
  watch:{
    'options.series.data'(){
      this.echartsinstance.setOption(this.options)
    },
    selected_project(){
      this.get_rmp_de()
    },
    deep:true,
  },

}
</script>

<style scoped>

</style>