<template>
  <div>
    <el-tabs>
      <el-tab-pane label="Secondary Structure">
        <div id='rna_ss'></div>
      </el-tab-pane>
      <el-tab-pane label="Modification Annotation" :disabled="!this.modified">
        <div id='rna_ss_m'></div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as fornac from "@/fornac/fornac.js";
import axios from "axios";
export default {
  name: "TranStructure",
  data() {
    return {
      modified:true,
      comment:'',
      options: {
        name: 'tRNA-Ala-AGC-8-1',
        // structure: '.(((((((..((((...........)))).(((((.......))))).(.....................)(((((.......))))))))))))....',
        // sequence: '-1,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,17a,18,19,20,20a,20b,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,e11,e12,e13,e14,e15,e16,e17,e1,e2,e3,e4,e5,e27,e26,e25,e24,e23,e22,e21,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76',
        structure:'(((((((....((.((.........)).))...((.............))))))))).',
        sequence:'GAGAAAGCTCACAAGAACTGCTAACTCATGCCCCCATGTCTAACACATGGCTTTCTCA',
        color: ''
      }
    }
  },
  methods: {
    drawPlot(id) {
      let container = new fornac.FornaContainer(("#"+id),
          {
            'animation': true
            , 'zoomable': true, 'editable': false
          });
      container.displayNumbering(false)
      console.log(container)
      console.log(this.options)
      container.addRNA(this.options.structure, this.options);
      console.log(container)
      container.setSize(500, 500)
      container.addCustomColorsText(this.options.color)

    },
    async get_structure() {
      let url_base='https://43.138.148.56:20004/get-hg38/'
      if (this.$route.query.spec=="Homo sapiens"){
        url_base='https://43.138.148.56:20004/get-hg38/'
      }else if (this.$route.query.spec=="Mus musculus"){
        url_base='https://43.138.148.56:20004/get-mm39/'
      }else if (this.$route.query.spec=="Saccharomyces cerevisiae"){
        url_base='https://43.138.148.56:20004/get-sce-ref/'
      }
      let res = await axios({
        method: 'get',
        url: url_base + this.$route.query.name.replace(/-/g,"_"),
      })
      res=res.data

      function arrayRemove(arr, value) {
        return arr.filter(function(ele){
          return ele != value;
        });
      }

      if (res[0].modified_sequence==null){
        this.modified=false
        console.log(this.modified)
      }else{
        this.modified=true
        let arr=res[0].modified_sequence.split(";")
        let arr_rm=arrayRemove(arr,'-')
        let text=arr_rm.join(";")
        let color=''
        for (let i = 0; i < arr_rm.length; i++) {
          if (["A","C","G","U"].indexOf(arr_rm[i])== -1){
            color=color+(i+1)+':#ACBFE6 '
          }
        }
        console.log(color)
        this.options.name=res[0].name
        this.options.structure=res[0].structure
        this.options.sequence=text
        this.options.color=color
        this.drawPlot("rna_ss_m")
      }
      this.options.name=res[0].name
      this.options.structure=res[0].structure
      this.options.color=''
      this.options.sequence=res[0].sequence_db.replace(/[a-z]+/g,"_")
      this.drawPlot("rna_ss")
    },
  },
  mounted() {
    this.get_structure()
  }
}
</script>

<style>
circle[node_type="nucleotide"] {
  stroke: #ccc;
@value nodeStrokeWidth: 0.8;
  stroke-width: 1;
  opacity: 1;
  fill: white;
}

text[label_type="nucleotide"], text[label_type="label"] {
  font-weight: bolder;
  font-family: "Sofia RE", sans-serif;
  font-size: 0.5em;
  color: rgb(100, 100, 100);
  text-anchor: middle;
  alignment-baseline: middle;
  dominant-baseline: central;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

circle[node_type="label"] {
  stroke: transparent;
  stroke-width: 0;
  fill: white;
  display: none;
}
path[class="fornac-directionArrow"] {
  fill: #777;
  stroke: none;
  stroke-width: 0.8px;
}
line[class="link fornac-link fornac-transparent"]{
  display: none;
}
text[class="fornac-nodeLabel fornac-transparent"]{
  display: none;
}
</style>
