<template>
  <div>
    <el-row>
      <el-col>
        <h1 style="color:#182978">Dataset View</h1>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:12,offset:0}">
        <el-card shadow="never" header="">
          <div slot="header">
            <i class="el-icon-my-export-summary"></i>
            <span style="color:#182978">Summary</span>
          </div>
          <my-summary entity="dataset"></my-summary>
        </el-card>
      </el-col>
      <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:12,offset:0}">
          <el-card shadow="never" header="">
            <div slot="header">
              <i class="el-icon-my-export-publication"></i>
              <span style="color:#182978">Publication</span>
            </div>
            <vue-good-table
                :columns="columns_pub"
                :rows="rows_pub"
                @on-row-click="on_row_click_pub"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'author'">
                  <div v-for="(item,index) in props.row.author.split(';')" :key="index" style="white-space: nowrap">
                    {{item}}
                    <br/>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </el-card>
        </el-col>
    </el-row>
<el-row>
      <el-col>
        <el-card shadow="never">
          <el-tabs>
            <el-tab-pane label="tRNA Abundance"><dataset-view-pie :series_pie="receivedPie" :series_stack="receivedStack" :top="top"></dataset-view-pie></el-tab-pane>
            <el-tab-pane label="Mismatch Landscape"><dataset-view-heatmap></dataset-view-heatmap></el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <dataset-view-table :rows="receivedRows" ></dataset-view-table>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import axios from "axios";
import DatasetViewPie from "@/components/Dataset/DatasetViewPie";
import DatasetViewTable from "@/components/Dataset/DatasetViewTable";
import DatasetViewHeatmap from "@/components/Dataset/DatasetViewHeatmap";
import MySummary from "@/components/MySummary";


export default {
  name: 'DatasetView',
  components:{
    DatasetViewPie,
    DatasetViewTable,
    MySummary,
    DatasetViewHeatmap,

  },
  data() {
    return {
      tRNA_name:[],
      top:10,
      top_options:[5,10,20],
      tissue:['QuantM-tRNA-seq','DM-tRNA-seq','mim-tRNA-seq'].indexOf(this.$route.query.technology)> -1,
      entity:"dataset",
      receivedRows:[],
      receivedPie:[],
      receivedHeatmap:[],
      receivedStack:[],
      columns_pub: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Author',
          field: 'author',
        },
        {
          label: 'Year',
          field: 'year',
        },
        {
          label: 'Journal',
          field: 'journal',
        },
        {
          label: 'Volume',
          field: 'volume',
        },
        {
          label: 'Issue',
          field: 'issue',
        },
        {
          label: 'DOI',
          field: 'doi',
        },
        {
          label: 'PMID',
          field: 'accession',
        },
      ],
      rows_pub:[]
    };
  },
  mounted() {
    this.get_dataset()
    this.get_related_publication()
  },
  methods: {
    async get_dataset() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-view/'+this.$route.query.dataset.replaceAll("-","~")
      })

      res = res.data
      console.log(res[1])
      let rows=[]
      let pie=[]
      let stack=[]
      let name=[]
      // let A=[]
      for (let i = 0; i < res.length; i++) {
        rows[i] = {name: res[i].raw_name,sequence: res[i].seq,mismatch:eval(res[i].per_mismatch),modomics:res[i].modified_sequence,stop:eval(res[i].stop)}
        pie[i]={name:res[i].raw_name,value:parseFloat(res[i].per_counts)}
        name[i]={value:res[i].raw_name,label:res[i].raw_name}
        stack[i]={name:res[i].raw_name,type:'line',stack:'Total',areaStyle: {},
          emphasis: {
            focus: 'series'
          },data:eval(res[i].cov_ng)}
        // for (let j = 0; j < res[i].seq.length; j++){
        //   A.push([res[i].name
        //     ,j,eval(eval(res[i].per_mismatch)[j])[0]]
        //   )
        // }
      }

      // 排序函數
      var compare = function(name){
        return function(o, p){
          var a, b;
          if (typeof o === "object" && typeof p === "object" && o && p) {
            a = o[name];
            b = p[name];
            if (a === b) {
              return 0;
            }
            if (a.indexOf('mt')!==-1&&b.indexOf('mt')!==-1) {
              return a < b ? -1 : 1;
            }else if (a.indexOf('mt')!==-1&&b.indexOf('mt')===-1){
              return 1;
            }else if(a.indexOf('mt')===-1&&b.indexOf('mt')!==-1){
              return -1;
            }else if(a.indexOf('mt')===-1&&b.indexOf('mt')===-1){
              return a < b ? -1 : 1;
            }
          }
          else {
            throw ("error");
          }
        }
      }
      this.$data.receivedRows=rows.sort(compare("name"))
      this.$data.tRNA_name=[...new Set(name)]
      console.log(rows)
      let top=this.$data.top
      let pie_array=pie.sort((a,b)=>{ return b.value-a.value}).slice(0,top)
      let left_array=pie.sort((a,b)=>{ return b.value-a.value}).slice(top)
      let pie_name=pie_array.map(item => item.name)
      let sum = left_array.reduce((sum, e) => sum + Number(e.value || 0), 0)
      let others={name:"Others",value:sum,itemStyle: {
          color: '#C0C4CC'
        }}
      this.$data.receivedPie=pie_array.concat(others)
      this.$data.receivedStack=this.filterByName(stack,pie_name).sort((prev, next) => {
        const p = pie_name.indexOf(prev.name)
        const n = pie_name.indexOf(next.name)
        return p - n
      })
    },
    async get_related_publication() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-dataset-publication/' + this.$route.query.dataset
      })
      res = res.data
      console.log(res)
      let rows_pub = []
      for (let i = 0; i < res.length; i++) {
        rows_pub[i] = {
          title:res[i].Title,
          author:res[i].Author,
          year:res[i].Year,
          journal:res[i]['Tertiary Title'],
          volume:res[i].Volume,
          issue:res[i].Issue,
          doi:res[i].DOI,
          accession:res[i].accession,
        }
      }
      this.$data.rows_pub = rows_pub
    },
    filterByName(aim, nameArr) {
      return aim.filter(item => nameArr.indexOf(item.name) > -1)
    },
    on_row_click_pub(params){
      let url="https://pubmed.ncbi.nlm.nih.gov/"+params.row.accession
      window.open(url, "_blank");
    }
  }
}
</script>

<style>
.box-card {
  /*margin-left: auto;*/
}

.demo-color-box {
  position: relative;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  height: 60px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
}


.bg-color-sub {
  width: 100%;
  height: 20px;
  left: 0;
  bottom: 0;
  position: absolute;
}

.bg-color-sub, .demo-color-box-group .demo-color-box:last-child {
  border-radius: 0 0 4px 4px;
}

.bg-blue-sub-item {
  width: 11.1111111%;
  height: 100%;
  display: inline-block;
}

.inner-span {
  font-size: 16px;
  position: absolute;

}
#inner{
  color: #606266;
  text-decoration: none;
  font-size: 16px;
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-row {
  margin-bottom: 20px;
}
.el-icon-my-export-structure {
  background: url('../../assets/icon/structure.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-summary {
  background: url('../../assets/icon/summary.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-sequence {
  background: url('../../assets/icon/alphabetical.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-chart {
  background: url('../../assets/icon/chart.svg') center no-repeat;
  background-size: cover;
}
.el-icon-my-export-publication {
  background: url('../../assets/icon/journal.svg') center no-repeat;
  background-size: cover;
}
/*占位用↓*/

[class^="el-icon-my-export"]:before {
  content: "\66ff";
  font-size: 16px;
  visibility: hidden;
}


[class^="el-icon-my-export"] {
  font-size: 16px;
}

[class^="el-icon-my-export"]:before {
  content: "\e641";
}
</style>