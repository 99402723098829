<template>
  <div>
    <div>
      <el-row>
        <el-col>
          <h1 style="color:#182978">tRNAs</h1>
        </el-col>
      </el-row>
      <el-row>
        <el-card shadow="never">
          <div slot="header">
            <i class="el-icon-my-export-filter"></i>
            <span style="color:#182978">Quick Filter</span>
          </div>
          <el-form :inline="true" class="demo-form-inline" label-position="top" size="medium">
            <el-form-item label="Species: ">
              <el-radio-group v-model="selectedSpecies">
                <el-radio-button label="Homo sapiens" value="Homo sapiens"></el-radio-button>
                <el-radio-button label="Mus musculus" value="Mus musculus"></el-radio-button>
                <el-radio-button label="Saccharomyces cerevisiae" value="Saccharomyces cerevisiae"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Amino Acid: ">
              <el-select v-model="selectedAminoAcid" clearable filterable placeholder="">
                <el-option
                    v-for="aa in aminoAcid" :key="aa" :label="aa"
                    :value="aa">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Anticodon: ">
              <el-select v-model="selectedAnticodon" clearable filterable placeholder="">
                <el-option
                    v-for="ant in anticodon" :key="ant" :label="ant"
                    :value="ant">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </el-row>

    </div>
    <el-row>
      <el-col>
        <h2 style="color:#182978">tRNA List</h2>
      </el-col>
    </el-row>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows,'tmodbase_tRNA.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows1,'tmodbase_tRNA.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row>
      <el-col v-loading="Loading1">
        <vue-good-table
            :columns="columns1"
            :pagination-options="{
        enabled: true,
        perPage: 10
        }"
            :rows="rows"
            :sort-options="{
        enabled: true
        }"
            @on-row-click="on_row_click"
            ref="table1"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'variants'&props.row.variants != null">
              <div v-for="(item,index) in props.row.variants.split(';')" :key="index">
                {{ item }}
                <br/>
              </div>
            </div>
          </template>
          <template slot="loadingContent">
            Hi!
          </template>
        </vue-good-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h2 style="color:#182978">Modified tRNA</h2>
      </el-col>
    </el-row>
    <el-row style="text-align: right">
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Whole Table">
        <el-button type="primary" slot="reference" style="margin-right: 10px"  @click="downloadCSV(rows2,'tmodbase_tRNA_modomics.csv')">
          <i class="el-icon-my-export-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
      <el-popover
          placement="top-start"
          width="auto"
          trigger="hover"
          x-placement="top"
          content="Download Filtered Table">
        <el-button type="primary" slot="reference"  style="margin-right: 10px" @click="downloadCSV(filteredRows2,'tmodbase_tRNA_modomics.csv')">
          <i class="el-icon-my-export-filtered-download-white" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </el-row>
    <el-row>
      <el-col v-loading="Loading2">
        <vue-good-table
            :columns="columns2"
            :pagination-options="{
        enabled: true,
        perPage: 10
        }"
            :rows="rows2"
            ref="table2"
        >
          <template slot="table-row" slot-scope="props">
            <router-link
                v-if="['A','C','G','U','-'].indexOf(props.row[props.column.field])==-1&props.column.field!='name'"
                :to="{path:'/modification-view',query: {name: props.row[props.column.field]}}"
                class="this"
                style="cursor: pointer;color:#FFFFFF;background-color: #6688CC;border-radius: 5px;border: 5px solid #6688cc"
                tag="span">
              <span v-if="props.column.field!=='name'" v-html="props.row[props.column.field+'_html']">
              </span>
              <span v-else>
                {{ props.row.name }}
              </span>
            </router-link>
            <div v-else>
              <span v-if="props.column.field!=='name'" v-html="props.row[props.column.field+'_html']">
              </span>
              <span v-else class="row_name">
                {{ props.row.name }}
              </span>
            </div>

          </template>
        </vue-good-table>
      </el-col>
    </el-row>
    <el-row>

    </el-row>
    <el-row v-if="this.selectedSpecies==='Homo sapiens'">
      <el-col>
        <h2 style="color:#182978">tRNA Expression</h2>
      </el-col>
      <el-card class="box-card" shadow="never">
        <trna-expression></trna-expression>
      </el-card>
    </el-row>
    <el-row v-if="this.selectedSpecies==='Homo sapiens'">
      <el-col>
        <h2 style="color:#182978">tRNA Differential Expression</h2>
      </el-col>
      <el-card class="box-card" shadow="never">
        <trna-de></trna-de>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import json2csv from "json2csv";
import TrnaExpression from "@/components/TrnaExpression";
import TrnaDe from "@/components/TrnaDe";
export default {
  components: {TrnaExpression,TrnaDe},
  data() {
    return {
      Loading1: true,
      Loading2: true,
      selectedSpecies: 'Homo sapiens',
      aminoAcid: ["Phe",
        "Val",
        "Leu",
        "Ile",
        "Gln",
        "Met",
        "Trp",
        "Ala",
        "Asn",
        "Cys",
        "Tyr",
        "Ser",
        "Asp",
        "Lys",
        "Gly",
        "Arg",
        "His",
        "Glu",
        "Thr",
        "Pro",
        "iMet",
        "SeC",
      ],
      selectedAminoAcid: '',
      anticodon: ["GAA",
        "TAC",
        "TAA",
        "GAT",
        "TTG",
        "TCA",
        "TGC",
        "GTT",
        "GCA",
        "GTA",
        "TGA",
        "GTC",
        "TTT",
        "TCC",
        "TCG",
        "GTG",
        "GCT",
        "TAG",
        "TTC",
        "TGT",
        "TGG",
        "AGC",
        "CGC",
        "ACG",
        "CCG",
        "CCT",
        "TCT",
        "CTG",
        "CTC",
        "CCC",
        "GCC",
        "AAT",
        "TAT",
        "CAT",
        "AAG",
        "CAA",
        "CAG",
        "CTT",
        "AGG",
        "CGG",
        "AGA",
        "CGA",
        "AGT",
        "CGT",
        "CCA",
        "ATA",
        "AAC",
        "CAC"],
      selectedAnticodon: '',
      columns1: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        // {
        //   label: 'Sequence',
        //   field: 'sequence',
        //   filterOptions: {
        //     // styleClass: 'class1', // class to be added to the parent th element
        //     enabled: true, // enable filter for this column
        //     placeholder: 'Input text', // placeholder for filter input
        //     filterValue: '', // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     filterFn:this.columnFilterFn,
        //     trigger: '',
        //   },
        // },
        {
          label: 'Amino Acid',
          field: 'AA',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Anticodon',
          field: 'anticodon',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
        {
          label: 'Variants',
          field: 'variants',
          filterOptions: {
            // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Multiple items can be connected with commas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: '',
          },
        },
      ],
      rows: [],
      columns2: [
        {
          label: 'Name',
          field: 'name',
          width: '150px'
        },
        {
          label: '-1',
          field: '-1',

        },
        {
          label: '1',
          field: '1',

        },
        {
          label: '2',
          field: '2',

        },
        {
          label: '3',
          field: '3',

        },
        {
          label: '4',
          field: '4',

        },
        {
          label: '5',
          field: '5',

        },
        {
          label: '6',
          field: '6',

        },
        {
          label: '7',
          field: '7',

        },
        {
          label: '8',
          field: '8',

        },
        {
          label: '9',
          field: '9',

        },
        {
          label: '10',
          field: '10',

        },
        {
          label: '11',
          field: '11',

        },
        {
          label: '12',
          field: '12',

        },
        {
          label: '13',
          field: '13',

        },
        {
          label: '14',
          field: '14',

        },
        {
          label: '15',
          field: '15',

        },
        {
          label: '16',
          field: '16',

        },
        {
          label: '17',
          field: '17',

        },
        {
          label: '17a',
          field: '17a'

        },
        {
          label: '18',
          field: '18',

        },
        {
          label: '19',
          field: '19',

        },
        {
          label: '20',
          field: '20',

        },
        {
          label: '20a',
          field: '20a',

        },
        {
          label: '20b',
          field: '20b',

        },
        {
          label: '21',
          field: '21',

        },
        {
          label: '22',
          field: '22',

        },
        {
          label: '23',
          field: '23',

        },
        {
          label: '24',
          field: '24',

        },
        {
          label: '25',
          field: '25',

        },
        {
          label: '26',
          field: '26',

        },
        {
          label: '27',
          field: '27',

        },
        {
          label: '28',
          field: '28',

        },
        {
          label: '29',
          field: '29',

        },
        {
          label: '30',
          field: '30',

        },
        {
          label: '31',
          field: '31',

        },
        {
          label: '32',
          field: '32',

        },
        {
          label: '33',
          field: '33',

        },
        {
          label: '34',
          field: '34',

        },
        {
          label: '35',
          field: '35',

        },
        {
          label: '36',
          field: '36',

        },
        {
          label: '37',
          field: '37',

        },
        {
          label: '38',
          field: '38',

        },
        {
          label: '39',
          field: '39',

        },
        {
          label: '40',
          field: '40',

        },
        {
          label: '41',
          field: '41',

        },
        {
          label: '42',
          field: '42',

        },
        {
          label: '43',
          field: '43',

        },
        {
          label: '44',
          field: '44',

        },
        {
          label: '45',
          field: '45',

        },
        {
          label: 'e11',
          field: 'e11',

        },
        {
          label: 'e12',
          field: 'e12',

        },
        {
          label: 'e13',
          field: 'e13',

        },
        {
          label: 'e14',
          field: 'e14',

        },
        {
          label: 'e15',
          field: 'e15',

        },
        {
          label: 'e16',
          field: 'e16',

        },
        {
          label: 'e17',
          field: 'e17',

        },
        {
          label: 'e1',
          field: 'e1',

        },
        {
          label: 'e2',
          field: 'e2',

        },
        {
          label: 'e3',
          field: 'e3',

        },
        {
          label: 'e4',
          field: 'e4',

        },
        {
          label: 'e5',
          field: 'e5',

        },
        {
          label: 'e27',
          field: 'e27',

        },
        {
          label: 'e26',
          field: 'e26',

        },
        {
          label: 'e25',
          field: 'e25',

        },
        {
          label: 'e24',
          field: 'e24',

        },
        {
          label: 'e23',
          field: 'e23',

        },
        {
          label: 'e22',
          field: 'e22',

        },
        {
          label: 'e21',
          field: 'e21',

        },
        {
          label: '46',
          field: '46',

        },
        {
          label: '47',
          field: '47',

        },
        {
          label: '48',
          field: '48',

        },
        {
          label: '49',
          field: '49',

        },
        {
          label: '50',
          field: '50',

        },
        {
          label: '51',
          field: '51',

        },
        {
          label: '52',
          field: '52',

        },
        {
          label: '53',
          field: '53',

        },
        {
          label: '54',
          field: '54',

        },
        {
          label: '55',
          field: '55',

        },
        {
          label: '56',
          field: '56',

        },
        {
          label: '57',
          field: '57',

        },
        {
          label: '58',
          field: '58',

        },
        {
          label: '59',
          field: '59',

        },
        {
          label: '60',
          field: '60',

        },
        {
          label: '61',
          field: '61',

        },
        {
          label: '62',
          field: '62',

        },
        {
          label: '63',
          field: '63',

        },
        {
          label: '64',
          field: '64',

        },
        {
          label: '65',
          field: '65',

        },
        {
          label: '66',
          field: '66',

        },
        {
          label: '67',
          field: '67',

        },
        {
          label: '68',
          field: '68',

        },
        {
          label: '69',
          field: '69',

        },
        {
          label: '70',
          field: '70',

        },
        {
          label: '71',
          field: '71',

        },
        {
          label: '72',
          field: '72',

        },
        {
          label: '73',
          field: '73',

        },
        {
          label: '74',
          field: '74',

        },
        {
          label: '75',
          field: '75',

        },
        {
          label: '76',
          field: '76',
        },
      ],
      rows2: [],
    };
  },
  methods: {
    async getTrnaList() {
      this.Loading1=true
      let url = 'https://43.138.148.56:20004/get-hsa'
      if (this.selectedSpecies == "Homo sapiens") {
        url = 'https://43.138.148.56:20004/get-hsa'
      } else if (this.selectedSpecies == "Mus musculus") {
        url = 'https://43.138.148.56:20004/get-mmu'
      }else if (this.selectedSpecies == "Saccharomyces cerevisiae") {
        url = 'https://43.138.148.56:20004/get-sce'
      }
      let res = await axios({
        method: 'get',
        url: url
      })
      res = res.data
      let rows = []
      for (let i = 0; i < res.length; i++) {
        rows[i] = {
          id: i + 1,
          name: res[i].raw_name,
          sequence: res[i].sequence,
          AA: res[i].AA,
          anticodon: res[i].anticodon,
          type: res[i].type,
          variants: res[i].variants,
          species: res[i].species
        }
      }
      var compare = function(name){
        return function(o, p){
          var a, b;
          if (typeof o === "object" && typeof p === "object" && o && p) {
            a = o[name];
            b = p[name];
            if (a === b) {
              return 0;
            }
            if (a.indexOf('mt')!==-1&&b.indexOf('mt')!==-1) {
              return a < b ? -1 : 1;
            }else if (a.indexOf('mt')!==-1&&b.indexOf('mt')===-1){
              return 1;
            }else if(a.indexOf('mt')===-1&&b.indexOf('mt')!==-1){
              return -1;
            }else if(a.indexOf('mt')===-1&&b.indexOf('mt')===-1){
              return a < b ? -1 : 1;
            }
          }
          else {
            throw ("error");
          }
        }
      }
      this.$data.rows=rows.sort(compare("name"))
      this.Loading1 = false

    },
    async downloadCSV(data,fileName='data.csv'){
      try {
        const result = json2csv.parse(data);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, fileName);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    modification2SupOrSub(text){
      // 这个函数用来将文本的修饰名称格式化为有上下标的形式(html)
      // 除此之外还转化特殊符号，例如Y→ψ和tau→τ
      let f = function ($1) {
        return $1.replace(",", "").replaceAll("p", "b");
      }
      let reg1 = new RegExp("[0-9]+", 'g');
      let reg2 = new RegExp(",<sup>[0-9]+</sup>", 'g');
      let text1 = text.replace(reg1, "<sup>$&</sup>");
      let text2 = text1.replace(reg2, f)
      let text3 = text2.replaceAll("Y", "Ψ")
      let text4 = text3.replaceAll("tau", "τ")
      return text4
      // modification2SupOrSub("m2G")
      // 'm<sup>2</sup>G'
      // modification2SupOrSub("m2,2tau")
      // 'm<sup>2</sup><sub>2</sub>τ'
    },
    async get_modomics() {
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-modomics-by-species/' + this.selectedSpecies
      })
      res = res.data
      console.log(res)
      let rows_seq = []

      for (let i = 0; i < res.length; i++) {
        rows_seq[i] = res[i]
        let obj = rows_seq[i]
        let obj_key = Object.keys(obj)
        console.log(obj_key)
        let obj_value = Object.values(obj).map((item) => {
          if (new RegExp("^tRNA").test(item.toString())) {
            return item
          } else {
            return this.modification2SupOrSub(item.toString())
          }
        })
        console.log(obj_value)
        let new_obj = {};
        for (var j = 0; j < obj_key.length; j++) {
          new_obj[obj_key[j] + '_html'] = obj_value[j]
        }
        console.log(new_obj)
        rows_seq[i] = {...new_obj, ...rows_seq[i]}
        console.log(rows_seq[i])
      }

      this.$data.rows2 = rows_seq
      this.Loading2 = false
    },
    on_row_click(params) {
      console.log(params)
      let default_datasets={
        "Homo sapiens":"hydro_HSA_HEK293T",
        "Mus musculus":"QuantM_MMU_Cortex",
        "Saccharomyces cerevisiae":"ARM_SCE"
      }
      this.$router.push({path: "trna-view", query: {name: params.row.name,spec:params.row.species, dataset: default_datasets[params.row.species]}})

    },
  },
  computed:{
    filteredRows1:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.table1.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    },
    filteredRows2:function (){
      // this.$refs.myTable.filteredRows[0].children 是完整的数组
      // 要去掉vgt_id和originalIndex这2个属性
      let newArr = JSON.parse(JSON.stringify(this.$refs.table2.filteredRows[0].children)) //数组是引用类型, 深拷贝一下
      newArr.map(e => { delete e.vgt_id })
      newArr.map(e => { delete e.originalIndex })
      //
      return newArr
    }
  },
  mounted() {
    this.getTrnaList()
    this.get_modomics()
  },
  watch: {
    selectedSpecies() {
      this.getTrnaList()
      this.get_modomics()
    },
    selectedAminoAcid(val) {
      if (val === undefined) {
        this.columns1[1].filterOptions.filterValue = ''
      } else {
        this.columns1[1].filterOptions.filterValue = val
      }
    },
    selectedAnticodon(val) {
      if (val === undefined) {
        this.columns1[2].filterOptions.filterValue = ''
      } else {
        this.columns1[2].filterOptions.filterValue = val
      }
    },
  }
};
</script>

<style>
</style>