<template>
<div>
  <el-card shadow="never">
    <div slot="header">
      <i class="el-icon-my-export-sequence"></i>
      <span style="color:#182978">Peak Value</span>
    </div>
    <span>Window Size: {{this.$data.ws}}</span><br/>
    <span>+CMC/-CMC counts ratio: {{this.$data.plus_minus_ratio}}</span>
    <div id="echartsdemo" :style="{width: '1200px', height: '500px'}" ref="container"></div>

  </el-card>
</div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";

export default {
  name: "TrnaPseudo",
  data(){
    return{
      options : {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series:
          {
            data: [],
            type: 'line',
            smooth: true
          }

      },
      ws:0,
      plus_minus_ratio:1
    }
  },
  methods:{
    async get_peak_value(){
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-pseudo-peak/'+this.$route.query.name.replace(/-/g,"_")
      })
      res = res.data
      console.log(res)
      this.$data.options.series={
        data: res['peak'],
        type: 'line',
        smooth: true
      }
      let arr=[]
      for (let i = 0; i < res['peak'].length; i++) {
        arr.push(i + 1);
      }

      this.$data.options.xAxis={
        type: 'category',
        data:arr
      }
    this.$data.ws=res['ws']
      this.$data.plus_minus_ratio=res['ratio'].toFixed(4
      )
    }
  },
  mounted() {

    this.echartsinstance = echarts.init(this.$refs.container)
    this.echartsinstance.setOption(this.options)
    this.get_peak_value()
  },
  watch:{
    'options.series'(){
      this.echartsinstance.setOption(this.options)
    },
  }
}
</script>

<style scoped>

</style>