<template>
<div v-loading="Loading">
  <div id="cancers" :style="{width: '100%', height: '500px'}"  ref="container1"></div>
  <div id="samples" :style="{width: '100%', height: '500px'}"  ref="container2" ></div>
</div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
const TCGA_abbr={
  "ACC":"Adrenocortical carcinoma",
  "BLCA":"Bladder Urothelial Carcinoma",
  "BRCA":"Breast invasive carcinoma",
  "CESC":"Cervical squamous cell carcinoma and endocervical adenocarcinoma",
  "CHOL":"Cholangiocarcinoma",
  "COAD":"Colon adenocarcinoma",
  "COADREAD":"Colon adenocarcinoma/Rectum adenocarcinoma Esophageal carcinoma",
  "DLBC":"Lymphoid Neoplasm Diffuse Large B-cell Lymphoma",
  "ESCA":"Esophageal carcinoma",
  "FPPP":"FFPE Pilot Phase II",
  "GBM":"Glioblastoma multiforme",
  "GBMLGG":"Glioma",
  "HNSC":"Head and Neck squamous cell carcinoma",
  "KICH":"Kidney Chromophobe",
  "KIPAN":"Pan-kidney cohort (KICH+KIRC+KIRP)",
  "KIRC":"Kidney renal clear cell carcinoma",
  "KIRP":"Kidney renal papillary cell carcinoma",
  "LAML":"Acute Myeloid Leukemia",
  "LGG":"Brain Lower Grade Glioma",
  "LIHC":"Liver hepatocellular carcinoma",
  "LUAD":"Lung adenocarcinoma",
  "LUSC":"Lung squamous cell carcinoma",
  "MESO":"Mesothelioma",
  "OV":"Ovarian serous cystadenocarcinoma",
  "PAAD":"Pancreatic adenocarcinoma",
  "PCPG":"Pheochromocytoma and Paraganglioma",
  "PRAD":"Prostate adenocarcinoma",
  "READ":"Rectum adenocarcinoma",
  "SARC":"Sarcoma",
  "SKCM":"Skin Cutaneous Melanoma",
  "STAD":"Stomach adenocarcinoma",
  "STES":"Stomach and Esophageal carcinoma",
  "TGCT":"Testicular Germ Cell Tumors",
  "THCA":"Thyroid carcinoma",
  "THYM":"Thymoma",
  "UCEC":"Uterine Corpus Endometrial Carcinoma",
  "UCS":"Uterine Carcinosarcoma",
  "UVM":"Uveal Melanoma",

}
export default {
  name: "EnzExpression",
  data(){
    return{
      Loading:true,
      mouseover_params:'',
      source:"TCGA",
      response_data:{},
      options_cancers:{
        tooltip:{
          formatter: function (params) {
            console.log(params)
            return  (params.seriesName+'<br/>'+
                params.marker+' '+params.name+' ('+ TCGA_abbr[params.name]+'): '+
                params.data.toFixed(2))
          }
        },
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        legend: {
          orient: 'vertical',
          x:'right',      //可设定图例在左、右、居中
          y:'center',     //可设定图例在上、下、居中
        },
        xAxis: {
          type:'category',
          axisLabel: {
            interval:0,
            rotate:45
          },
          data: [
            "ACC","BLCA","BRCA","CESC","CHOL","COAD","DLBC","ESCA","GBM","HNSC","KICH","KIRC","KIRP","LGG","LIHC","LUAD","LUSC","MESO","OV","PAAD","PCPG","PRAD","READ","SARC","SKCM","STAD","TGCT","THCA","THYM","UCEC","UCS","UVM"
          ],
        },
        yAxis: {
          type: 'value',
          name: 'expression value',
        },
        series:[]
      },
      options_samples:{
        visualMap:{
          top:50,
          right:5,
          type:'piecewise',
          dimension:2,
          categories:['normal','tumor'],
          inRange: {
            color: ['#6688CC', '#FF6A3D']
          },
        },
        tooltip:{},
        dataset:{
          source:[]
        },
        textStyle: {
          fontFamily: 'Sofia RE',
        },

        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: 49,
            textStyle:{
              color:"#ccd7d7"
            }
          },
        ],

        xAxis: {
          type:'category',
          axisLabel: {
            interval:0,
            rotate:45
          },
        },
        yAxis: {
          type: 'value',
          name: 'expression value',
        },
        series: {
          type:'bar'
        },
        grid: {
          y: 60,
          y2: 150,
        },
      }
      }
  },
  methods:{
    async get_rmp(){
      let res = await axios({
        method: 'get',
        url: 'https://43.138.148.56:20004/get-rmp/'+this.$route.query.name
      })
      res = res.data
      // 先将得到的数据存放在data中
      this.response_data=res
      let normal_data=[]
      let tumor_data=[]
      for (let i = 0; i < res.length; i++) {
        normal_data[i]=this.arrAvg(eval(res[i].normal))

        tumor_data[i]=this.arrAvg(eval(res[i].tumor))

      }
      this.$data.options_cancers.series=[{name:"normal",color:"#6688CC",data:normal_data,type:'bar'},{name:"tumor",color:"#FF6A3D",data:tumor_data,type:'bar'}]
      this.Loading=false
    },
    get_clicked(){
      let data=[]
      for (let i = 0; i < this.response_data.length; i++) {
        if (this.response_data[i]['project'].split("~")[1]===this.mouseover_params){
          for (let j = 0; j < this.response_data[i]['normal'].length; j++) {
            data.push({
              name:eval(this.response_data[i]['normal_sample'])[j],
              expression:eval(this.response_data[i]['normal'])[j],
              sample:'normal'
            })
          }
          for (let k = 0; k < this.response_data[i]['tumor'].length; k++) {
            data.push({
              name:eval(this.response_data[i]['tumor_sample'])[k],
              expression:eval(this.response_data[i]['tumor'])[k],
              sample:'tumor'
            })
          }
        }
      }
      this.$data.options_samples.dataset.source=data

    },
  },
  mounted() {
    var vm=this
    this.echartsinstance1 = echarts.init(this.$refs.container1)
    this.echartsinstance1.on('mouseover', function(params) {
      vm._data.mouseover_params=params.name
      console.log(vm._data.mouseover_params)
    });
    this.echartsinstance1.setOption(this.options_cancers)
    this.get_rmp()

    this.echartsinstance2 = echarts.init(this.$refs.container2)
    this.echartsinstance2.setOption(this.options_samples)
    window.onresize = () => {
      return (() => {
        this.echartsinstance1.resize();
        this.echartsinstance2.resize();
      })()
    }
  },
  watch:{
    'options_cancers.series'(){
      this.echartsinstance1.setOption(this.options_cancers)
    },
    'options_samples.series'(){
      this.echartsinstance2.setOption(this.options_samples)
    },
    mouseover_params(){
      this.get_clicked()
      this.echartsinstance2.setOption(this.options_samples)
      console.log(this.options_samples)
    }
  }
}
</script>

<style scoped>

</style>