<template>
  <div v-loading="Loading">
    <div>
      <el-row style="display:inline-block ">

        <el-radio-group v-model="checked_base" style="margin: 20px">
          <el-radio-button :class="base" v-for="base in bases" :label="base" :key="base">{{base}}</el-radio-button>
        </el-radio-group>
        <el-select v-model="selected_tRNA" filterable placeholder="Choose or Search tRNA">
          <el-option
              v-for="item in this.tRNA"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-row>

    </div>
    <div :id="label" :style="{width: '100%', height: '500px'}" ref="container"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import axios from "axios";
import arrow from '@/assets/icon/arrow.svg'
const arr=[];
for (let i = 0; i < 105; i++) {
  arr.push(i + 1);
}
export default {
  data(){
    return {
      Loading:true,
      selected_tRNA:'tRNA-Ala-TGC-1',
      tRNA:[
        "mt-tRNA-Phe-GAA",
        "mt-tRNA-Val-TAC",
        "mt-tRNA-Leu-TAA",
        "mt-tRNA-Ile-GAT",
        "mt-tRNA-Gln-TTG",
        "mt-tRNA-Met-CAT",
        "mt-tRNA-Trp-TGG",
        "mt-tRNA-Ala-TGC",
        "mt-tRNA-Asn-GTT",
        "mt-tRNA-Cys-GCA",
        "mt-tRNA-Tyr-GTA",
        "mt-tRNA-Ser-TGA",
        "mt-tRNA-Asp-GTC",
        "mt-tRNA-Lys-TTT",
        "mt-tRNA-Gly-TCC",
        "mt-tRNA-Arg-TCG",
        "mt-tRNA-His-GTG",
        "mt-tRNA-Ser-GCT",
        "mt-tRNA-Leu-TAG",
        "mt-tRNA-Glu-TTC",
        "mt-tRNA-Thr-TGT",
        "mt-tRNA-Pro-TGG",
        "tRNA-Ala-AGC-1",
        "tRNA-Ala-AGC-10",
        "tRNA-Ala-AGC-2",
        "tRNA-Ala-AGC-3",
        "tRNA-Ala-AGC-4",
        "tRNA-Ala-AGC-5",
        "tRNA-Ala-AGC-6",
        "tRNA-Ala-AGC-7",
        "tRNA-Ala-AGC-8",
        "tRNA-Ala-CGC-1",
        "tRNA-Ala-CGC-2",
        "tRNA-Ala-CGC-3",
        "tRNA-Ala-CGC-4",
        "tRNA-Ala-CGC-5",
        "tRNA-Ala-CGC-6",
        "tRNA-Ala-CGC-7",
        "tRNA-Ala-TGC-1",
        "tRNA-Ala-TGC-2",
        "tRNA-Ala-TGC-3",
        "tRNA-Ala-TGC-4",
        "tRNA-Ala-TGC-5",
        "tRNA-Ala-TGC-6",
        "tRNA-Ala-TGC-7",
        "tRNA-Ala-TGC-8",
        "tRNA-Arg-ACG-1",
        "tRNA-Arg-ACG-2",
        "tRNA-Arg-ACG-3",
        "tRNA-Arg-CCG-1",
        "tRNA-Arg-CCG-2",
        "tRNA-Arg-CCG-3",
        "tRNA-Arg-CCT-1",
        "tRNA-Arg-CCT-2",
        "tRNA-Arg-CCT-3",
        "tRNA-Arg-CCT-4",
        "tRNA-Arg-TCG-1",
        "tRNA-Arg-TCG-2",
        "tRNA-Arg-TCG-3",
        "tRNA-Arg-TCG-4",
        "tRNA-Arg-TCT-1",
        "tRNA-Arg-TCT-2",
        "tRNA-Arg-TCT-3",
        "tRNA-Arg-TCT-4",
        "tRNA-Arg-TCT-5",
        "tRNA-Asn-GTT-1",
        "tRNA-Asn-GTT-2",
        "tRNA-Asn-GTT-3",
        "tRNA-Asn-GTT-4",
        "tRNA-Asn-GTT-5",
        "tRNA-Asp-GTC-1",
        "tRNA-Asp-GTC-2",
        "tRNA-Asp-GTC-3",
        "tRNA-Asp-GTC-4",
        "tRNA-Cys-GCA-1",
        "tRNA-Cys-GCA-10",
        "tRNA-Cys-GCA-11",
        "tRNA-Cys-GCA-12",
        "tRNA-Cys-GCA-13",
        "tRNA-Cys-GCA-14",
        "tRNA-Cys-GCA-15",
        "tRNA-Cys-GCA-16",
        "tRNA-Cys-GCA-17",
        "tRNA-Cys-GCA-18",
        "tRNA-Cys-GCA-19",
        "tRNA-Cys-GCA-2",
        "tRNA-Cys-GCA-21",
        "tRNA-Cys-GCA-24",
        "tRNA-Cys-GCA-25",
        "tRNA-Cys-GCA-3",
        "tRNA-Cys-GCA-4",
        "tRNA-Cys-GCA-5",
        "tRNA-Cys-GCA-6",
        "tRNA-Cys-GCA-7",
        "tRNA-Cys-GCA-8",
        "tRNA-Cys-GCA-9",
        "tRNA-Gln-CTG-1",
        "tRNA-Gln-CTG-2",
        "tRNA-Gln-CTG-3",
        "tRNA-Gln-CTG-4",
        "tRNA-Gln-CTG-5",
        "tRNA-Gln-TTG-1",
        "tRNA-Gln-TTG-2",
        "tRNA-Gln-TTG-3",
        "tRNA-Gln-TTG-4",
        "tRNA-Gln-TTG-5",
        "tRNA-Glu-CTC-1",
        "tRNA-Glu-CTC-2",
        "tRNA-Glu-CTC-5",
        "tRNA-Glu-TTC-1",
        "tRNA-Glu-TTC-2",
        "tRNA-Glu-TTC-3",
        "tRNA-Gly-ACC-1",
        "tRNA-Gly-CCC-1",
        "tRNA-Gly-CCC-2",
        "tRNA-Gly-CCC-3",
        "tRNA-Gly-CCC-4",
        "tRNA-Gly-GCC-1",
        "tRNA-Gly-GCC-2",
        "tRNA-Gly-GCC-3",
        "tRNA-Gly-GCC-4",
        "tRNA-Gly-TCC-1",
        "tRNA-His-GTG-1",
        "tRNA-His-GTG-2",
        "tRNA-His-GTG-3",
        "tRNA-Ile-AAT-1",
        "tRNA-Ile-AAT-2",
        "tRNA-Ile-AAT-3",
        "tRNA-Ile-AAT-4",
        "tRNA-Ile-TAT-1",
        "tRNA-Ile-TAT-2",
        "tRNA-Ile-TAT-3",
        "tRNA-iMet-CAT-1",
        "tRNA-iMet-CAT-2",
        "tRNA-iMet-CAT-3",
        "tRNA-Leu-AAG-1",
        "tRNA-Leu-AAG-2",
        "tRNA-Leu-AAG-3",
        "tRNA-Leu-CAA-1",
        "tRNA-Leu-CAA-2",
        "tRNA-Leu-CAA-3",
        "tRNA-Leu-CAA-4",
        "tRNA-Leu-CAG-1",
        "tRNA-Leu-CAG-2",
        "tRNA-Leu-CAG-3",
        "tRNA-Leu-CAG-4",
        "tRNA-Leu-TAA-1",
        "tRNA-Leu-TAA-2",
        "tRNA-Leu-TAA-3",
        "tRNA-Leu-TAA-4",
        "tRNA-Leu-TAG-1",
        "tRNA-Leu-TAG-2",
        "tRNA-Leu-TAG-3",
        "tRNA-Lys-CTT-1",
        "tRNA-Lys-CTT-12",
        "tRNA-Lys-CTT-13",
        "tRNA-Lys-CTT-14",
        "tRNA-Lys-CTT-2",
        "tRNA-Lys-CTT-3",
        "tRNA-Lys-CTT-4",
        "tRNA-Lys-CTT-5",
        "tRNA-Lys-CTT-6",
        "tRNA-Lys-CTT-7",
        "tRNA-Lys-CTT-8",
        "tRNA-Lys-CTT-9",
        "tRNA-Lys-TTT-1",
        "tRNA-Lys-TTT-2",
        "tRNA-Lys-TTT-3",
        "tRNA-Lys-TTT-4",
        "tRNA-Lys-TTT-5",
        "tRNA-Met-CAT-1",
        "tRNA-Met-CAT-2",
        "tRNA-Met-CAT-3",
        "tRNA-Met-CAT-4",
        "tRNA-Met-CAT-5",
        "tRNA-Met-CAT-6",
        "tRNA-Phe-GAA-1",
        "tRNA-Phe-GAA-2",
        "tRNA-Phe-GAA-3",
        "tRNA-Pro-AGG-1",
        "tRNA-Pro-CGG-1",
        "tRNA-Pro-TGG-1",
        "tRNA-Pro-TGG-2",
        "tRNA-Pro-TGG-3",
        "tRNA-Pro-TGG-4",
        "tRNA-SeC-TCA-1",
        "tRNA-Ser-AGA-1",
        "tRNA-Ser-AGA-2",
        "tRNA-Ser-AGA-3",
        "tRNA-Ser-CGA-1",
        "tRNA-Ser-CGA-2",
        "tRNA-Ser-CGA-3",
        "tRNA-Ser-GCT-1",
        "tRNA-Ser-GCT-2",
        "tRNA-Ser-GCT-3",
        "tRNA-Ser-GCT-4",
        "tRNA-Ser-GCT-5",
        "tRNA-Ser-GGA-1",
        "tRNA-Ser-TGA-1",
        "tRNA-Ser-TGA-2",
        "tRNA-Sup-TCA-1",
        "tRNA-Sup-TTA-1",
        "tRNA-Thr-AGT-1",
        "tRNA-Thr-AGT-2",
        "tRNA-Thr-AGT-3",
        "tRNA-Thr-AGT-4",
        "tRNA-Thr-AGT-5",
        "tRNA-Thr-AGT-6",
        "tRNA-Thr-AGT-7",
        "tRNA-Thr-CGT-1",
        "tRNA-Thr-CGT-2",
        "tRNA-Thr-CGT-3",
        "tRNA-Thr-CGT-4",
        "tRNA-Thr-TGT-1",
        "tRNA-Thr-TGT-2",
        "tRNA-Thr-TGT-3",
        "tRNA-Trp-CCA-1",
        "tRNA-Trp-CCA-2",
        "tRNA-Trp-CCA-3",
        "tRNA-Trp-CCA-4",
        "tRNA-Trp-CCA-5",
        "tRNA-Trp-CCA-6",
        "tRNA-Tyr-GTA-1",
        "tRNA-Tyr-GTA-2",
        "tRNA-Tyr-GTA-3",
        "tRNA-Tyr-GTA-4",
        "tRNA-Tyr-GTA-5",
        "tRNA-Val-AAC-1",
        "tRNA-Val-AAC-2",
        "tRNA-Val-AAC-3",
        "tRNA-Val-AAC-4",
        "tRNA-Val-AAC-5",
        "tRNA-Val-AAC-6",
        "tRNA-Val-CAC-1",
        "tRNA-Val-CAC-2",
        "tRNA-Val-CAC-3",
        "tRNA-Val-CAC-4",
        "tRNA-Val-CAC-5",
        "tRNA-Val-CAC-7",
        "tRNA-Val-TAC-1"
      ],
      bases:['Overall','A','C','G','T'],
      gap:true,
      imgUrl:require('../../assets/icon/chart.svg'),
      checked_base:'Overall',
      options : {
        textStyle: {
          fontFamily: 'Sofia RE',
        },
        grid:{
          x:100,
          y:50,
          x2:100,
          y2:150
        },
        title: {
          text: 'Dynamic changes in Mismatches',
          left: 'center'
        },
        tooltip: {
          formatter: function (params) {
            console.log(params)
            return ' position: '+params.data[1]+ '<br/>'+params.marker+'<span style="font-weight: bold">'+params.data[2].toFixed(2)+'</span>'
          }
        },
        xAxis: {

          type: 'category',
          data: [],

          axisLabel: {
            rotate:45,
            interval:0,
            margin: 20,
            formatter: function (value) {
              let obj=JSON.parse(value)
              // let res=''
              // if (obj.treatment=="DM treated"){
              //   res=obj.sample+'\n\n{DM|'+obj.treatment+'}'
              // }else if(obj.treatment=="untreated"){
              //   res=obj.sample+'\n\n{UT|'+obj.treatment+'}'
              // }else if(obj.treatment.indexOf(";")!=1){
              //   res=obj.sample+'\n\n'+obj.treatment.split(';')[0]+'\n\n'+obj.treatment.split(';')[1]
              // }
              // // if (obj.this=="this"){
              // //   res=res+'\n{THIS|}'
              // // }
              return obj.dataset.replaceAll("~","-")
            },

            rich:{
              DM: {
                color: '#fff',
                backgroundColor:"#FF6A3D",
                borderColor: "#FF6A3D",
                borderWidth: 2,
                borderRadius:4
              },
              UT: {
                color: '#fff',
                backgroundColor:"#6688CC",
                borderColor: "#6688CC",
                borderWidth: 2,
                borderRadius:4
              },
              THIS: {
                backgroundColor: {
                  image:arrow},
                height: 40
              },
            }
          }
        },
        yAxis: {

          type: 'category',
          data: arr,
        },
        visualMap: {
          orient:"vertical",
          padding:0,
          min: 0,
          max: 0.5,
          calculable: true,
          realtime: false,
          precision:2,
          inRange: {
            color: [
              '#74add1',
              '#abd9e9',
              '#e0f3f8',
              '#ffffbf',
              '#fee090',
              '#fdae61',
              '#f46d43',
              '#d73027',
              '#a50026'
            ]
          }
        },
        series:
            {
              name: 'Gaussian',
              type: 'heatmap',
              data: [],
              emphasis: {
                itemStyle: {
                  borderColor: '#333',
                  borderWidth: 1
                }
              },
              progressive: 1000,
              animation: false
            }
      },
    }
  },
  methods:{
    async get_dataset() {
      this.Loading=true
      let url='https://43.138.148.56:20004/get-tissue/DM_MMU_T~cell_set1_Naive;DM_MMU_T~cell_set1_20/'+this.selected_tRNA.replaceAll("-","_")+'/'+this.$data.checked_base

      if (this.label==1){
         url='https://43.138.148.56:20004/get-tissue/DM_MMU_T~cell_set1_Naive;DM_MMU_T~cell_set1_20;DM_MMU_T~cell_set1_48_hi~CD62L;DM_MMU_T~cell_set1_48_lo~CD62L;DM_MMU_T~cell_set1_72_hi~CD62L;DM_MMU_T~cell_set1_72_lo~CD62L;DM_MMU_T~cell_set2_20;DM_MMU_T~cell_set2_48;DM_MMU_T~cell_set2_72;DM_MMU_T~cell_set2_Navie/'+this.selected_tRNA.replaceAll("-","_")+'/'+this.$data.checked_base
      }else if(this.label==2){
         url='https://43.138.148.56:20004/get-tissue/DM_MMU_MC57G_mock;DM_MMU_MC57G_MHV68/'+this.selected_tRNA.replaceAll("-","_")+'/'+this.$data.checked_base
      }else if(this.label==3){
        url='https://43.138.148.56:20004/get-tissue/AQ_MTU_S4;AQ_MTU_S0;AQ_MTU_S10/'+this.selected_tRNA.replaceAll("-","_")+'/'+this.$data.checked_base
      }
      let res = await axios({
        method: 'get',
        url: url
      })
      res = res.data
      let arr=[]
      for (let i = 0; i < res.length; i++) {
        arr.push(res[i][0])
      }
      this.$data.options.series.data=res
      this.$data.options.xAxis.data=[...new Set(arr)]
      this.Loading=false
    },
  },
  mounted(){
    this.echartsinstance = echarts.init(this.$refs.container)
    this.echartsinstance.setOption(this.options)
    console.log(this.label)
    this.get_dataset()
  },
  watch:{
    'options.series.data'(){
      this.echartsinstance.setOption(this.options)
    },
    'options.xAxis'(){
      this.echartsinstance.setOption(this.options)
    },
    checked_base(){
      this.get_dataset()
    },
    gap(){
      this.get_dataset()
    },
    selected_tRNA(){
      this.get_dataset()
    },
    deep: true
  },
  props: ['label']
}
</script>
<style >
.el-radio-button.is-active.A .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #5daf34;
}
.el-radio-button.is-active.C .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #6688CC;
}
.el-radio-button.is-active.G .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #F4DB7D;
}
.el-radio-button.is-active.T .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #FF6A3D;
}
</style>
